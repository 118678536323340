import general from "../../../assets/images/background/race_track/general.png";
import streetDiagonal from "../../../assets/images/background/race_track/street_diagonal.png";
import streetDiagonal2 from "../../../assets/images/background/race_track/street_diagonal_2.png";
import streetS from "../../../assets/images/background/race_track/street_s.png";
import streetS2 from "../../../assets/images/background/race_track/street_s_2.png";
import streetEmpty from "../../../assets/images/background/race_track/street_empty.png";
import start from "../../../assets/images/background/race_track/start.png";
import streetUpRight from "../../../assets/images/background/race_track/street_up_right.png";
import streetCone from "../../../assets/images/background/race_track/street_cone.png";
import streetDiagonal3 from "../../../assets/images/background/race_track/street_diagonal_3.png";
import streetDouble from "../../../assets/images/background/race_track/street_double.png";
import streetUp from "../../../assets/images/background/race_track/street_up.png";
import streetHigh from "../../../assets/images/background/race_track/street_high.png";
import streetDouble2 from "../../../assets/images/background/race_track/street_double_2.png";
import streetDouble3 from "../../../assets/images/background/race_track/street_double_3.png";
import streetDouble4 from "../../../assets/images/background/race_track/street_double_4.png";
import streetStraight from "../../../assets/images/background/race_track/street_straight.png";
import streetCorner from "../../../assets/images/background/race_track/street_corner.png";
import green from "../../../assets/images/background/race_track/green.png";
import trees from "../../../assets/images/background/race_track/trees.png";
import streetMiddle from "../../../assets/images/background/race_track/street_middle.png";

const raceTrack = {
	name: "Race Track",
	components: {
		general: {
			id: "general",
			image: general,
			text: "General",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetDiagonal: {
			id: "streetDiagonal",
			image: streetDiagonal,
			text: "Street Diagonal",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetDiagonal2: {
			id: "streetDiagonal2",
			image: streetDiagonal2,
			text: "Street Diagonal 2",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetS: {
			id: "streetS",
			image: streetS,
			text: "Street S",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetS2: {
			id: "streetS2",
			image: streetS2,
			text: "Street S 2",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetEmpty: {
			id: "streetEmpty",
			image: streetEmpty,
			text: "Street Empty",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		start: {
			id: "start",
			image: start,
			text: "Start",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetUpRight: {
			id: "streetUpRight",
			image: streetUpRight,
			text: "Street Up Right",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetCone: {
			id: "streetCone",
			image: streetCone,
			text: "Street Cone",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetDiagonal3: {
			id: "streetDiagonal3",
			image: streetDiagonal3,
			text: "Street Diagonal 3",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetDouble: {
			id: "streetDouble",
			image: streetDouble,
			text: "Street Double",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetUp: {
			id: "streetUp",
			image: streetUp,
			text: "Street Up",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetHigh: {
			id: "streetHigh",
			image: streetHigh,
			text: "Street High",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetDouble2: {
			id: "streetDouble2",
			image: streetDouble2,
			text: "Street Double 2",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetDouble3: {
			id: "streetDouble3",
			image: streetDouble3,
			text: "Street Double 3",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetDouble4: {
			id: "streetDouble4",
			image: streetDouble4,
			text: "Street Double 4",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetStraight: {
			id: "streetStraight",
			image: streetStraight,
			text: "Street Straight",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetCorner: {
			id: "streetCorner",
			image: streetCorner,
			text: "Street Corner",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		green: {
			id: "green",
			image: green,
			text: "Green",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		trees: {
			id: "trees",
			image: trees,
			text: "Trees",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		streetMiddle: {
			id: "streetMiddle",
			image: streetMiddle,
			text: "Street Middle",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
	},
};

export default raceTrack;
