/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable arrow-body-style */
import { Grid, Typography } from "@mui/material";
import { PrimaryBorderTagInput } from "../Autocomplete.js";

const ParametersTagInput = ({ param, parameters, setParameters, nodeData }) => {
    return (
        <Grid
            item
            container
            display="flex"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                marginBottom: "5px",
                width: "100%",
            }}
        >
            <Typography sx={{ marginRight: "10px", width: "40%" }}>
                {param.label}
            </Typography>

            <PrimaryBorderTagInput
                id={param.id}
                value={parameters.find((p) => p.id === param.id)?.value || []}
                width="55%"
                onChange={(newValues) => {
                    const processedValues = newValues.map((val) => {
                        // Check if the value inserted is a number or a string
                        return /^[+-]?\d+(\.\d+)?$/.test(val) ? Number(val) : val;
                    });

                    // eslint-disable-next-line no-confusing-arrow
                    const newParams = parameters.map((p) => p.id === param.id ? { ...p, value: processedValues } : p);

                    setParameters(newParams);
                }}
            />
        </Grid>
    );
};

export default ParametersTagInput;
