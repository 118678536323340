import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Create list node

The Create list node... creates a list! A typical example of the Create list node is the following:

![alt text](${transformAsset("create-list.png")})

It should be stated that a list is a custom variable as well, but it is kept in a different place (the Custom lists section at the right) to be easier to distinguish. Like the custom variables you need to provide a name, and a set of initial elements by adding a value and pressing Enter (return).
`;

export default documentation;
