// eslint-disable-next-line import/no-extraneous-dependencies
import ReactNumericInput from "react-numeric-input";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	numericInput: {
		width: ({ width }) => width,
		fontSize: ({ fontSize }) => `${fontSize}!important` || "1rem!important",
		color: "black",
		backgroundColor: theme.palette.third.main,
		borderColor: "transparent",
		outline: "none",
		border: "0px!important",
		height: "30px",
	},
}));

export const NumericInput = ({
	id = "numeric-input",
	disabled = false,
	className = "",
	width = "200px",
	fontSize = "14px",
	value,
	min,
	max,
	step,
	precision,
	format = (num) => num,
	parse = (num) => num,
	mobile = false,
	onChange = () => {},
}) => {
	const classes = useStyles({ width, fontSize });

	return (
		<ReactNumericInput
			key={id}
			id={id}
			disabled={disabled}
			className={`${classes.numericInput} ${className}`}
			value={value}
			{...(min === undefined ? {} : { min })}
			{...(max === undefined ? {} : { max })}
			{...(step === undefined ? {} : { step })}
			{...(precision === undefined ? {} : { precision })}
			format={format}
			parse={parse}
			mobile={mobile}
			onChange={onChange}
		/>
	);
};

export default NumericInput;
