import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Manage list node

This node offers standard functions over lists, i.e. to delete an element, to add an element, and to order (ascending or descending). A typical view of this node follows:

![alt text](${transformAsset("manage-list.png")})

The name of the list must be declared, and the operation should be selected from a dropdown.
An example application where a list is created, is ordered in ascending order and the first element is logged follows:

![alt text](${transformAsset("manage-list-example.png")})

It should be stated that this node performs operations on the list itself, thus it changes its structure.
`;

export default documentation;
