/* eslint-disable camelcase */
import { useEffect, useRef, useState } from "react";
import { Card, CardContent, Typography, IconButton, Grid, Box, Chip, Dialog, DialogTitle, DialogContent, Divider } from "@mui/material";
import { ContentCopy, DeleteOutline, Visibility, Close } from "@mui/icons-material";
import AreYouSurePopup from "./Popups/AreYouSurePopup.js";
import { removeModelFromMarketplace, cloneModelFromStore, newProject, addModelToProject, getModel } from "../api/index.js";
import Form from "./Form.js";
import Popup from "./Popup.js";
import CreateMission from "./create-mission/index.js";
import CreateMap from "./create-map/index.js";
import Testbed from "../screens/Testbed.js";
import MarkdownPreview from "@uiw/react-markdown-preview";
import removeFromMarketplaceIcon from "../assets/dsl-icons/Remove_marketplace.png";

const ModelCard = ({
	model,
	setIsLoading,
	success,
	error,
	fetchData,
	textualDsls,
	graphicalDsls,
	projects,
	fromProject = false,
	publicProjects,
	dslImage,
	fromProjectScreen = false,
	fromDslScreen = false,
	removeFromOtherScreen,
	viewFromOtherScreen,
	cloneFromOtherScreen,
	supportsValidation,
}) => {
	const [removePopupOpen, setRemovePopupOpen] = useState(false);
	const [cloneModelPopupOpen, setCloneModelPopupOpen] = useState(false);
	const [viewPopupOpen, setViewPopupOpen] = useState(false);

	const [popupModel, setPopupModel] = useState(null);
	const modelRef = useRef(null);

	const {
		_id: id,
		title,
		model_type,
		description,
		createdAt,
		isUser,
		creator,
		shopEditable,
		model_text,
		creator_fullname,
		validated,
	} = model;

	const newModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Cloned model title",
			required: true,
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "Cloned model description",
		},
		{
			customType: "autocomplete",
			id: "project",
			options: projects ? projects.map((project) => project?.title) : [],
			multiple: true,
			label: "Select project",
			width: 400,
			filled: false,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Clone model",
		},
	];

	const handleCloneModel = async (values) => {
		setIsLoading(true);
		// Check that the project has value
		if (values?.project !== "" || !values?.project) {
			// Checks if the given project is an already existing project
			const projectExistance = projects.find((project) => project?.title === values?.project);
			// Creates the model
			const { success: scs, message, newModel } = await cloneModelFromStore(model._id, values.title, values.description);
			if (scs) {
				if (projectExistance) {
					// If the given project exists it adds the created project in the project
					const { success: scs, message } = await addModelToProject(projectExistance._id, newModel._id);
					if (scs) {
						success("Model cloned successfully");
						const newTabUrl = `${window.location.origin}/dsls/${model_type}`;
						window.open(newTabUrl, '_blank');
					} else {
						error(message);
					}
				} else {
					// If the given project does not exist it creates the project and then adds the cloned model inside the project
					const { success: scs, message, result } = await newProject(values?.project);
					if (scs) {
						const { success: scs, message } = await addModelToProject(result._id, newModel._id);
						if (scs) {
							success("Model cloned successfully");
							const newTabUrl = `${window.location.origin}/dsls/${model_type}`;
							window.open(newTabUrl, '_blank');
						} else {
							error(message);
						}
					} else {
						error(message);
					}
				}
			} else {
				error(message);
			}
		} else {
			// If the project does not have value just clone the model
			const { success: scs, message } = await cloneModelFromStore(model._id, values.title, values.description);
			if (scs) {
				success(message);
				const newTabUrl = `${window.location.origin}/dsls/${model_type}`;
				window.open(newTabUrl, '_blank');
			} else {
				error(message);
			}
		}

		setCloneModelPopupOpen(false);
		setIsLoading(false);
	};

	const onRemoveClicked = () => {
		setRemovePopupOpen(true);
	};

	const declineRemoveModelFromMarketplace = () => {
		setRemovePopupOpen(false);
	};

	const handleRemoveModelFromMarketplace = async () => {
		setIsLoading(true);
		const { success: scs, message } = await removeModelFromMarketplace(model._id);
		if (scs) {
			success(message);
		} else {
			error(message);
		}

		await fetchData();
		setRemovePopupOpen(false);
		setIsLoading(false);
	};

	useEffect(() => {
		(async () => {
			if (viewPopupOpen) {
				const { success: scs, model: mo } = await getModel(id);
				if (scs) {
					setPopupModel(mo);
				}
			}
		})();
	}, [id, model, viewPopupOpen]);

	return (
		<>
			<AreYouSurePopup
				open={removePopupOpen}
				title="Remove model from Marketplace?"
				content="Are you sure you want to remove this model from Marketplace?"
				onDecline={declineRemoveModelFromMarketplace}
				onAccept={handleRemoveModelFromMarketplace}
			/>
			<Popup
				width="800px"
				open={cloneModelPopupOpen}
				title="Clone Model"
				titleColor="#0D192B"
				titleBackgroundColor="#ABC7D9"
				backgroundColor="#DDE8EF"
				closeIconColor="#0D192B"
				onClose={() => {
					setCloneModelPopupOpen(false);
				}}
			>
				<Form
					content={newModelFormContent}
					onSubmit={handleCloneModel}
				/>
			</Popup>
			<Dialog
				fullWidth
				open={viewPopupOpen}
				maxWidth="md"
				onClose={() => setViewPopupOpen(false)}
			>
				<DialogTitle sx={{
					backgroundColor: "#ABC7D9",
					variant: "h7",
					fontWeight: "bold",
				}}
				>
					{title}
					<IconButton
						sx={{ position: "absolute", right: 8, top: 8 }}
						onClick={() => setViewPopupOpen(false)}
					>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent
					dividers
					sx={{
						overflowY: "auto",
						whiteSpace: "pre-wrap",
						fontFamily: model_type === "textualDsl" ? "monospace" : "inherit",
						maxHeight: "80vh",
						padding: 2,
						backgroundColor: "#DDE8EF",
					}}
				>
					{textualDsls.map((item) => item.replaceAll('-', "")).includes(model_type) ? (
						<Typography
							sx={{
								fontFamily: "monospace",
								color: "text.primary",
							}}
						>
							{model_text || "No model text available."}
						</Typography>
					) : (
						graphicalDsls.includes(model_type) && (
							<Grid height="700px">
								{viewPopupOpen && model_type === "envpop" && (
									<CreateMission ref={modelRef} inPopup model={popupModel?.model_text} dbitem={popupModel} />
								)}
								{viewPopupOpen && model_type === "envmaker" && (
									<CreateMap inPopup model={popupModel?.model_text} />
								)}
								{viewPopupOpen && model_type === "appcreator" && (
									<Testbed
										previewMode
										model={model_text}
										dbitem={model}
										modelid={id}
										userid={creator._id}
										inSimulation={false}
										validationErrors={[]}
									/>
								)}
							</Grid>
						)
					)}
				</DialogContent>
			</Dialog>
			<Card
				sx={{
					width: "100%",
					height: 300,
					maxWidth: 400,
					margin: 2,
					boxShadow: 6,
					borderRadius: 3,
					overflow: "hidden",
					position: "relative",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					backgroundColor: "#323D4D",
					"&:hover": {
						boxShadow: 12,
						transform: "scale(1.02)",
						transition: "all 0.3s ease",
						"& .custom-scrollbar::-webkit-scrollbar": {
							visibility: "visible",
						},
					},
				}}
			>
				<Box
					sx={{
						backgroundColor: "#ABC7D9",
						padding: 1,
						color: "white.main",
						display: "flex",
						alignItems: "start",
						justifyContent: "start",
					}}
				>
					<Typography
						variant="h7"
						fontWeight="bold"
						sx={{
							wordWrap: "break-word",
							textAlign: "start",
							color: "black",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{title || "Untitled"}
					</Typography>

				</Box>

				<CardContent
					className="custom-scrollbar"
					sx={{
						flex: 1,
						overflowY: "auto",
						maxHeight: "150px",
						padding: 2,
						scrollbarWidth: "none",
					}}
				>
					<Grid container direction="column">
						<Grid item>
							<Typography
								variant="body2"
								color="textSecondary"
								sx={{
									wordWrap: "break-word",
									fontStyle: "italic",
									fontWeight: "bold",
									color: "white !important",
									mb: "1rem",
								}}
							>
								<MarkdownPreview
									source={description?.length > 40 ? `${description.slice(0, 40)}...` : description || "No description available."}
									style={{ background: "transparent", color: "inherit", fontSize: "0.8rem" }}
								/>
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant="caption"
								color="textSecondary"
								sx={{
									color: "secondary.main",
								}}
							>
								{"Created At: "}
								{new Date(createdAt).toLocaleDateString("el-GR")}
							</Typography>
						</Grid>
						{/* <Grid item>
							{tags && tags.length > 0 && (
								<Box
									sx={{
										display: "flex",
										flexWrap: "wrap",
										gap: 1,
										marginTop: 1,
									}}
								>
									{tags.map((tag, index) => (
										tag !== "" && (
											<Chip
												key={index}
												label={tag}
												size="small"
												sx={{
													backgroundColor: "secondary.dark",
													color: "white.main",
													fontSize: "0.80rem",
													fontWeight: 500,
												}}
											/>
										)
									))}
								</Box>
							)}
						</Grid> */}
						{!fromProject && !fromDslScreen && model?.publishedInProject && !fromProjectScreen && (
							<Grid item>
								<Typography
									variant="caption"
									color="#ABC7D9 !important"
									sx={{
										fontSize: "14px",
									}}
								>
									{"Published in Project: "}
									{
										publicProjects?.find((prj) => prj?.models?.includes(model._id))?.title || "Unknown"
									}
								</Typography>
							</Grid>
						)}
						<Grid item>
							<Typography
								variant="caption"
								color="textSecondary"
								sx={{
									color: "white.main",
									fontSize: "14px",
									fontWeight: "bold",
								}}
							>
								{"Created By: "}
								{creator_fullname || creator?.fullname || "Unknown"}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>

				<Box sx={{ padding: 2, position: "relative" }}>
					{!fromProjectScreen && (
						<Grid item>
							<img
								src={dslImage}
								alt={dslImage}
								style={{
									height: 30,
								}}
							/>
						</Grid>
					)}
					<Divider sx={{ backgroundColor: "white.main", marginBottom: "1rem" }} />
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item>
							{!fromProjectScreen && !fromDslScreen ? (
								<Chip
									label={shopEditable ? "Editable" : "Non-Editable"}
									color={shopEditable ? "success" : "error"}
									size="small"
									sx={{
										fontWeight: 500,
										color: "black",
										fontSize: "0.875rem",
										background: shopEditable ? "#03DAC5 !important" : "#CF6679 !important",
									}}
								/>
							) : fromProjectScreen ? (
								<img
									src={dslImage}
									alt={dslImage}
									style={{
										height: 30,
									}}
								/>
							) : fromDslScreen ? (
								<Chip
									label={(validated || !supportsValidation) ? "Validated" : "Not Validated"}
									size="small"
									sx={{
										fontWeight: 500,
										color: "primary.main",
										fontSize: "0.875rem",
										backgroundColor: (validated || !supportsValidation) ? "#03DAC5" : "#CF6679",
									}}
								/>

							) : null}

						</Grid>
						<Grid item>
							<Box sx={{ display: "flex", gap: 1 }}>

								{/* View Model Button */}
								<IconButton
									aria-label="view model"
									sx={{
										backgroundColor: "#ABC7D9 !important",
										borderRadius: "50%",
										boxShadow: 3,
										padding: 1,
										width: 40,
										height: 40,
										"&:hover": {
											backgroundColor: "#0D192B !important",
											"& .MuiSvgIcon-root": {
												color: "#ABC7D9 !important",
											},
										},
									}}
									onClick={(fromProjectScreen || fromDslScreen) ? viewFromOtherScreen : () => setViewPopupOpen(true)}
									onAuxClick={(event) => {
										if (event.button === 1) {
											event.preventDefault();
											if (fromProjectScreen || fromDslScreen) {
												const baseUrl = window.location.origin;
												const modelUrl = `${baseUrl}/dsls/${model_type}/${model?._id}`;
												window.open(modelUrl, "_blank");
											}
										}
									}}
								>
									<Visibility sx={{
										color: "#0D192B !important",
									}}
									/>
								</IconButton>

								{/* Clone Model Button */}
								{!fromProject && (
									<IconButton
										aria-label="clone model"
										sx={{
											backgroundColor: "#ABC7D9 !important",
											borderRadius: "50%",
											boxShadow: 3,
											padding: 1,
											width: 40,
											height: 40,
											"&:hover": {
												backgroundColor: "#0D192B !important",
												"& .MuiSvgIcon-root": {
													color: "#ABC7D9 !important",
												},
											},
										}}
										onClick={(fromProjectScreen || fromDslScreen) ? cloneFromOtherScreen : () => setCloneModelPopupOpen(true)}
									>
										<ContentCopy sx={{
											color: "#0D192B !important",
										}}
										/>
									</IconButton>
								)}

								{/* Remove Model Button */}
								{isUser && !fromProject && !fromProjectScreen && !fromDslScreen && !model?.publishedInProject && (
									<IconButton
										aria-label="remove model"
										sx={{
											backgroundColor: "#ABC7D9",
											border: null,
											borderRadius: "50%",
											boxShadow: 3,
											padding: 1,
											"&:hover": {
												backgroundColor: "primary.main",
												borderColor: "#CF6679",
												"& .MuiSvgIcon-root": {
													color: "#0D192B",
												},
											},
										}}
										onClick={() => onRemoveClicked(model)}
									>
										<img
											src={removeFromMarketplaceIcon}
											alt="Remove from Marketplace"
											style={{ width: 24, height: 24 }}
										/>
									</IconButton>
								)}
								{(fromProjectScreen || fromDslScreen) && (
									<IconButton
										aria-label="remove model"
										sx={{
											backgroundColor: "transparent",
											border: `2px solid #CF6679`,
											borderRadius: "50%",
											boxShadow: 3,
											padding: 1,
											width: 40,
											height: 40,
											"&:hover": {
												backgroundColor: "#CF6679",
												borderColor: "#CF6679",
												"& .MuiSvgIcon-root": {
													color: "#0D192B",
												},
											},
										}}
										onClick={() => removeFromOtherScreen(model?._id)}
									>
										<DeleteOutline sx={{
											color: "#CF6679",
										}}
										/>
									</IconButton>
								)}
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Card>
		</>
	);
};

export default ModelCard;
