import decode from "jwt-decode";

import cookie from "./cookie.js";

const jwt = {
	getToken: () => cookie.get("_locsys_platform"),
	setToken: (token) => token && cookie.set("_locsys_platform", token),
	destroyToken: () => {
		cookie.remove("_locsys_platform");
	},
	isAuthenticated: () => {
		const token = cookie.get("_locsys_platform");
		return token && token !== "undefined";
	},
	decode: () => {
		const token = cookie.get("_locsys_platform");
		if (token) return decode(token);
		cookie.remove("_locsys_platform");
		window.location.href = "/";
		return null;
	},
};

export default jwt;
