import { memo, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Grid, Typography, Switch } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DslCard from "../components/DslCard.js";
import { makeStyles } from '@mui/styles';

import Search from "../components/Search.js";
import Spinner from "../components/Spinner.js";
import { isFuzzyMatch } from "../utils/index.js";

import { getDslsStats } from "../api/index.js";
import { getDslsConstants } from "../dsls/index.js";

const useStyles = makeStyles({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "#BB86FC",
		"&:before": {
			borderColor: "#BB86FC",
		},
		"&:after": {
			borderColor: "#BB86FC",
		},
		"& .MuiSelect-icon": {
			color: "white",
		},
	},
});

const Dsls = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [searchFilter, setSearchFilter] = useState("");
	const [selectedTag, setSelectedTag] = useState("All");
	const [dsls, setDsls] = useState([]);
	const [tags, setTags] = useState([]);
	const [filteredDsls, setFilteredDsls] = useState(dsls);
	const [experimentalFlag, setExperimentalFlag] = useState(false);
	const classes = useStyles();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			const tmpTags = ["All"];

			const stats = await getDslsStats();
			const constants = await getDslsConstants();
			for (const dsl of constants) {
				dsl.modelsCount = stats.models.find((m) => m._id === dsl.short)?.count || 0;
				dsl.usersCount = stats.users.find((m) => m._id === dsl.short)?.count || 0;
				dsl.validationsCount = stats.validations.find((m) => m._id === dsl.short)?.count || 0;
				for (const tag of dsl.tags) {
					if (!tmpTags.includes(tag)) tmpTags.push(tag);
				}
			}

			// Sort tags by name
			tmpTags.sort((a, b) => a.localeCompare(b));
			setTags(tmpTags);

			// sort by enabled
			constants.sort((a, b) => {
				if (a.enabled === b.enabled) return 0;
				if (a.enabled) return -1;
				return 1;
			});

			setDsls(constants);
			setIsLoading(false);
		},
		[],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	useEffect(() => {
		setFilteredDsls(
			dsls
				.filter((us) => isFuzzyMatch(us.name, searchFilter))
				.filter((us) => selectedTag === "All" || us.tags.includes(selectedTag))
				.filter((us) => (experimentalFlag && us.experimental) || (!experimentalFlag && !us.experimental)),
		);
	}, [searchFilter, dsls, selectedTag, experimentalFlag]);

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					mt={2}
					pr={2}
					mb={2}
					display="flex"
					minHeight="60px"
					borderRadius="20px"
					alignItems="center"
					justifyContent="space-between"
					width="1280px"
				>
					<Grid
						item
						display="flex"
						flexDirection="row"
						alignItems="center"
						height="60px"
					>
						<Typography variant="h5" color="#ffffff">
							{"ALL DSLs"}
						</Typography>
					</Grid>
					<Grid
						item
						xs={4}
						display="flex"
						flexDirection="row"
						alignItems="baseline"
					>
						{/* Dropdown for tags */}
						<Typography color="#ffffff" sx={{ pr: 1 }}>
							{"Tag:"}
						</Typography>
						<FormControl variant="standard" sx={{ minWidth: 120, pr: 2 }}>

							<Select
								className={classes.select}
								value={selectedTag ?? "All"}
								onChange={(event) => {
									setSelectedTag(event.target.value);
								}}
							>
								{
									tags.map((e, ind) => (
										<MenuItem key={ind} value={e}>{e}</MenuItem>
									))
								}
							</Select>
						</FormControl>
						<Grid
							item
							display="flex"
							flexDirection="row"
							justifyContent="flex-start"
							alignItems="center"
							style={{
								width: "100%",
								marginBottom: "10px",
								color: "white",
							}}
						>
							<Typography
								style={{
									width: "100%",
									fontSize: "14px",
									fontWeight: "bold",
									padding: "5px",
									margin: "1px",
									marginTop: "5px",
									borderRadius: "5px",
								}}
							>
								{"Experimental"}
							</Typography>
							<Switch
								checked={experimentalFlag}
								sx={{
									'& .MuiSwitch-switchBase.Mui-checked': {
										color: '#03DAC5',
									},
									'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
										backgroundColor: '#03DAC5',
									},
									'& .MuiSwitch-switchBase': {
										color: '#5B6471',
									},
									'& .MuiSwitch-switchBase + .MuiSwitch-track': {
										backgroundColor: '#323D4D',
									},
								}}
								onChange={() => setExperimentalFlag((prev) => !prev)}
							/>

						</Grid>

						<Search
							value={searchFilter}
							onChange={(event) => setSearchFilter(event.target.value)}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={2}
					mt={2}
					xs={11}
					justifyContent="center"
					width="1300px"
				>
					{filteredDsls.map((us, ind) => (
						<Link
							key={`comp_link_${ind}`}
							to={us.enabled ? `/dsls/${us.short}` : null}
						>
							<DslCard dsl={us} />
						</Link>
					))}

				</Grid>
			</Grid>
		</>
	);
};

export default memo(Dsls);
