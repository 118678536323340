/* eslint-disable no-continue */
/* eslint-disable camelcase */
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { SketchPicker } from "react-color";

import Tooltip from "../../Tooltip.js";
import Popup from "../../Popup.js";
import { useSnackbar } from "../../../utils/index.js";
import Form from "../../Form.js";
import { ErrorBorderButton } from "../../Buttons.js";
import { Grid } from "@mui/material";

const toTitleCase = (phrase) => (phrase
	.toLowerCase()
	.split(" ")
	.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
	.join(" ")
);

const colors = [
	{ color: '#ff0000', title: 'Red' },
	{ color: '#ffffff', title: 'White' },
	{ color: '#00ff00', title: 'Green' },
	{ color: '#0000ff', title: 'Blue' },
	{ color: '#000000', title: 'Black' },
	{ color: '#ffff00', title: 'Yellow' },
	{ color: '#ff00ff', title: 'Magenta' },
	{ color: '#00ffff', title: 'Cyan' },
];

const componentToHex = (c) => {
	const hex = c.toString(16);
	return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = (r, g, b) => `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

const hexToRgb = (hex) => {
	const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex);
	return result ? {
		r: Number.parseInt(result[1], 16),
		g: Number.parseInt(result[2], 16),
		b: Number.parseInt(result[3], 16),
	} : null;
};

const Item = forwardRef(({
	mode = "view",
	id = "",
	name: propsName = "",
	initialName = "",
	position: propsPosition = { x: 0, y: 0 },
	icon = null,
	icons: propsIcons = {},
	variables: propsVariables = {},
	elementDimensions: propsElementDimensions = 30,
	mapWidth: propsMapWidth = 100,
	mapHeight: propsMapHeight = 80,
	resolution: propsResolution = 0.1,
	cat = "",
	itemCat = "",
	tooltip = { header: "", body: "" },
	items: propsItems = [],
	updateItem: propsUpdateItem = () => {},
	deleteItem: propsDeleteItem = () => {},
	checkNameExists: propsCheckNameExists = () => {},
	createNewItem: propsCreateNewItem = () => {},
	setDescriptionTitle: propsSetDescriptionTitle = () => {},
	setDescriptionBody: propsSetDescriptionBody = () => {},
	onDrag: propsOnDrag = () => {},
	onDragStop: propsOnDragStop = () => {},
}, ref) => {
	const [name, setName] = useState(propsName);
	const [position, setPosition] = useState(propsPosition);
	const [variables, setVariables] = useState(propsVariables);
	const [elementDimensions, setElementDimensions] = useState(propsElementDimensions);

	const [mapWidth, setMapWidth] = useState(propsMapWidth);
	const [mapHeight, setMapHeight] = useState(propsMapHeight);
	const [resolution, setResolution] = useState(propsResolution);

	const [dragging, setDragging] = useState(false);
	const [popupOpen, setPopupOpen] = useState(false);
	const [extraPopupOpen, setExtraPopupOpen] = useState(false);
	const [extraVariable, setExtraVariable] = useState(null);
	const [metersTooltipOpen, setMetersTooltipOpen] = useState(false);
	const [metersTooltipPosition, setMetersTooltipPosition] = useState({ x: 0, y: 0 });
	const [infoTooltipOpen, setInfoTooltipOpen] = useState(false);
	const [metersDisplay, setMetersDisplay] = useState(false);
	const [tmpFormValues, setTmpFormValues] = useState(Object.fromEntries(
		[...Object.keys(variables).map((variable) => [variable, variables[variable].value]), ["itemName", name]],
	));
	const [tmpFormExtraValues, setTmpFormExtraValues] = useState({});

	const [simulationRangeOpen, setSimulationRangeOpen] = useState(false);
	let simulationRangeTimeout;
	const [simulationMessageOpen, setSimulationMessageOpen] = useState(false);
	const [simulationMessageInfo, setSimulationMessageInfo] = useState({});
	let simulationMessageTimeout;

	const isHuman = itemCat === "human";
	const isColor = itemCat === "color";
	const isRobot = itemCat === "robot";
	const isPoi = itemCat === "poi";
	const isCameraDistance = itemCat === "camera" || itemCat === "distance";
	const isSensor = cat === "sensors";

	const { error } = useSnackbar();

	const sendUpdate = (newValues, updateHost = true) => {
		const tmpState = {
			id,
			itemName: name,
			x: position.x,
			y: position.y,
			variables,
			cat,
			itemCat,
		};

		for (const [key, value] of Object.entries(newValues)) {
			tmpState[key] = value;
		}

		propsUpdateItem(cat, itemCat, id, tmpState, updateHost);
	};

	const onClick = () => {
		setPopupOpen(true);
	};

	const onStart = (event) => {
		event.preventDefault();
		const xMeters = (((position.x / 100) * mapWidth) * resolution).toFixed(2);
		const yMeters = (((position.y / 100) * mapHeight) * resolution).toFixed(2);
		setMetersTooltipOpen(true);
		setMetersTooltipPosition({ x: xMeters, y: yMeters });
		propsSetDescriptionTitle(null);
		propsSetDescriptionBody(null);
		setInfoTooltipOpen(false);
	};

	const onDrag = (event, data) => {
		event.preventDefault();
		const xMeters = ((((position.x / 100) * mapWidth) + data.x) * resolution).toFixed(2);
		const yMeters = ((((position.y / 100) * mapHeight) - data.y) * resolution).toFixed(2);
		setMetersTooltipPosition({ x: xMeters, y: yMeters });
		setDragging(true);
	};

	const onStop = (event, data) => {
		event.preventDefault();
		if (dragging) {
			const newX = Math.max(Math.min(((((position.x / 100) * mapWidth) + data.x) / mapWidth) * 100, 100), 0);
			const newY = Math.max(Math.min((((((position.y / 100) * mapHeight) - data.y) / mapHeight) * 100), 100), 0);
			sendUpdate({ x: newX, y: newY });
		} else {
			onClick();
		}

		setDragging(false);
		setMetersTooltipOpen(false);
	};

	const onMenuStart = (event) => {
		event.preventDefault();
		setDragging(true);
	};

	const onMenuDrag = (event) => {
		event.preventDefault();
		const { x, y } = event;
		propsOnDrag(x, y);
	};

	const onMenuStop = (event) => {
		event.preventDefault();
		propsOnDragStop();
		setDragging(false);

		const mapComponent = document.querySelector("#map");
		const { top, right, bottom, left } = mapComponent.getBoundingClientRect();
		const { x, y } = event;

		if (x >= left && x <= right && y >= top && y <= bottom) {
			const newX = ((x - left) / mapWidth) * 100;
			const newY = ((bottom - y) / mapHeight) * 100;
			propsCreateNewItem(cat, itemCat, newX, newY);
		}
	};

	const deleteItem = () => {
		propsDeleteItem(cat, itemCat, id);
	};

	const checkNameExists = (value) => {
		const { matchesPattern, found } = propsCheckNameExists(value, initialName, cat, itemCat, id);

		if (matchesPattern) {
			error("Name cannot follow the template category_number");
		} else if (found) {
			error("Name already exists");
		}

		return matchesPattern || found;
	};

	const onSettingsSubmit = (values) => {
		const { itemName, ...newValues } = values;

		if (itemName !== name && checkNameExists(itemName)) {
			return;
		}

		const newVariables = { ...variables };
		for (const [key, value] of Object.entries(newValues)) {
			newVariables[key].value = value;
		}

		if (itemCat === "robot") {
			const { camera, cameraOnPanTilt, rfid_reader, rfidOnPanTilt } = newValues;
			const { sonar_pan_tilt } = tmpFormExtraValues;
			newVariables.panTilt.value = (camera !== undefined && camera && cameraOnPanTilt !== undefined && cameraOnPanTilt)
				|| (rfid_reader !== undefined && rfid_reader && rfidOnPanTilt !== undefined && rfidOnPanTilt)
				|| (sonar_pan_tilt !== undefined && sonar_pan_tilt);
			for (const variab of Object.keys(newVariables)) {
				if (newVariables[variab]?.templateId) {
					newVariables[variab].ids[0] = `${itemName.toLowerCase().split(" ").join("_")}_${newVariables[variab].templateId}`;
				}

				for (const subvariab of Object.keys(newVariables?.[variab]?.extra ?? {})) {
					if (newVariables[variab].extra[subvariab]?.templateId) {
						newVariables[variab].extra[subvariab].ids[0] = `${itemName.toLowerCase().split(" ").join("_")}_${newVariables[variab].extra[subvariab].templateId}`;
					}
				}
			}
		}

		let updateHost = true;
		if (isCameraDistance) {
			const { onPanTilt } = newValues;
			const { host } = newVariables;

			if (onPanTilt !== undefined && !onPanTilt && host.value) {
				updateHost = false;
				propsDeleteItem("effectors", "panTilt", host.value, id, itemCat, cat);
			} else if (onPanTilt !== undefined && onPanTilt && !host.value) {
				updateHost = false;
				propsCreateNewItem("effectors", "panTilt", position.x, position.y, id, itemCat, cat);
			}
		}

		sendUpdate({ variables: newVariables, itemName: itemName.toLowerCase().split(" ").join("_") }, updateHost);
		setPopupOpen(false);
	};

	const onExtraSettingsSubmit = (values) => {
		const { itemName, ...newValues } = tmpFormValues;

		if (itemName !== name && checkNameExists(itemName)) {
			return;
		}

		const newVariables = { ...variables };
		for (const [key, value] of Object.entries(newValues)) {
			newVariables[key].value = value;
		}

		if (itemCat === "robot") {
			const { camera, cameraOnPanTilt, rfid_reader, rfidOnPanTilt } = newValues;
			const { sonar_pan_tilt } = values;
			newVariables.panTilt.value = (camera && cameraOnPanTilt) || (rfid_reader && rfidOnPanTilt) || sonar_pan_tilt;

			for (const variab of Object.keys(newVariables)) {
				if (newVariables[variab]?.templateId) {
					newVariables[variab].ids[0] = `${itemName.toLowerCase().split(" ").join("_")}_${newVariables[variab].templateId}`;
				}
			}
		}

		if (isCameraDistance) {
			const { onPanTilt } = newValues;
			const { host } = newVariables;

			if (onPanTilt !== undefined && !onPanTilt && host.value) {
				propsDeleteItem("effectors", "panTilt", host.value, id, itemCat, cat);
			} else if (onPanTilt !== undefined && onPanTilt && !host.value) {
				propsCreateNewItem("effectors", "panTilt", position.x, position.y, id, itemCat, cat);
			}
		}

		const newExtraVariables = { ...variables[extraVariable].extra };
		for (const [key, value] of Object.entries(values)) {
			newExtraVariables[key].value = value;
		}

		for (const variab of Object.keys(newExtraVariables)) {
			if (newExtraVariables[variab]?.templateId) {
				newExtraVariables[variab].ids[0] = `${itemName.toLowerCase().split(" ").join("_")}_${newExtraVariables[variab].templateId}`;
			}
		}

		newVariables[extraVariable].extra = newExtraVariables;

		sendUpdate({ variables: newVariables, itemName: itemName.toLowerCase().split(" ").join("_") });
		setExtraPopupOpen(false);
	};

	const changeRGB = (color) => {
		const { r, g, b } = hexToRgb(color.hex);
		const newVariables = { ...variables };
		newVariables.r.value = r;
		newVariables.g.value = g;
		newVariables.b.value = b;

		sendUpdate({ variables: newVariables });
	};

	const closePopup = () => {
		setPopupOpen(false);
		setTmpFormValues(Object.fromEntries(
			[...Object.keys(variables).map((variable) => [variable, variables[variable].value]), ["itemName", name]],
		));
	};

	const closeExtraPopup = () => {
		setExtraPopupOpen(false);
		setTmpFormExtraValues(Object.fromEntries(Object.keys(variables[extraVariable].extra)
			.map((variable) => [variable, variables[extraVariable].extra[variable].value])));
	};

	const formContent = useMemo(() => {
		const content = [{
			customType: "input",
			id: "itemName",
			type: "text",
			label: "Name",
			placeholder: "Name",
			value: tmpFormValues.itemName,
			onBlur: (event) => {
				setTmpFormValues({ ...tmpFormValues, itemName: event.target.value });
			},
		}];

		for (const [variable, options] of Object.entries(variables)) {
			const {
				type,
				name: varName,
				placeholder,
				min,
				max,
				step,
				precision,
				format,
				options: selectItems,
				variables: optionVariables,
				needs,
				hide,
				affects,
			} = options;
			if (isColor && ["r", "g", "b"].includes(variable)) continue;

			if (needs) {
				let needsMet = true;
				for (const neededKey of Object.keys(needs)) {
					const neededValue = needs[neededKey];
					if (tmpFormValues?.[neededKey] !== undefined) {
						let found = false;
						for (const val of neededValue) {
							if (tmpFormValues[neededKey] === val) {
								found = true;
								break;
							}
						}

						needsMet = found;
						if (!needsMet) {
							break;
						}
					}
				}

				if (!needsMet) {
					continue;
				}
			}

			if (variable === "automationLoop" && (itemCat === "robot" || itemCat === "human")) {
				const isReverse = tmpFormValues.automationReverse;
				let isStartEndSame = false;

				if (tmpFormValues?.automation
					&& tmpFormValues?.automationPoints
					&& tmpFormValues?.automationPoints.length > 1) {
					const points = tmpFormValues.automationPoints;
					isStartEndSame = points[0].id === points.at(-1).id;
				}

				if (!isReverse && !isStartEndSame) {
					continue;
				}
			}

			if (hide) continue;

			switch (type) {
				case "text": {
					content.push({
						customType: "input",
						id: variable,
						type: "text",
						label: varName,
						placeholder,
						value: tmpFormValues[variable],
						onBlur: (event) => {
							setTmpFormValues({ ...tmpFormValues, [variable]: event.target.value });
						},
					});

					break;
				}

				case "number": {
					content.push({
						customType: "number",
						id: variable,
						type: "number",
						label: varName,
						value: tmpFormValues[variable],
						min: (typeof min === "string" ? tmpFormValues?.[min] : min),
						max: (typeof max === "string" ? tmpFormValues?.[max] : max),
						step,
						precision,
						...(format ? {
							format: (num) => `${num}${format}`,
						} : {}),
						...(format ? {
							parse: (num) => num.replace(format, ""),
						} : {}),
					});

					break;
				}

				case "boolean": {
					content.push({
						customType: "checkbox",
						id: variable,
						label: varName,
						defaultValue: tmpFormValues[variable],
						color: "primary",
						onChange: (event) => {
							const isPanTiltDevice = itemCat === "robot" && (variable === "cameraOnPanTilt" || variable === "rfidOnPanTilt");
							let onPanTilt;
							if (isPanTiltDevice) {
								onPanTilt = variable === "cameraOnPanTilt"
									? (tmpFormValues.rfid_reader && tmpFormValues.rfidOnPanTilt)
									|| (tmpFormExtraValues.sonar_pan_tilt)
									|| (tmpFormValues.camera && event.target.checked)
									: (tmpFormValues.camera && tmpFormValues.cameraOnPanTilt)
									|| (tmpFormExtraValues.sonar_pan_tilt)
									|| (tmpFormValues.rfid_reader && event.target.checked);
							}

							setTmpFormValues({
								...tmpFormValues,
								[variable]: event.target.checked,
								...(isPanTiltDevice ? { panTilt: onPanTilt } : {}),
							});
						},
					});

					break;
				}

				case "select": {
					content.push({
						customType: "dropdown",
						id: variable,
						label: varName,
						defaultValue: tmpFormValues[variable],
						items: typeof selectItems === "string"
							? tmpFormValues[selectItems].map((item) => ({ value: item, text: item }))
							: selectItems.map((item) => ({ value: item, text: item })),
						onChange: (event) => {
							setTmpFormValues({ ...tmpFormValues, [variable]: event.target.value });
						},
					});

					break;
				}

				case "tagInput": {
					content.push({
						customType: "tagInput",
						id: variable,
						label: varName,
						defaultValue: tmpFormValues[variable],
						onChange: (values) => {
							const formValues = { ...tmpFormValues };
							formValues[variable] = values;

							if (affects) {
								for (const affect of affects) {
									if (!values.includes(tmpFormValues[affect])) {
										formValues[affect] = values[0];
									}
								}
							}

							setTmpFormValues(formValues);
						},
					});

					break;
				}

				case "switch": {
					content.push({
						customType: "switch",
						id: variable,
						label: varName,
						defaultValue: tmpFormValues[variable],
						onChange: (event) => {
							setTmpFormValues({ ...tmpFormValues, [variable]: event.target.checked });
						},
					});

					break;
				}

				case "extra": {
					content.push({
						customType: "button",
						id: variable,
						text: varName,
						color: "secondary",
						onClick: () => {
							setExtraPopupOpen(true);
							setExtraVariable(variable);
							setTmpFormExtraValues(Object.fromEntries(Object.keys(variables[variable].extra)
								.map((variab) => [variab, variables[variable].extra[variab].value])));
							setPopupOpen(false);
						},
					});

					break;
				}

				case "pois": {
					content.push({
						customType: "steps",
						id: variable,
						label: varName,
						defaultValue: tmpFormValues[variable],
						items: Object.values(propsItems?.general?.poi ?? {}).map((poi) => ({
							id: poi.id,
							name: poi.itemName,
							x: poi.x,
							y: poi.y,
						})),
						placeholder,
						onChange: (values) => {
							setTmpFormValues({ ...tmpFormValues, [variable]: values });
						},
					});

					break;
				}

				case "stateInput": {
					content.push({
						customType: "stateInput",
						id: variable,
						label: varName,
						placeholder: "No automation steps",
						value: tmpFormValues[variable],
						variables: Object.fromEntries(optionVariables.map((variab) => [variab, { ...variables[variab], tmpFormValues }])),
						onChange: (values) => {
							setTmpFormValues({ ...tmpFormValues, [variable]: values });
						},
					});

					break;
				}

				default:
				// Do nothing
			}
		}

		content.push({
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save",
		});

		return content;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, variables, tmpFormValues]);

	const extraFormContent = useMemo(() => {
		const content = [];

		for (const [variable, options] of Object.entries(variables?.[extraVariable]?.extra ?? {})) {
			const {
				type,
				name: varName,
				placeholder,
				min,
				max,
				step,
				precision,
				format,
				options: selectItems,
				needs,
			} = options;
			if (needs) {
				let needsMet = true;
				for (const neededKey of Object.keys(needs)) {
					const neededValue = needs[neededKey];
					if (tmpFormExtraValues?.[neededKey] !== undefined) {
						let found = false;
						for (const val of neededValue) {
							if (tmpFormExtraValues[neededKey] === val) {
								found = true;
								break;
							}
						}

						needsMet = found;
						if (!needsMet) {
							break;
						}
					}
				}

				if (!needsMet) {
					continue;
				}
			}

			switch (type) {
				case "text": {
					content.push({
						customType: "input",
						id: variable,
						type: "text",
						label: varName,
						placeholder,
						value: tmpFormExtraValues[variable],
						onChange: (event) => {
							setTmpFormExtraValues({ ...tmpFormExtraValues, [variable]: event.target.value });
						},
					});

					break;
				}

				case "number": {
					content.push({
						customType: "number",
						id: variable,
						type: "number",
						label: varName,
						value: variables[extraVariable].extra[variable].value,
						min: (typeof min === "string" ? tmpFormExtraValues?.[min] : min),
						max: (typeof max === "string" ? tmpFormExtraValues?.[max] : max),
						step,
						precision,
						...(format ? {
							format: (num) => `${num}${format}`,
						} : {}),
						...(format ? {
							parse: (num) => num.replace(format, ""),
						} : {}),
						onChange: (val) => {
							setTmpFormExtraValues({ ...tmpFormExtraValues, [variable]: val });
						},
					});

					break;
				}

				case "boolean": {
					content.push({
						customType: "checkbox",
						id: variable,
						label: varName,
						defaultValue: tmpFormExtraValues[variable],
						color: "primary",
						onChange: (event) => {
							const isPanTiltDevice = itemCat === "robot" && (variable === "sonar_pan_tilt");
							let onPanTilt;
							if (isPanTiltDevice) {
								onPanTilt = (tmpFormValues.rfid_reader && tmpFormValues.rfidOnPanTilt)
									|| (tmpFormValues.camera && tmpFormValues.cameraOnPanTilt)
									|| (event.target.checked);
							}

							setTmpFormValues({
								...tmpFormValues,
								...(isPanTiltDevice ? { panTilt: onPanTilt } : {}),
							});
							setTmpFormExtraValues({ ...tmpFormExtraValues, [variable]: event.target.checked });
						},
					});

					break;
				}

				case "select": {
					content.push({
						customType: "dropdown",
						id: variable,
						label: varName,
						defaultValue: tmpFormExtraValues[variable],
						items: selectItems.map((item) => ({ value: item, text: item })),
						onChange: (event) => {
							setTmpFormExtraValues({ ...tmpFormExtraValues, [variable]: event.target.value });
						},
					});

					break;
				}

				case "switch": {
					content.push({
						customType: "switch",
						id: variable,
						label: varName,
						defaultValue: tmpFormExtraValues[variable],
						onChange: (event) => {
							setTmpFormExtraValues({ ...tmpFormExtraValues, [variable]: event.target.checked });
						},
					});

					break;
				}

				default:
				// Do nothing
			}
		}

		content.push({
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save",
		});

		return content;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, variables, tmpFormExtraValues, extraVariable]);

	useEffect(() => {
		sendUpdate({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setMapWidth(propsMapWidth);
	}, [propsMapWidth]);

	useEffect(() => {
		setMapHeight(propsMapHeight);
	}, [propsMapHeight]);

	useEffect(() => {
		if (propsPosition.x !== position.x || propsPosition.y !== position.y) {
			setPosition(propsPosition);
		}
	}, [position.x, position.y, propsPosition]);

	useEffect(() => {
		setVariables(propsVariables);
		setTmpFormValues(Object.fromEntries(
			[...Object.keys(propsVariables).map((variable) => [variable, propsVariables[variable].value]), ["itemName", name]],
		));
	}, [name, propsVariables]);

	useEffect(() => {
		setName(propsName);
	}, [propsName]);

	useEffect(() => {
		setResolution(propsResolution);
	}, [propsResolution]);

	useEffect(() => {
		setElementDimensions(propsElementDimensions);
	}, [propsElementDimensions]);

	useImperativeHandle(ref, () => ({
		simulationRangeStart() {
			if (!simulationRangeTimeout) {
				setSimulationRangeOpen(true);

				simulationRangeTimeout = setTimeout(() => {
					setSimulationRangeOpen(false);
					clearTimeout(simulationRangeTimeout);
				}, 5000);
			}
		},
		simulationMessageStart(targetState) {
			setSimulationMessageOpen(true);
			setSimulationMessageInfo(targetState);
			if (simulationMessageTimeout) clearTimeout(simulationMessageTimeout);

			simulationMessageTimeout = setTimeout(() => {
				setSimulationMessageOpen(false);
			}, 5000);
		},
	}));

	const fov = variables.fov?.value || 10;
	const cameraRange = (isCameraDistance)
		? (fov < 180
			? `conic-gradient(transparent 0deg ${90 - fov / 2}deg, rgba(255, 0, 0, 0.4) ${90 - fov / 2}deg ${90 + fov / 2}deg, transparent ${90 + fov / 2}deg 360deg)`
			: `conic-gradient(rgba(255, 0, 0, 0.4) 0deg ${90 + fov / 2}deg, transparent ${90 + fov / 2}deg ${360 + (90 - fov / 2)}deg, rgba(255, 0, 0, 0.4) ${360 + (90 - fov / 2)}deg 360deg)`
		)
		: null;
	const robotScale = 4 / 5;
	const humanScale = 3 / 2;

	return ([
		<Draggable
			key={`item_${id}`}
			allowAnyClick={false}
			{... (mode === "edit" ? { grid: [5, 5] } : {})}
			zIndex={100}
			position={{ x: 0, y: 0 }}
			disabled={mode === "view"}
			onStart={mode === "menu" ? onMenuStart : (mode === "edit" ? onStart : () => {})}
			onDrag={mode === "menu" ? onMenuDrag : (mode === "edit" ? onDrag : () => {})}
			onStop={mode === "menu" ? onMenuStop : (mode === "edit" ? onStop : () => {})}
		>

			<div
				id={`item_${id}`}
				style={{
					width: isRobot
						? `${(mode === "menu" ? 45 : elementDimensions) * robotScale * propsIcons[variables.skin.value].aspectRatio}px`
						: (mode === "menu"
							? ((isHuman || isColor) ? "33px" : "45px")
							: `${(isColor ? (11 / 15) : (isPoi ? (7 / 15) : (isHuman ? humanScale * propsIcons[variables.skin.value].aspectRatio : 1))) * elementDimensions}px`),
					height: isRobot
						? `${(mode === "menu" ? 45 : elementDimensions) * robotScale}px`
						: (mode === "menu"
							? ((isHuman || isColor) ? "33px" : "45px")
							: `${(isColor ? (11 / 15) : (isPoi ? (7 / 15) : (isHuman ? humanScale : 1))) * elementDimensions}px`),
					borderRadius: `${(((isHuman && mode === "menu") || isColor) ? (11 / 15) : 1) * elementDimensions}px`,
					...(mode !== "menu" && {
						position: "absolute",
						left: `calc(${position.x}% - ${(elementDimensions * (isRobot ? robotScale * propsIcons[variables.skin.value].aspectRatio : (isPoi ? (7 / 15) : (isHuman ? humanScale * propsIcons[variables.skin.value].aspectRatio : 1)))) / 2}px)`,
						bottom: `calc(${position.y}% - ${(elementDimensions * (isRobot ? robotScale : (isPoi ? (7 / 15) : (isHuman ? humanScale : 1)))) / 2}px)`,
					}),
					border: isColor ? "2px solid #134eda" : "none",
					margin: ((isHuman && mode === "menu") || isColor) ? "6px" : "0px",
					padding: ((isHuman && mode === "menu") || isColor) ? "5px" : "0px",
					boxShadow: ((isHuman && mode === "menu") || isColor) ? "0px 0px 20px 5px rgba(0, 0, 0, 0.5)" : "none",
					background: (isHuman && mode === "menu")
						? "#134eda"
						: (isColor
							? `rgb(${variables.r.value}, ${variables.g.value}, ${variables.b.value})`
							: "transparent"
						),
					cursor: mode === "menu"
						? "move"
						: (mode === "view"
							? "help"
							: dragging ? "move" : "pointer"),
					opacity: (dragging && mode === "menu") ? 0 : 1,
					transform: "translate(-50%, -50%)",
				}}
			>
				<Tooltip
					open={infoTooltipOpen}
					handleOpen={() => {
						if (dragging || popupOpen) {
							return;
						}

						if (mode === "menu") {
							propsSetDescriptionTitle(tooltip.header);
							propsSetDescriptionBody(tooltip.body);
						} else {
							setInfoTooltipOpen(true);
							setMetersDisplay(true);
						}
					}}
					handleClose={() => {
						propsSetDescriptionTitle(null);
						propsSetDescriptionBody(null);
						setInfoTooltipOpen(false);
						setMetersDisplay(false);
					}}
					title={(
						mode === "menu"
							? (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										textAlign: "center",
										width: "150px",
										padding: "5px",
										borderRadius: "10px",
										color: "white",
									}}
								>
									<div style={{ fontWeight: "bold", fontSize: "16px" }}>
										{tooltip.header}
									</div>
									<div
										style={{
											height: "fit-content",
											wordBreak: "break-word",
										}}
									>
										{tooltip.body}
									</div>
								</div>
							)
							: (
								<>
									<div
										key="itemTooltip_name"
										style={{
											display: "flex",
											flexDirection: "column",
											textAlign: "center",
											width: "150px",
											borderRadius: "10px",
											color: "white",
										}}
									>
										<div style={{ fontWeight: "bold", fontSize: "16px" }}>
											{name}
										</div>
									</div>
									{Object.keys(variables)
										.filter((variable) => variables[variable].tooltipDisplay)
										.filter((variable) => {
											if (["switch", "boolean"].includes(variables[variable].type) && itemCat === "robot") {
												return variables[variable].value;
											}

											if (variables[variable].type === "extra") {
												return Object.keys(variables[variable].extra)
													.filter((xtraVariable) => variables[variable].extra[xtraVariable].tooltipDisplay
														&& variables[variable].extra[xtraVariable].value)
													.map((xtraVariable) => variables[variable].extra[xtraVariable].name).length > 0;
											}

											return true;
										})
										.map((variable) => (
											<div
												key={`itemTooltip_${variable}`}
												style={{
													display: "flex",
													flexDirection: "column",
													textAlign: "center",
													width: "150px",
													padding: "5px",
													borderRadius: "10px",
													color: "white",
												}}
											>
												<div style={{ fontWeight: "bold", fontSize: "16px" }}>
													{variables[variable].name}
												</div>
												<div
													style={{
														height: "fit-content",
														wordBreak: "break-word",
														fontSize: "14px",
													}}
												>
													{
														["switch", "boolean"].includes(variables[variable].type)
															? (variables[variable].value ? "On" : "Off")
															: (variables[variable].type === "extra"
																? Object.keys(variables[variable].extra)
																	.filter((xtraVariable) => variables[variable].extra[xtraVariable].tooltipDisplay
																		&& variables[variable].extra[xtraVariable].value)
																	.map((xtraVariable) => variables[variable].extra[xtraVariable].name)
																	.join(", ") || "Off"
																: (variables[variable].type === "tagInput"
																	? variables[variable].value.join(", ")
																	: variables[variable].value
																)
															)
													}
												</div>
											</div>
										))}
									{(isCameraDistance && variables?.host?.value) && (
										<div
											key="itemTooltip_panTilt"
											style={{
												display: "flex",
												flexDirection: "column",
												textAlign: "center",
												width: "150px",
												padding: "5px",
												borderRadius: "10px",
												color: "white",
											}}
										>
											<div style={{ fontWeight: "bold", fontSize: "16px" }}>
												{"Pan Tilt Name"}
											</div>
											<div
												style={{
													height: "fit-content",
													wordBreak: "break-word",
													fontSize: "14px",
												}}
											>
												{propsItems.effectors.panTilt[variables.host.value].itemName}
											</div>
										</div>
									)}
								</>
							)
					)}
					onMouseLeave={() => {
						propsSetDescriptionTitle(null);
						propsSetDescriptionBody(null);
						setInfoTooltipOpen(false);
					}}
				>
					<div style={{ ...(variables?.theta?.value !== undefined && { transform: `rotate(${-variables.theta.value}deg)` }) }}>
						<img
							id={`itemImg_${id}`}
							src={
								isHuman && mode !== "menu"
									? propsIcons[variables.skin.value].image
									: (isRobot && mode !== "menu"
										? propsIcons[variables.skin.value].image[variables.skinColor.value]
										: (isPoi && mode !== "menu"
											? propsIcons.default
											: icon
										))
							}
							alt=""
							draggable={false}
							style={{
								maxWidth: "100%",
								maxHeight: "100%",
								zIndex: dragging ? 1000 : 1,
								...(isSensor && mode === "view" && variables?.state?.value && variables?.state?.value === "off" ? { filter: "grayscale(1)" } : {}),
							}}
							onMouseOver={() => setMetersDisplay(true)}
							onMouseOut={() => setMetersDisplay(false)}
							onFocus={() => setMetersDisplay(true)}
							onBlur={() => setMetersDisplay(false)}
						/>
						{mode !== "menu" && metersDisplay && !dragging && (
							Object.keys(variables).map((variable) => (
								variables[variable].metersDisplay && (
									<div
										key={`meters_${variable}`}
										style={{
											width: `${((2 * variables[variable].value) / resolution)}px`,
											height: `${((2 * variables[variable].value) / resolution)}px`,
											borderRadius: `${((2 * variables[variable].value) / resolution)}px`,
											background: (isCameraDistance)
												? "transparent"
												: (cat === "general"
													? "rgba(19, 78, 218, 0.6)"
													: (cat === "effectors"
														? "rgba(250, 160, 0, 0.6)"
														: "rgba(255, 0, 0, 0.6)"
													)),
											backgroundImage: cameraRange,
											position: "absolute",
											top: `${-(variables[variable].value / resolution) + ((isColor ? (11 / 15) : (isHuman ? humanScale : 1)) * elementDimensions) / 2}px`,
											left: `${-(variables[variable].value / resolution) + ((isColor ? (11 / 15) : (isHuman ? humanScale * propsIcons[variables.skin.value].aspectRatio : 1)) * elementDimensions) / 2}px`,
											filter: "blur(5px)",
											zIndex: -1,
											animation: "areaAlarmAnimation 1.2s linear infinite",
										}}
									/>
								)
							))
						)}
						{mode === "view" && simulationRangeOpen && (
							<div
								key="srcDetection"
								style={{
									width: `${((2 * (variables?.range?.value ?? 5)) / resolution)}px`,
									height: `${((2 * (variables?.range?.value ?? 5)) / resolution)}px`,
									borderRadius: `${((2 * (variables?.range?.value ?? 5)) / resolution)}px`,
									background: (isCameraDistance)
										? "transparent"
										: (cat === "general"
											? "rgba(19, 78, 218, 0.6)"
											: (cat === "effectors"
												? "rgba(250, 160, 0, 0.6)"
												: "rgba(255, 0, 0, 0.6)"
											)),
									backgroundImage: cameraRange,
									position: "absolute",
									top: `${-((variables?.range?.value ?? 5) / resolution) + ((isColor ? (11 / 15) : (isRobot ? robotScale : (isHuman ? humanScale : 1))) * elementDimensions) / 2}px`,
									left: `${-((variables?.range?.value ?? 5) / resolution) + ((isColor ? (11 / 15) : (isRobot ? robotScale * propsIcons[variables.skin.value].aspectRatio : (isHuman ? humanScale * propsIcons[variables.skin.value].aspectRatio : 1))) * elementDimensions) / 2}px`,
									filter: "blur(5px)",
									zIndex: -1,
									animation: "areaAlarmAnimation 1.2s linear infinite",
								}}
							/>
						)}
						{mode === "view" && simulationMessageOpen && (
							<div
								key="targetDetection"
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									backgroundColor: "rgba(3, 19, 32, 0.8)",
									color: "white",
									padding: "10px",
									borderRadius: "5px",
									textAlign: "center",
								}}
							>
								{Object.keys(simulationMessageInfo).map((key, ind) => (
									<div key={`targetDetection_${key}`}>
										{ind !== 0 && <hr style={{ margin: "5px 0px" }} />}
										<div>
											{`${toTitleCase(key)}:`}
										</div>
										<div style={{ width: "max-content", maxWidth: "200px", display: "flex", marginLeft: "auto", marginRight: "auto" }}>
											{simulationMessageInfo[key]}
										</div>
									</div>
								))}
							</div>
						)}
					</div>
				</Tooltip>
				{mode === "edit" && (
					<div
						style={{
							display: (metersTooltipOpen) ? "block" : "none",
							width: "max-content",
							paddingLeft: "5px",
							paddingRight: "5px",
							height: "20px",
							position: "absolute",
							top: `-${(elementDimensions / 2) + 5}px`,
							left: `${(elementDimensions / 2) - (145 / 2)}px`,
							fontSize: "14px",
							textAlign: "center",
							background: "#7a8585",
							borderRadius: "10px",
							color: "white",
							zIndex: 100,
						}}
					>
						{`x: ${metersTooltipPosition.x}m, y: ${metersTooltipPosition.y}m`}
					</div>
				)}
			</div>
		</Draggable>,
		<Popup
			key={`popup_${id}`}
			width="500px"
			open={popupOpen}
			title={`${toTitleCase(itemCat)} ${id} Settings`}
			onClose={closePopup}
		>
			<Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				{isColor && (
					<SketchPicker
						disableAlpha
						presetColors={colors}
						color={rgbToHex(variables.r.value, variables.g.value, variables.b.value)}
						onChange={changeRGB}
					/>
				)}
				<Form key={JSON.stringify(tmpFormValues)} content={formContent} onSubmit={onSettingsSubmit} />
				<ErrorBorderButton
					title="Delete"
					width="300px"
					onClick={deleteItem}
				/>
			</Grid>
		</Popup>,
		<Popup
			key={`extra_popup_${id}`}
			width="500px"
			open={extraPopupOpen}
			title={`${toTitleCase(itemCat)} ${id} Extra Settings`}
			onClose={closeExtraPopup}
		>
			<Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<Form key={JSON.stringify(extraFormContent)} content={extraFormContent} onSubmit={onExtraSettingsSubmit} />
			</Grid>
		</Popup>,
	]);
});

export default Item;
