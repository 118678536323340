/* eslint-disable no-unsafe-optional-chaining */
// eslint-disable-next-line no-unused-vars
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";

import {
	Avatar,
	Grid,
	IconButton,
	Typography,
	Chip,
	TextField,
	Box,
	Tooltip,
} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Search from "../components/Search.js";

import addToMarketPlaceIcon from "../assets/dsl-icons/Add_to_Market.png";
import editIcon from "../assets/dsl-icons/Edit.png";
import removeFromMarketplaceIcon from "../assets/dsl-icons/Remove_marketplace.png";

import ModelCard from '../components/ModelCard.js';

import { makeStyles } from '@mui/styles';

import MDEditor from "@uiw/react-md-editor";

import { memo, useCallback, useEffect, useState } from "react";

import Popup from "../components/Popup.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import { useSnackbar, jwt, isFuzzyMatch } from "../utils/index.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import {
	getProject,
	getUsersModels,
	addCollaboratorToProject,
	removeCollaboratorFromProject,
	addModelToProject,
	removeModelFromProject,
	getCollaborators,
	updateProjectMetadata,
	cloneModel,
	newUserModel,
	addProjectToMarketPlace,
	removeProjectFromMarketplace,
	addModelInPublishedProject,
	checkModelAdditionInPublicProject,
} from '../api/index.js';

import AreYouSurePopup from '../components/Popups/AreYouSurePopup.js';

import { getDslsImages, getDslsNames, getDslsConstants } from '../dsls/index.js';

const useStyles = makeStyles((theme) => ({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "#F1A542", // Set the desired color for the select
		"&:before": {
			borderColor: "#F1A542", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "#F1A542", // Set the desired color for the select's border when focused
		},
	},
	selectSmall: {
		color: theme.palette.secondary.main, // Set the desired color for the select
		"&:before": {
			borderColor: theme.palette.secondary.main, // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: theme.palette.secondary.main, // Set the desired color for the select's border when focused
		},
		fontSize: "0.9rem",
	},
	dotSquare: {
		position: 'relative',
		width: 200, // Adjust as needed
		height: 40, // Adjust as needed
		borderRadius: 0,
		borderColor: null,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: 'none',
	},
	dot: {
		position: 'absolute',
		width: 16, // Adjust as needed
		height: 16, // Adjust as needed
		backgroundColor: 'white', // Dot color
		borderRadius: '50%',
	},
}));

const textualDslOptions = [
	{ text: "All DSLs", value: "All DSLs" },
	{ text: "SmAuto", value: "SmAuto" },
	{ text: "CODINTxt", value: "CODINTxt" },
	{ text: "OpenAPI", value: "OpenAPI" },
	{ text: "ToolBoxer", value: "ToolBoxer" },
	{ text: "Goal-dsl", value: "Goal-dsl" },
	{ text: "dflow", value: "dflow" },
	{ text: "DeMoL", value: "DeMoL" },
	{ text: "cps-ml", value: "cps-ml" },
	{ text: "Generos", value: "Generos" },
	{ text: "rosbridge-ml", value: "rosbridge-ml" },
	{ text: "XmasDSL", value: "XmasDSL" },
	{ text: "comm-idl", value: "comm-idl" },
];

const graphicalDslOptions = [
	{ text: "All DSLs", value: "All DSLs" },
	{ text: "AppCreator", value: "AppCreator" },
	{ text: "EnvMaker", value: "EnvMaker" },
	{ text: "EnvPop", value: "EnvPop" },
];

const ProjectScreen = (params) => {
	const { error, success } = useSnackbar();
	const { projectid } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [project, setProject] = useState(null);
	const [collaborators, setCollaborators] = useState([]);
	const [description, setDescription] = useState("");
	const [tmpDescription, setTmpDescription] = useState("");
	const [tmpTitle, setTmpTitle] = useState("");
	const [models, setModels] = useState([]);
	const [filteredModels, setFilteredModels] = useState([]);
	const [modelsDsls, setModelsDsls] = useState([]);
	const [usersModels, setUsersModels] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [user, setUser] = useState(jwt.decode());
	const [dslsImages, setDslsImages] = useState({});
	const [dslsNames, setDslsNames] = useState({});
	const [dslsConstants, setDslsConstants] = useState({});
	const [userCollaborators, setUserCollaborators] = useState([]);
	const [editMetadataPopupOpen, setEditMetadataPopupOpen] = useState(false);
	const [cloneModelPopupOpen, setCloneModelPopupOpen] = useState(false);
	const [createModelPopupOpen, setCreateModelPopupOpen] = useState(false);
	const [modelToClone, setModelToClone] = useState(null);
	const [addProjectToMarketplacePopupOpen, setAddProjectToMarketplacePopupOpen] = useState(false);
	const [removeProjectFromMarketplacePopupOpen, setRemoveProjectFromMarketplacePopupOpen] = useState(false);
	const [canAddProjectToMarketplace, setCanAddProjectToMarketplace] = useState(true);
	const [addModelInPublishedProjectPopupOpen, setAddModelInPublishedProjectPopupOpen] = useState(false);
	const [addCreatedModelInPublishedProjectPopupOpen, setAddCreatedModelInPublishedProjectPopupOpen] = useState(false);
	const [createdModel, setCreatedModel] = useState(null);
	const [searchFilter, setSearchFilter] = useState(null);
	const [dslTypes, setDslTypes] = useState([]);
	const [selectedType, setSelectedType] = useState(null);
	const [creatorsFullNames, setCreatorsFullNames] = useState([]);
	const [selectedCreator, setSelectedCreator] = useState(null);

	// const [addModelInPublishedProjectFormContent, setAddModelInPublishedProjectFormContent] = useState([]);
	const [modelToBeAdded, setModelToBeAdded] = useState(null);

	const [selectedDsl, setSelectedDsl] = useState("");
	const [selectedDslForNewModel, setSelectedDslForNewModel] = useState("");
	const [addToMarketplaceFormContent, setAddToMarketplaceFormContent] = useState([]);
	const [isInMarketplace, setIsInMarketplace] = useState(false);

	const classes = useStyles();
	const navigate = useNavigate();

	// eslint-disable-next-line no-unused-vars
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const addModelInPublishedProjectFormContent = [
		{
			customType: "checkbox",
			id: "newModelEditable",
			label: `Allow other users to edit the added model`,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Submit",
		},
	];

	const fetchData = useCallback(
		async () => {
			console.log(params);
			setIsLoading(true);

			// Fetch the project
			const { success: scs, project: prj } = await getProject(projectid);
			console.log("Project", prj);
			if (scs) {
				// Find names of owners for each model
				for (let i = 0; i < prj.models.length; i++) {
					const creator = prj.models[i].creator;
					if (prj.models[i]?.editable === false && !prj?.isOriginalCreator) {
						setCanAddProjectToMarketplace(false);
					}

					if (prj.models[i]?.public === true && prj.models[i]?.publishedInProject === prj?._id) {
						setIsInMarketplace(true);
					}

					for (const collab of prj.collaborators) {
						if (collab._id === creator) {
							prj.models[i].creator_fullname = collab.fullname;
							break;
						}
					}
				}

				setProject(prj);
				setDescription(prj.description);
				setTmpDescription(prj.description);
				setTmpTitle(prj.title);
				setCollaborators(prj.collaborators);
				setModels(prj.models);

				const uniqueDslTypes = [
					...new Set(prj.models.map((mdl) => mdl.model_type))].map((type) => ({ text: type, value: type }));

				const dslTypesArray = [{ text: "All DSLs", value: "All DSLs" }, ...uniqueDslTypes];

				setDslTypes(dslTypesArray);

				const uniqueCreators = [
					...new Set(prj.models.map((mdl) => mdl.creator_fullname))].map((name) => ({ text: name, value: name }));

				const creatorFullnameArray = [{ text: "All Creators", value: "All Creators" }, ...uniqueCreators];

				setCreatorsFullNames(creatorFullnameArray);

				// // Fetch the user's models
				// const { success: scs2, models: usModels } = await getUsersModels(user.id);
				// // console.log(usModels);
				// if (scs2) {
				// 	// Remove the models that are already in the project
				// 	const insertableModels = [];
				// 	for (const mdl of usModels) {
				// 		let found = false;
				// 		for (const mdl2 of prj.models) {
				// 			if (mdl._id === mdl2._id) {
				// 				found = true;
				// 				break;
				// 			}
				// 		}

				// 		if (!found) {
				// 			insertableModels.push(mdl);
				// 		}
				// 	}

				// 	// Find dsls from models
				// 	const dsls = new Set(insertableModels.map((mdl) => mdl.model_type));
				// 	setModelsDsls([...dsls].sort());

				// 	setUsersModels(insertableModels);
				// } else {
				// 	error();
				// }
			} else {
				error();
			}

			const _collaborators = await getCollaborators(user.id);
			// remove the project collaborators from the user collaborators
			_collaborators.collaborators = _collaborators.collaborators.filter(
				(colab) => !prj.collaborators.some((colab2) => colab2.email === colab.email),
			);
			console.log("Collaborators", _collaborators.collaborators);
			setUserCollaborators(_collaborators.collaborators);

			// Get dsls images
			const _dslsConstants = await getDslsConstants();
			setDslsConstants(_dslsConstants);
			console.log("DSLs names", _dslsConstants);
			const _dslsImages = await getDslsImages();
			setDslsImages(_dslsImages);
			const _dslsNames = await getDslsNames();
			setDslsNames(_dslsNames);

			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[error, projectid, user],
	);

	const removeCollaboratorHandler = async (userid) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await removeCollaboratorFromProject(
				projectid,
				userid,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const addCollaboratorHandler = async (values) => {
		setIsLoading(true);
		console.log("Add colab:", values);
		try {
			const { success: successCode, message } = await addCollaboratorToProject(
				projectid,
				values.target.value,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const addModelHandle = async (event, values) => {
		setIsLoading(true);
		try {
			if (isInMarketplace) {
				await addModelInPublishedProject(projectid, modelToBeAdded, values.newModelEditable);
			}

			const { success: successCode, message } = await addModelToProject(
				projectid,
				isInMarketplace ? modelToBeAdded : event.target.value,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		setAddModelInPublishedProjectPopupOpen(false);
		await fetchData();
		setIsLoading(false);
	};

	const removeModelHandle = async (_modelId) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await removeModelFromProject(
				projectid,
				_modelId,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const editMetadataHandle = async () => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await updateProjectMetadata(
				projectid,
				tmpTitle,
				tmpDescription,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setEditMetadataPopupOpen(false);
		setIsLoading(false);
	};

	const cloneModelSubmitHandler = async (values) => {
		setIsLoading(true);
		console.log(values);
		try {
			const { success: successCode, message } = await cloneModel(
				modelToClone._id,
				values.title,
				values.description,
			);

			if (successCode) {
				success("Model was cloned in your account");
			} else {
				error(message);
			}

			setModelToClone(null);
			setCloneModelPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const createModelSubmitHandler = async (values) => {
		setIsLoading(true);
		console.log(values);
		try {
			const { success: successCode, message, newModel } = await newUserModel(
				user.id,
				selectedDslForNewModel,
				values.title,
				values.description,
				values.tags,
				projectid,
			);

			if (successCode) {
				if (isInMarketplace) {
					setCreatedModel(newModel);
					setAddCreatedModelInPublishedProjectPopupOpen(true);
				}

				success("Model was created successfully");
			} else {
				error(message);
			}

			setCreateModelPopupOpen(false);

			// Enter the new model
			navigate(`/dsls/${selectedDslForNewModel}/${newModel._id}`);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const handleAddCreatedModelToMarketplace = async (values) => {
		setIsLoading(true);
		if (!values?.newModelEditable) {
			values.newModelEditable = false;
		}

		const { success: scs } = await addModelInPublishedProject(project._id, createdModel._id, values?.newModelEditable);
		if (!scs) {
			error("Failed to add model in marketplace");
		}

		setAddCreatedModelInPublishedProjectPopupOpen(false);
		setIsLoading(false);
	};

	const cloneModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "New title",
			value: modelToClone?.title,
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "New description",
			value: modelToClone?.description,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Clone model",
		},
	];

	const createModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Title",
			value: modelToClone?.title,
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "Description",
			value: modelToClone?.description,
		},
		{
			customType: "wideInput",
			id: "tags",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Tags",
			helperText: "You can add multiple tags separated by comma",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Create model",
		},
	];

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	useEffect(() => {
		let filtered = [...models];

		if (searchFilter) {
			filtered = filtered.filter((flt) => isFuzzyMatch(flt?.title, searchFilter)
				|| isFuzzyMatch(flt?.description, searchFilter)
				|| flt?.tags.some((tag) => isFuzzyMatch(tag, searchFilter)));
		}

		if (selectedType && selectedType !== "All DSLs") {
			filtered = filtered.filter((model) => model.model_type === selectedType.toLowerCase().replaceAll('-', ""));
		}

		if (selectedCreator && selectedCreator !== "All Creators") {
			filtered = filtered.filter((model) => model.creator_fullname === selectedCreator);
		}

		setFilteredModels(filtered);
	}, [searchFilter, models, selectedType, selectedCreator]);

	useEffect(() => {
		const formContent = [];
		if (project) {
			for (const model of project?.models) {
				formContent.push(
					{
						customType: "checkbox",
						id: model?._id,
						label: `Allow other users to edit the model: "${model?.title}"`,
					},
				);
			}

			formContent.push(
				{
					customType: "button",
					id: "submit",
					type: "submit",
					text: "Submit",
				},
			);

			setAddToMarketplaceFormContent(formContent);
		}
	}, [project]);

	const handleAddProjectToMarketplace = async (values) => {
		setIsLoading(true);
		// eslint-disable-next-line no-negated-condition, unicorn/no-negated-condition
		const result = Object.entries(values).map(([key, value]) => [key, value !== undefined ? value : false]);
		const { success: scs, message } = await addProjectToMarketPlace(project._id, result);
		if (scs) {
			success(message);
			setIsInMarketplace(true);
		} else {
			error(message);
		}

		setAddProjectToMarketplacePopupOpen(false);
		setIsLoading(false);
	};

	const handleRemoveProjectFromMarketplace = async () => {
		setIsLoading(true);
		const { success: scs, message } = await removeProjectFromMarketplace(project._id);
		if (scs) {
			success(message);
			setIsInMarketplace(false);
		} else {
			error(message);
		}

		await fetchData();
		setRemoveProjectFromMarketplacePopupOpen(false);
		setIsLoading(false);
	};

	const handleAditionWhileInMarketplace = async (event) => {
		setIsLoading(true);
		const { success: scs } = await checkModelAdditionInPublicProject(event.target.value);
		if (scs) {
			setModelToBeAdded(event.target.value);
			setAddModelInPublishedProjectPopupOpen(true);
		} else {
			error("Cannot add non editable model in public project");
		}

		setIsLoading(false);
	};

	return (
		<>
			<Spinner open={isLoading} />
			<AreYouSurePopup
				open={removeProjectFromMarketplacePopupOpen}
				title="Remove Project from Marketplace?"
				content="Are you sure you want to remove this project from the Marketplace?"
				onDecline={() => setRemoveProjectFromMarketplacePopupOpen(false)}
				onAccept={handleRemoveProjectFromMarketplace}
			/>
			<Popup
				width="800px"
				open={editMetadataPopupOpen}
				title="Edit project information"
				onClose={() => {
					setEditMetadataPopupOpen(false);
				}}
			>
				{/* Add text input to submit name */}
				<TextField
					label="title"
					variant="filled"
					sx={{
						width: "100%",
						mb: 2,
					}}
					InputProps={{
						style: { color: 'black' }, // Style for the input text
					}}
					value={tmpTitle}
					onChange={(e) => {
						setTmpTitle(e.target.value);
					}}
				/>
				<MDEditor
					hideToolbar
					value={tmpDescription}
					height={500}
					maxHeight={1200}
					fullscreen={false}
					tabSize={2}
					style={{
						zIndex: 100,
						borderRadius: 10,
					}}
					onChange={setTmpDescription}
				/>
				{/* Add button to submit broker */}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						mt: 2,
					}}
				>
					<Chip
						key={-1}
						label="Submit"
						color="secondary"
						className={[classes.clickableChip].join(" ")}
						sx={{
							mr: 0.5,
						}}
						onClick={editMetadataHandle}
					/>
				</Box>
			</Popup>
			<Popup
				width="800px"
				open={cloneModelPopupOpen}
				title="Clone the model into a new one"
				onClose={() => {
					setCloneModelPopupOpen(false);
				}}
			>
				<Form
					content={cloneModelFormContent}
					onSubmit={cloneModelSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={createModelPopupOpen}
				title="Create a new model"
				onClose={() => {
					setCreateModelPopupOpen(false);
				}}
			>
				<Grid
					item
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="flex-start"
					mb={1}
				>
					<Typography color="black" fontSize="1rem" mr={2}>
						{"Select DSL"}
					</Typography>
					<FormControl variant="standard" sx={{ minWidth: 120, mr: 2 }}>
						<Select
							value={selectedDslForNewModel || ""}
							label="Model"
							size="small"
							sx={{
								fontSize: "0.9rem",
								color: "primary.main",
							}}
							onChange={(e) => setSelectedDslForNewModel(e.target.value)}
						>
							{
								// dslsConstants is an object
								Object.keys(dslsConstants)
									.filter((mdl) => dslsConstants[mdl].enabled)
									.map((mdl, mdlInd) => [
										<MenuItem key={`subheader-${mdlInd}`} value={dslsConstants[mdl].short}>
											{dslsConstants[mdl].name}
										</MenuItem>,
									])
							}
						</Select>
					</FormControl>

				</Grid>
				<Form
					content={createModelFormContent}
					onSubmit={createModelSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={addProjectToMarketplacePopupOpen}
				title="Add project to Marketplace"
				onClose={() => {
					setAddProjectToMarketplacePopupOpen(false);
				}}
			>
				<Form
					content={addToMarketplaceFormContent}
					onSubmit={handleAddProjectToMarketplace}
				/>
			</Popup>

			<Popup
				width="800px"
				open={addModelInPublishedProjectPopupOpen}
				title="Select the public status of the model"
				onClose={() => {
					setAddModelInPublishedProjectPopupOpen(false);
				}}
			>
				<Form
					content={addModelInPublishedProjectFormContent}
					onSubmit={(values) => addModelHandle(event, values)}
				/>
			</Popup>
			<Popup
				width="800px"
				open={addCreatedModelInPublishedProjectPopupOpen}
				title="Select the public status of the model"
			// onClose={() => {
			// 	setAddModelInPublishedProjectPopupOpen(false);
			// }}
			>
				<Form
					content={addModelInPublishedProjectFormContent}
					onSubmit={handleAddCreatedModelToMarketplace}
				/>
			</Popup>
			<Grid
				container
				item
				mt={2}
				mb={2}
				width="100%"
				display="flex"
				flexDirection="column"
				justifyContent="space-evenly"
			>

				<Grid
					item
					container
					xs={12}
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
					sx={{
						borderBottom: "1px solid",
						borderColor: "secondary.main",
						pb: "1rem",
					}}
				>
					{/* Left Side: Title and Buttons */}
					<Grid item container alignItems="center" xs={6}>
						{/* Add to marketplace button */}
						{((canAddProjectToMarketplace && (!isInMarketplace && !project?.published))) && (
							<Avatar
								sx={{
									bgcolor: "#262835",
									ml: 2,
									mr: 2,
									width: 38,
									height: 38,
								}}
								className={classes.buttonCursor}
							>
								<Tooltip title="Add to Marketplace">
									<IconButton
										color="primary"
										onClick={() => setAddProjectToMarketplacePopupOpen(true)}
									>
										<img
											src={addToMarketPlaceIcon}
											alt="Add to Marketplace"
											style={{ width: 24, height: 24 }}
										/>
									</IconButton>
								</Tooltip>
							</Avatar>
						)}
						{/* Remove from marketplace button */}
						{(isInMarketplace || project?.published) && (
							<Avatar
								sx={{
									bgcolor: "#262835",
									ml: 2,
									mr: 2,
									width: 38,
									height: 38,
								}}
								className={classes.buttonCursor}
							>
								<Tooltip title="Remove from Marketplace">
									<IconButton
										color="primary"
										onClick={() => setRemoveProjectFromMarketplacePopupOpen(true)}
									>
										<img
											src={removeFromMarketplaceIcon}
											alt="Remove from Marketplace"
											style={{ width: 24, height: 24 }}
										/>
									</IconButton>
								</Tooltip>
							</Avatar>
						)}
						{/* Edit button */}
						<Avatar
							sx={{
								bgcolor: "#262835",
								mr: 2,
								width: 38,
								height: 38,
							}}
							className={classes.buttonCursor}
						>
							<Tooltip title="Edit Model Metadata">
								<IconButton
									color="secondary"
									onClick={() => setEditMetadataPopupOpen(true)}
								>
									<img
										src={editIcon}
										alt="Edit Model"
										style={{ width: 24, height: 24 }}
									/>
								</IconButton>
							</Tooltip>
						</Avatar>
						<Typography variant="h6" color="#ffffff">
							{project?.title}
						</Typography>
					</Grid>

					{/* Right Side: Dropdowns */}
					<Grid
						item
						container
						alignItems="center"
						justifyContent="flex-end"
						xs={6}
					>
						{/* Add collaborator dropdown */}
						<Grid item display="flex" flexDirection="row" alignItems="center">
							<FormControl variant="standard" sx={{ minWidth: 120, mr: 2 }}>
								<Select
									displayEmpty
									renderValue={(selected) => (selected || "Add Collaborator")}
									className={classes.selectSmall}
									value=""
									label="Collaborator"
									size="small"
									sx={{
										"& .MuiSelect-icon": {
											color: "#DDE8EF !important",
										},
									}}
									onChange={addCollaboratorHandler}
								>
									{userCollaborators.map((mdl, mdlInd) => (
										<MenuItem key={mdlInd} value={mdl.email}>
											{`${mdl.fullname}`}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						{/* Select DSL dropdown */}
						{/* <Grid item display="flex" flexDirection="row" alignItems="center">
							<FormControl variant="standard" sx={{ minWidth: 120, mr: 2 }}>
								<Select
									displayEmpty
									renderValue={(selected) => (selected || "Select DSL")}
									className={classes.selectSmall}
									value={selectedDsl || ""}
									label="Model"
									size="small"
									sx={{
										"& .MuiSelect-icon": {
											color: "#DDE8EF !important",
										},
									}}
									onChange={(e) => setSelectedDsl(e.target.value)}
								>
									{modelsDsls.map((mdl, mdlInd) => (
										<MenuItem key={`subheader-${mdlInd}`} value={mdl}>
											{dslsNames[mdl]}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid> */}

						{/* Add model dropdown */}
						{/* <Grid item display="flex" flexDirection="row" alignItems="center">
							<FormControl variant="standard" sx={{ minWidth: 80, mr: 2 }}>
								<Select
									displayEmpty
									renderValue={(selected) => (selected || "Add model")}
									className={classes.selectSmall}
									value=""
									label="Model"
									size="small"
									sx={{
										"& .MuiSelect-icon": {
											color: "#DDE8EF !important",
										},
									}}
									onChange={isInMarketplace ? handleAditionWhileInMarketplace : addModelHandle}
								>
									{usersModels
										.filter((mdl) => mdl.model_type === selectedDsl)
										.map((mdl, mdlInd) => (
											<MenuItem key={`item-${mdlInd}-${mdlInd}`} value={mdl._id}>
												{`${mdl.title}`}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid> */}
					</Grid>
				</Grid>
				<Grid container display="flex" flexDirection="row" xs={12} width="100%" sx={{ mt: "1rem" }}>
					{/* MDEditor Column */}
					<Grid
						item
						xs={4}
						sx={{
							borderTop: 0,
							borderRadius: "20px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<MDEditor
							hideToolbar
							value={description}
							height={screenSize.height - 200}
							maxHeight={1200}
							fullscreen={false}
							tabSize={2}
							preview="preview"
							style={{
								zIndex: 100,
								borderRadius: 10,
							}}
						/>
					</Grid>

					<Grid
						item
						xs={7.5}
						display="flex"
						flexDirection="column"
						sx={{ ml: "1rem" }}
					>
						{/* Collaborators Section */}
						<Grid container item width="100%" display="flex" minHeight="50px" maxHeight="50px" alignItems="center">
							<Grid item xs={12}>
								<Typography variant="h6" color="white !important" fontWeight="bold" sx={{ ml: "1rem" }}>
									{"Project collaborators"}
								</Typography>
							</Grid>
						</Grid>
						<Grid container item width="100%" pl={2} mb={1} display="flex" minHeight="50px" maxHeight="50px" borderRadius="20px" alignItems="center">
							{collaborators.length === 0 ? (
								<Typography color="gray" fontSize="0.8rem">
									{"No collaborators found."}
								</Typography>
							) : (
								collaborators.map((e, ind) => (
									<Chip
										key={ind}
										label={e.fullname}
										sx={{
											mr: 0.5,
											backgroundColor: "transparent",
											color: "secondary.main",
											border: "1px solid #DDE8EF",
											"& .MuiChip-deleteIcon": {
												color: "secondary.main",
												transition: "color 0.3s ease",
											},
											"& .MuiChip-deleteIcon:hover": {
												color: "#AABCC5",
											},
										}}
										onDelete={() => removeCollaboratorHandler(e._id)}
									/>
								))
							)}
						</Grid>

						{/* Models Section */}
						<Grid container item width="100%" pl={2} mt={2} display="flex" minHeight="50px" maxHeight="50px" alignItems="center" justifyContent="space-between">
							<Grid item display="flex" flexDirection="row" alignItems="center">
								<Typography variant="h6" color="white !important" fontWeight="bold">
									{"Project's models"}
								</Typography>
								<Tooltip title="Create new model">
									<IconButton
										sx={{ ml: 1 }}
										onClick={() => setCreateModelPopupOpen(true)}
									>
										<AddCircleIcon fontSize="large" sx={{ color: "#BB86FC !important" }} />
									</IconButton>
								</Tooltip>
							</Grid>

							{/* Right Section: Search */}
							<Grid item sx={{ width: "400px", display: "flex", justifyContent: "flex-end" }}>
								<FormControl variant="standard" sx={{ minWidth: 120, mr: 2 }}>
									<Select
										displayEmpty
										renderValue={(selected) => (selected || "All creators")}
										className={classes.selectSmall}
										value={selectedCreator}
										label="Model creator"
										size="small"
										sx={{
											"& .MuiSelect-icon": {
												color: "#DDE8EF !important",
											},
										}}
										onChange={(event) => setSelectedCreator(event.target.value)}
									>
										{creatorsFullNames.map((mdl, mdlInd) => (
											<MenuItem key={mdlInd} value={mdl.text}>
												{`${mdl.text}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl variant="standard" sx={{ minWidth: 120, mr: 2 }}>
									<Select
										displayEmpty
										renderValue={(selected) => (selected || "All DSLs")}
										className={classes.selectSmall}
										value={selectedType}
										label="DSL type"
										size="small"
										sx={{
											"& .MuiSelect-icon": {
												color: "#DDE8EF !important",
											},
										}}
										onChange={(event) => setSelectedType(event.target.value)}
									>
										{dslTypes.map((mdl, mdlInd) => (
											<MenuItem key={mdlInd} value={mdl.text}>
												{`${mdl.text}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<Search
									value={searchFilter}
									onChange={(event) => setSearchFilter(event.target.value)}
								/>
							</Grid>
						</Grid>
						{filteredModels.length === 0 ? (
							<Typography color="gray" pl={2} pb={1} pt={1} fontSize="0.8rem">
								{"No models found."}
							</Typography>
						) : (
							<Grid
								container
								display="flex"
								direction="column"
								alignItems="center"
								justifyContent="center"
							>
								<Grid container spacing={2} justifyContent="start" flexDirection="row" width="100%">

									{filteredModels.map((e, ind) => (
										<Grid
											key={ind}
											item
										>
											<Box sx={{ width: 330 }}>
												<ModelCard
													fromProjectScreen
													model={e}
													setIsLoading={setIsLoading}
													success={success}
													error={error}
													fetchData={fetchData}
													textualDsls={textualDslOptions.map((dsl) => dsl.value.toLowerCase())}
													graphicalDsls={graphicalDslOptions.map((dsl) => dsl.value.toLowerCase())}
													// eslint-disable-next-line unicorn/prefer-string-replace-all
													dslImage={dslsImages[e?.model_type.toLowerCase().replace(/-/g, "")]}
													removeFromOtherScreen={removeModelHandle}
													cloneFromOtherScreen={() => {
														setModelToClone(e);
														setCloneModelPopupOpen(true);
													}}
													viewFromOtherScreen={() => navigate(`/dsls/${e.model_type}/${e._id}`)}
												/>
											</Box>
										</Grid>
									))}
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(ProjectScreen);
