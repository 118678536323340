/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Card, Grid, Box, Chip, Divider, Typography } from "@mui/material";
import modelsIcon from "../assets/icons/Models.png";
import validationsIcon from "../assets/icons/Validations.png";
import usersIcon from "../assets/icons/users.png";

const DslCard = ({ dsl }) => (
    <Card
        sx={{
            height: 300,
            width: 400,
            margin: 2,
            boxShadow: 6,
            borderRadius: 3,
            overflow: "hidden",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#323D4D",
            paddingBottom: "50px",
            "&:hover": {
                boxShadow: 12,
                transform: "scale(1.02)",
                transition: "all 0.3s ease",
            },
        }}
    >
        {!dsl.enabled && (
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 10,
                }}
            >
                <Typography
                    sx={{
                        color: "#FFFFFF",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        borderRadius: "8px",
                    }}
                >
                    {"Not Enabled"}
                </Typography>
            </Box>
        )}

        <Grid item>
            <img
                src={dsl?.logoUrl}
                alt={dsl?.logoUrl}
                style={{
                    height: 60,
                    margin: "auto",
                    marginTop: 10,
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                }}
            />
            <Typography
                variant="body2"
                sx={{
                    fontSize: "0.85rem",
                    color: "#DDE8EF",
                    textAlign: "justify",
                    textJustify: "inter-word",
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    margin: 2,
                }}
            >
                {dsl?.description || "No Description"}
            </Typography>
        </Grid>

        <Box sx={{ position: "relative", width: "100%" }}>
            <Divider
                sx={{
                    backgroundColor: "#DDE8EF",
                    position: "absolute",
                    bottom: "15px",
                    left: "5%",
                    width: "90%",
                }}
            />
        </Box>

        {/* Tags Box */}
        <Box
            sx={{
                position: "absolute",
                bottom: "10px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                gap: 0.3,
                paddingX: 1,
                width: "80%",
            }}
        >
            {dsl?.tags?.map((tag, index) => (
                <Chip key={index} label={tag} sx={{ backgroundColor: "primary.main", color: "#DDE8EF" }} />
            ))}
        </Box>

        <Box
            sx={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                display: "flex",
                gap: 2,
                alignItems: "center",
            }}
        >
            {[
                { count: dsl?.usersCount, icon: usersIcon, bgColor: "#BB86FC" },
                { count: dsl?.validationsCount, icon: validationsIcon, bgColor: "#BB86FC" },
                { count: dsl?.modelsCount, icon: modelsIcon, bgColor: "#BB86FC" },
            ].map((item, index) => (
                item.count > 0 && (
                    <Box key={index} sx={{ position: "relative", display: "flex", alignItems: "center" }}>
                        <Typography
                            label={item.count}
                            sx={{
                                position: "absolute",
                                minWidth: "2rem",
                                height: "1rem",
                                top: "-10px",
                                left: "-15px",
                                fontSize: "0.5rem",
                                color: "primary.main",
                                backgroundColor: item.bgColor,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                lineHeight: "1.1rem",
                                whiteSpace: "nowrap",
                                fontWeight: "bold",
                                borderRadius: 5,
                            }}
                        >
                            {item.count}
                        </Typography>
                        <img src={item.icon} alt="icon" style={{ width: 35, height: 35 }} />
                    </Box>
                )
            ))}
        </Box>
    </Card>
);

export default DslCard;
