/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import { Grid } from "@mui/material";
import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateNodeParameters } from "./slice-nodes.js";
import SettingsIcon from '@mui/icons-material/Settings';
import Popup from "../Popup.js";
import MarkdownPreview from "@uiw/react-markdown-preview";
import MDEditor from "@uiw/react-md-editor";

const CommentNode = memo(({ data }) => {
    const dispatch = useDispatch();
    const [text, setText] = useState(data?.parameters[0]?.value || "");
    const [popupOpen, setPopupOpen] = useState(false);

    return (
        <Grid
            style={{
                background: "transparent !important",
                border: "2px solid #03DAC5",
                borderRadius: "10px",
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "230px",
            }}
        >
            <Popup
                width="800px"
                open={popupOpen}
                title={data.label}
                height="1000px"
                onClose={() => {
                    setPopupOpen(false);

                    const updatedParameters = data.parameters.map((param) => (param.id === "comment" ? { ...param, value: text } : param));

                    dispatch(updateNodeParameters({ id: data.id, parameters: updatedParameters }));
                }}
            >
                <Grid
                    container
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{
                        width: '100%',
                        maxWidth: '100%',
                    }}
                >
                    <MDEditor
                        hideToolbar
                        fullscreen={false}
                        value={text}
                        height={400}
                        maxHeight={1200}
                        tabSize={2}
                        style={{
                            width: '100%',
                            zIndex: 100,
                            borderRadius: 10,
                        }}
                        onChange={setText}
                    />
                </Grid>
            </Popup>

            {data.parameters && data.parameters.length > 0 && (
                <Grid
                    item
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    sx={{
                        position: "absolute",
                        right: "-23.5px",
                        top: "7px",
                        padding: "4px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        borderWidth: "1px",
                        border: "1px solid #666",
                        borderLeftWidth: "0px",
                        borderRadius: "10px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        display: "flex",
                    }}
                >
                    <SettingsIcon
                        sx={{
                            cursor: "pointer",
                            color: data.fontColor,
                            fontSize: "1em",
                        }}
                        onClick={() => {
                            setPopupOpen(true);
                        }}
                    />
                </Grid>
            )}
            <MarkdownPreview
                source={text || "Add comment..."}
                style={{
                    background: "transparent",
                    color: "inherit",
                    fontSize: "1.2rem",
                    width: "100%",
                }}
            />

        </Grid>
    );
});

export default CommentNode;
