import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Thread split node

The Thread split and Thread join nodes offer parallelism in your applications. As we said, AppCreator offers a synchronous, FSM-like flow from node to node. If you want process to be executed in parallel you must use a Thread split node.

A typical Thread split node with 3 outputs is evident below. When this is used, three threads will initiate and be executed in parallel, deploying the subflows connected to the three outputs.

![alt text](${transformAsset("image-10.png")})
`;

export default documentation;
