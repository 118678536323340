import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# List operations node

This node offers specific list-oriented mathematical operations, applied in a created list. Since the outcome of this node is a value (or values), the user is prompted to store the result in a custom variable. A typical example of this node is the following:

![alt text](${transformAsset("list-operations.png")})

Here, the average of the elements of list \`list_2\` is computed and stored in variable \`x\`. The result is the following:

![alt text](${transformAsset("average-list.png")})
`;

export default documentation;
