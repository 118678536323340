/* eslint indent: ["off"] */
import { createSlice } from "@reduxjs/toolkit";

export const variableSlice = createSlice({
    name: 'variables',
    initialState: [],
    reducers: {
        addVariable: (state, variable) => {
            state.push({
                variableId: variable.payload.id,
                variableName: variable.payload.parameters[0]?.value,
                variableValue: variable.payload.parameters[1]?.value,
            });
        },
        updateVariable: (state, action) => {
            const index = state.findIndex((variable) => variable.variableId === action.payload.id);

            if (index !== -1) {
                state[index] = {
                    variableId: action.payload.id,
                    variableName: action.payload.data.parameters[0].value,
                    variableValue: action.payload.data.parameters[1].value,
                };
            }
        },
        deleteVariable: (state, id) => {
            state = state.filter((variable) => variable.variableId !== id.payload.id);
            return state;
        },
        updateAllVariables: (state, action) => {
            const { id, variables } = action.payload;
            const filteredState = state.filter((variable) => variable.variableId !== id);
            const newVariables = variables.map((variable) => ({
                variableId: id,
                variableName: variable?.name,
                variableValue: variable?.value,
            }));

            return [...filteredState, ...newVariables];
        },
        setVariableParameters: (state, data) => {
            const varArr = [];

            for (const variable of data.payload) {
                varArr.push({
                    variableId: variable.nodeId,
                    variableName: variable.variable,
                    variableValue: variable.value,
                });
            }

            state = varArr;
            return state;
        },
    },
});

export const {
    addVariable,
    updateVariable,
    deleteVariable,
    setVariableParameters,
    updateAllVariables,
} = variableSlice.actions;

export default variableSlice.reducer;
