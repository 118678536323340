import { useState, useEffect, useCallback, memo } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Toolbar, Typography, Menu, MenuItem, IconButton, Button, Paper, Breadcrumbs, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
	ExpandMore,
	MoreVert as MoreIcon,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Image } from "mui-image";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import StoreIcon from '@mui/icons-material/Store';
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";

import { jwt, capitalize } from "../utils/index.js";
import useGlobalState from "../use-global-state.js";
import logo from "../assets/images/Locsys_logo.png";
import logoutIcon from "../assets/menu-icons/Logout.png";
import teamsIcon from "../assets/menu-icons/Collaborator.png";
import assignmentsIcon from "../assets/menu-icons/DSLs.png";
import publicationsIcon from "../assets/menu-icons/Projects.png";
import secretsIcon from "../assets/menu-icons/Secrets.png";
import marketplaceIcon from "../assets/menu-icons/Marketplace.png";
import adminIcon from "../assets/menu-icons/Admin.png";
import { getDslsConstants } from "../dsls/index.js";
import { getProjects, isUserAdmin } from "../api/index.js";

const useStyles = makeStyles((theme) => ({
	outer: {
		backgroundColor: theme.palette.primary.main,
	},
	grow: {
		flexGrow: 1,
		flexBasis: "auto",
		background: "linear-gradient(to bottom, white 0%, #DDE8EF 100%)",
		zIndex: 1200,
	},
	root: {
		height: "30px",
		padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(2),
		borderRadius: "0px",
		background: "#ABC7D9",
	},
	icon: {
		marginRight: 0.5,
		width: 20,
		height: 20,
	},
	expanded: {
		background: "transparent",
	},
	innerSmallAvatar: {
		color: theme.palette.common.black,
		fontSize: "inherit",
	},
	anchorOriginBottomRightCircular: {
		".MuiBadge-anchorOriginBottomRightCircular": {
			right: 0,
			bottom: 0,
		},
	},
	avatar: {
		width: "30px",
		height: "30px",
		background: "white",
	},
	iconButton: {
		padding: "3px 6px",
	},
	menuItemButton: {
		width: "100%",
		bgcolor: "grey.light",
		"&:hover": {
			bgcolor: "grey.dark",
		},
	},
	grey: {
		color: "grey.500",
	},
}));

const ButtonWithText = ({ text, icon, more, handler, closeAll }) => (
	<Button
		sx={{
			height: "100%",
			width: "120px",
			display: "flex",
			flexDirection: "column",
			p: 1,
			mx: -0.8,
			'&:hover': {
				bgcolor: 'white !important',
			},
		}}
		onClick={(event) => {
			closeAll();
			if (handler) {
				handler(event);
			}
		}}
	>
		<Image
			src={icon}
			alt={text}
			fit="contain"
			sx={{
				p: 0,
				my: 0,
				height: "100%",
				maxWidth: "200px",
				'&:hover': {
					bgcolor: 'transparent !important',
				},
			}}
		/>
		<Typography
			align="center"
			color="primary.main"
			fontSize="small"
			fontWeight="bold"
			display="flex"
			alignItems="center"
			sx={{
				textTransform: "capitalize",
				'&:hover': {
					bgcolor: 'transparent !important',
				},
			}}
		>
			{text}
			{more && <ExpandMore />}
		</Typography>
	</Button>
);

const Header = ({ isAuthenticated }) => {
	const classes = useStyles();
	const { logout } = useAuth0();

	const location = useLocation();
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

	const { maxDSLScreen } = useGlobalState((e) => ({
		maxDSLScreen: e.maxDSLScreen,
	}), shallow);

	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);
	const handleMobileMenuOpen = (event) => setMobileMoreAnchorEl(event.currentTarget);
	const closeAll = () => {
		handleMobileMenuClose();
	};

	const [projectsAnchorEl, setProjectsAnchorEl] = useState(null);
	const [dslsAnchorEl, setDslsAnchorEl] = useState(null);
	const [adminAnchorEl, setAdminAnchorEl] = useState(null);
	const [dslsConstants, setDslsConstants] = useState([]);
	const [userProjects, setUserProjects] = useState([]);
	const [isAdmin, setIsAdmin] = useState(false);
	const openProjectsMenu = Boolean(projectsAnchorEl);
	const openDSLsMenu = Boolean(dslsAnchorEl);
	const openAdminMenu = Boolean(adminAnchorEl);
	const fetchData = useCallback(async () => {
		if (!jwt.isAuthenticated()) return;
		const constants = await getDslsConstants();
		setDslsConstants(constants);

		const { success: scs } = await isUserAdmin();
		if (scs) {
			setIsAdmin(true);
		}

		const prjs = await getProjects();
		const sortedProjects = prjs.sort((a, b) => {
			if (a.pinned && !b.pinned) return -1;
			if (!a.pinned && b.pinned) return 1;

			return new Date(b.createdAt) - new Date(a.createdAt);
		});

		setUserProjects(sortedProjects);
		console.log("Projects", sortedProjects);
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleProjectsMenuClick = (event) => setProjectsAnchorEl(event.currentTarget);
	const handleDSLsMenuClick = (event) => setDslsAnchorEl(event.currentTarget);
	const handleAdminMenuClick = (event) => setAdminAnchorEl(event.currentTarget);
	const handleProjectsMenuClose = () => setProjectsAnchorEl(null);
	const handleDSLsMenuClose = () => setDslsAnchorEl(null);
	const handleAdminMenuClose = () => setAdminAnchorEl(null);

	const CrumpLink = styled(Link)(({ txtColor }) => ({ display: "flex", color: txtColor || "black !important" }));

	const buttons = [
		{
			icon: marketplaceIcon,
			text: "Marketplace",
			link: "/marketplace",
		},
		{
			icon: teamsIcon,
			text: "Collaboration",
			link: "/collaboration",
		},
		{
			icon: publicationsIcon,
			text: "Projects",
			link: "/projects",
		},
		{
			icon: assignmentsIcon,
			text: "DSLs",
			link: "/dsls",
		},
		{
			icon: secretsIcon,
			text: "Secrets",
			link: "/secrets",
		},
		{
			icon: logoutIcon,
			text: "Logout",
			handler: (event) => {
				event.preventDefault();
				closeAll();
				jwt.destroyToken();
				logout({ logoutParams: { returnTo: window.location.origin }, clientId: process.env.REACT_APP_AUTH0_CLIENT_ID });
			},
		},
	];

	const adminActions = [
		{
			text: "Change modelText for cloned models",
			link: "/model-text",
		},
		{
			text: "Change description for cloned projects",
			link: "/project-description",
		},
	];

	const renderMobileMenu = (
		<Menu
			keepMounted
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			{buttons.map((button) => (
				<MenuItem key={button.text} onClick={button.handler}>
					<Image src={button.icon} width="20px" />
					<p style={{ marginLeft: "5px" }}>{button.text}</p>
					{button.more && <ExpandMore />}
				</MenuItem>
			))}
		</Menu>
	);

	const pathnames = location.pathname.split("/").filter(Boolean);
	const crumps = [];
	crumps.push(
		<CrumpLink to="/" txtColor="black">
			{"Home"}
		</CrumpLink>,
	);

	for (const [ind, path] of pathnames.entries()) {
		let text = capitalize(path);
		// eslint-disable-next-line no-continue
		if (path === "home") continue;
		if (path === "file-upload") {
			text = "File Upload";
		}

		crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`} txtColor="#0b5080">{text}</CrumpLink>);
	}

	return (
		<Box className={classes.outer}>
			<AppBar id="header" position="static" className={classes.grow} style={{ height: maxDSLScreen ? "0px" : "70px" }}>
				<Toolbar className="header-container" style={{ height: maxDSLScreen ? "0px" : "100%" }}>
					<Box component={Link} to="/" style={{ height: "100%" }}>
						<Image src={logo} alt="Logo" fit="contain" sx={{ p: 0, my: 0, mx: 2, height: "100%", maxWidth: "200px" }} />
					</Box>
					<Box className={classes.grow} style={{ height: "100%" }} />
					{isAuthenticated && !maxDSLScreen && (
						<>
							<Box sx={{ display: "flex", height: "100%", py: 1, bgcolor: "transparent" }}>
								{isAdmin && (
									<>
										<ButtonWithText more text="Admin Panel" icon={adminIcon} handler={handleAdminMenuClick} closeAll={closeAll} />
										<Menu anchorEl={adminAnchorEl} open={openAdminMenu} onClose={handleAdminMenuClose}>
											{adminActions.map((action, index) => (
												<MenuItem
													key={index}
													onClick={() => {
														handleAdminMenuClose();
														window.location.href = `/admin${action?.link}`;
													}}
												>
													{action?.text}
												</MenuItem>
											))}
										</Menu>
									</>
								)}

								<Link to="/marketplace" style={{ textDecoration: "none" }}>
									<ButtonWithText text="Marketplace" icon={marketplaceIcon} closeAll={closeAll} />
								</Link>

								<Link to="/collaboration" style={{ textDecoration: "none" }}>
									<ButtonWithText text="Collaboration" icon={teamsIcon} closeAll={closeAll} />
								</Link>

								<ButtonWithText more text="Projects" icon={publicationsIcon} handler={handleProjectsMenuClick} closeAll={closeAll} />
								<Menu anchorEl={projectsAnchorEl} open={openProjectsMenu} onClose={handleProjectsMenuClose}>
									<MenuItem
										onClick={() => {
											handleDSLsMenuClose();
											window.location.href = `/projects`;
										}}
									>
										{"All Projects"}
									</MenuItem>
									{userProjects.map((prj, index) => (
										<MenuItem
											key={index}
										>
											{prj?.published && <StoreIcon />}
											<Link
												key={index}
												to={`/projects/${prj?._id}`}
												style={{ textDecoration: "none", color: "black" }}
												onClick={() => {
													handleDSLsMenuClose();
													window.location.href = `/projects/${prj?._id}`;
												}}
											>
												{prj?.title}
											</Link>
										</MenuItem>
									))}
								</Menu>

								<ButtonWithText more text="DSLs" icon={assignmentsIcon} handler={handleDSLsMenuClick} closeAll={closeAll} />
								<Menu anchorEl={dslsAnchorEl} open={openDSLsMenu} onClose={handleDSLsMenuClose}>
									<MenuItem
										onClick={() => {
											handleDSLsMenuClose();
											window.location.href = `/dsls`;
										}}
									>
										{"All DSLs"}
									</MenuItem>
									{dslsConstants.map((dsl, index) => (
										<MenuItem
											key={index}
										>
											<Link
												key={index}
												to={`/dsls/${dsl?._id}`}
												style={{ textDecoration: "none", color: "black" }}
												onClick={() => {
													handleDSLsMenuClose();
													window.location.href = `/dsls/${dsl?._id}`;
												}}
											>
												{dsl?.name}
											</Link>
										</MenuItem>
									))}
								</Menu>

								<Link to="/secrets" style={{ textDecoration: "none" }}>
									<ButtonWithText text="Secrets" icon={secretsIcon} closeAll={closeAll} />
								</Link>

								<ButtonWithText
									text="Logout"
									icon={logoutIcon}
									handler={() => {
										jwt.destroyToken();
										logout({ logoutParams: { returnTo: window.location.origin } });
									}}
									closeAll={closeAll}
								/>
							</Box>
							<Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
								<IconButton color="primary" onClick={handleMobileMenuOpen}><MoreIcon /></IconButton>
							</Box>
						</>
					)}
				</Toolbar>
			</AppBar>
			{isAuthenticated && !maxDSLScreen
				&& (
					<Paper elevation={0} className={classes.root}>
						<Breadcrumbs
							className="header-container"
							fontSize="small"
							separator={<NavigateNextIcon fontSize="small" />}
						>
							{crumps.map((e) => <div key={`crump_${e.props.to}`}>{e}</div>)}
						</Breadcrumbs>
					</Paper>
				)}
			{isAuthenticated
				&& (
					renderMobileMenu
				)}
		</Box>
	);
};

export default memo(Header);
