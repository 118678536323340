import humidifierIcon from "../../../assets/images/missionItems/effectors/humidifier.png";
import lightIcon from "../../../assets/images/missionItems/effectors/light.png";
import relayIcon from "../../../assets/images/missionItems/effectors/relay.png";
import speakerIcon from "../../../assets/images/missionItems/effectors/speaker.png";
import thermostatIcon from "../../../assets/images/missionItems/effectors/thermostat.png";

const effectors = {
	title: "Effectors",
	items: {
		humidifier: {
			icon: humidifierIcon,
			variables: {
				state: {
					hide: true,
					type: "select",
					name: "State",
					value: "on",
					options: ["on", "off"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				humidity: {
					type: "number",
					name: "Humidity",
					value: 50,
					min: 0,
					max: 100,
					step: 1,
					precision: 1,
					format: "%",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				proximityMode: {
					type: "boolean",
					name: "Set Only When Close",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				proximityDistance: {
					type: "number",
					name: "Proximity Distance",
					value: 5,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					needs: { proximityMode: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automation: {
					type: "boolean",
					name: "Predefined behavior (cannot be controlled)",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automationReverse: {
					type: "boolean",
					name: "Reverse State Transitions",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationLoop: {
					type: "boolean",
					name: "Loop State Transitions",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				stateAvailable: {
					type: "boolean",
					name: "State is Available",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationSteps: {
					type: "stateInput",
					name: "Automation Steps",
					value: [],
					variables: ["humidity"],
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
			},
			tooltip: {
				header: "Humidifier",
				body: "A humidifier can be used to change the humidity in the area it is placed",
			},
		},
		light: {
			icon: lightIcon,
			variables: {
				state: {
					hide: true,
					type: "select",
					name: "State",
					value: "on",
					options: ["on", "off"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				luminosity: {
					type: "number",
					name: "Luminosity",
					value: 50,
					min: 0,
					max: 100,
					step: 1,
					precision: 0,
					format: "%",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				r: {
					hide: true,
					type: "number",
					name: "Red",
					value: 255,
					min: 0,
					max: 255,
					step: 1,
					precision: 0,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				g: {
					hide: true,
					type: "number",
					name: "Green",
					value: 255,
					min: 0,
					max: 255,
					step: 1,
					precision: 0,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				b: {
					hide: true,
					type: "number",
					name: "Blue",
					value: 255,
					min: 0,
					max: 255,
					step: 1,
					precision: 0,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				proximityMode: {
					type: "boolean",
					name: "Set Only When Close",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				proximityDistance: {
					type: "number",
					name: "Proximity Distance",
					value: 5,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					needs: { proximityMode: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automation: {
					type: "boolean",
					name: "Predefined behavior (cannot be controlled)",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automationReverse: {
					type: "boolean",
					name: "Reverse State Transitions",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationLoop: {
					type: "boolean",
					name: "Loop State Transitions",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				stateAvailable: {
					type: "boolean",
					name: "State is Available",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationSteps: {
					type: "stateInput",
					name: "Automation Steps",
					value: [],
					variables: ["luminosity", "r", "g", "b"],
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
			},
			tooltip: {
				header: "Light",
				body: "A programmable, smart light that can be turned on and off, defining its luminosity",
			},
		},
		panTilt: {
			hide: true,
			variables: {
				state: {
					hide: true,
					type: "select",
					name: "State",
					value: "on",
					options: ["on", "off"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panMin: {
					type: "number",
					name: "Pan Min",
					value: -90,
					min: -180,
					max: "panMax",
					step: 10,
					precision: 1,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				panMax: {
					type: "number",
					name: "Pan Max",
					value: 90,
					min: "panMin",
					max: 180,
					step: 10,
					precision: 1,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				tiltMin: {
					type: "number",
					name: "Tilt Min",
					value: -90,
					min: -180,
					max: "tiltMax",
					step: 10,
					precision: 1,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				tiltMax: {
					type: "number",
					name: "Tilt Max",
					value: 90,
					min: "tiltMin",
					max: 180,
					step: 10,
					precision: 1,
					format: "°",
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				mode: {
					type: "select",
					name: "Pan Tilt Operation Mode",
					value: "simulation",
					options: ["simulation", "mock"],
					needs: { onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				operation: {
					type: "select",
					name: "Pan Tilt Operation Type",
					value: "sinus",
					options: ["sinus"],
					needs: { panTiltMode: ["mock"], onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				hz: {
					type: "number",
					name: "Pan Tilt Frequency",
					value: 3,
					min: 0,
					max: 10,
					step: 1,
					precision: 1,
					format: "Hz",
					needs: { panTiltOperation: ["sinus"], panTiltMode: ["mock"], onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				step: {
					type: "number",
					name: "Pan Tilt Step",
					value: 0.01,
					min: 0,
					step: 0.01,
					precision: 2,
					format: "°",
					needs: { panTiltOperation: ["sinus"], panTiltMode: ["mock"], onPanTilt: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
			},
		},
		relay: {
			icon: relayIcon,
			variables: {
				allowedStates: {
					type: "tagInput",
					name: "Allowed States",
					value: ["Off", "On"],
					affects: ["initialState"],
					tooltipDisplay: true,
					metersDisplay: false,
				},
				initialState: {
					type: "select",
					name: "Initial State",
					value: "Off",
					options: "allowedStates",
					tooltipDisplay: true,
					metersDisplay: false,
				},
				state: {
					hide: true,
					type: "select",
					name: "State",
					value: "Off",
					options: "allowedStates",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				proximityMode: {
					type: "boolean",
					name: "Set Only When Close",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				proximityDistance: {
					type: "number",
					name: "Proximity Distance",
					value: 5,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					needs: { proximityMode: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automation: {
					type: "boolean",
					name: "Predefined behavior (cannot be controlled)",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automationReverse: {
					type: "boolean",
					name: "Reverse State Transitions",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationLoop: {
					type: "boolean",
					name: "Loop State Transitions",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				stateAvailable: {
					type: "boolean",
					name: "State is Available",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationSteps: {
					type: "stateInput",
					name: "Automation Steps",
					value: [],
					variables: ["state"],
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
			},
			tooltip: {
				header: "Switch",
				body: "A mechanical switch that can take various states",
			},
		},
		speaker: {
			icon: speakerIcon,
			variables: {
				state: {
					hide: true,
					type: "select",
					name: "State",
					value: "on",
					options: ["on", "off"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				text: {
					hide: true,
					type: "text",
					name: "Text",
					value: "",
					placeholder: "Text",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				volume: {
					hide: true,
					type: "number",
					name: "Volume",
					value: 50,
					min: 0,
					max: 100,
					step: 1,
					precision: 1,
					format: "%",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				language: {
					hide: true,
					type: "select",
					name: "Language",
					value: "EL",
					options: ["EL", "EN"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				proximityMode: {
					type: "boolean",
					name: "Set Only When Close",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				proximityDistance: {
					type: "number",
					name: "Proximity Distance",
					value: 5,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					needs: { proximityMode: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automation: {
					type: "boolean",
					name: "Predefined behavior (cannot be controlled)",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automationReverse: {
					type: "boolean",
					name: "Reverse State Transitions",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationLoop: {
					type: "boolean",
					name: "Loop State Transitions",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				stateAvailable: {
					type: "boolean",
					name: "State is Available",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationSteps: {
					type: "stateInput",
					name: "Automation Steps",
					value: [],
					variables: ["text", "volume", "language"],
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
			},
			tooltip: {
				header: "Speaker",
				body: "A speaker can be used to “say” things or play sounds",
			},
		},
		thermostat: {
			icon: thermostatIcon,
			variables: {
				state: {
					hide: true,
					type: "select",
					name: "State",
					value: "on",
					options: ["on", "off"],
					tooltipDisplay: false,
					metersDisplay: false,
				},
				temperature: {
					type: "number",
					name: "Temperature",
					value: 20,
					min: 0,
					max: 200,
					step: 1,
					precision: 1,
					format: "°C",
					tooltipDisplay: false,
					metersDisplay: false,
				},
				range: {
					type: "number",
					name: "Range",
					value: 20,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					tooltipDisplay: false,
					metersDisplay: true,
				},
				proximityMode: {
					type: "boolean",
					name: "Set Only When Close",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				proximityDistance: {
					type: "number",
					name: "Proximity Distance",
					value: 5,
					min: 0,
					max: 1000,
					step: 1,
					precision: 1,
					format: "m",
					needs: { proximityMode: [true] },
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automation: {
					type: "boolean",
					name: "Predefined behavior (cannot be controlled)",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
				},
				automationReverse: {
					type: "boolean",
					name: "Reverse State Transitions",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationLoop: {
					type: "boolean",
					name: "Loop State Transitions",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				stateAvailable: {
					type: "boolean",
					name: "State is Available",
					value: false,
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
				automationSteps: {
					type: "stateInput",
					name: "Automation Steps",
					value: [],
					variables: ["temperature"],
					tooltipDisplay: false,
					metersDisplay: false,
					needs: { automation: [true] },
				},
			},
			tooltip: {
				header: "Thermostat",
				body: "A thermostat can be used to change the temperature in the area it is placed",
			},
		},
	},
};

export default effectors;
