/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */

import { memo, useCallback, useState, useMemo, useRef, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { shallow } from "zustand/shallow";
import { setBrokers } from "../components/AppCreator/slice-brokers.js";
import { addThread, deleteThread, setThreads } from "../components/AppCreator/slice-threads.js";
import { addNodeParameters, deleteNodeParameters, setNodesParameters } from "../components/AppCreator/slice-nodes.js";
import { addLog, cleanLogs, setLogsParameters } from "../components/AppCreator/slice-logs.js";
import { addVariable, deleteVariable, setVariableParameters, updateVariable, updateAllVariables } from "../components/AppCreator/slice-variables.js";
import { addList, deleteList, setListParameters, updateList } from "../components/AppCreator/slice-lists.js";
import { addTab, deleteTab, setTabParameters, updateTab } from "../components/AppCreator/slice-tabs.js";
import AreYouSurePopup from "../components/Popups/AreYouSurePopup.js";
import { jwt, useSnackbar } from "../utils/index.js";
import { TreeView, TreeItem } from '@mui/x-tree-view';

import {
	Divider,
	Grid,
	Typography,
	Select,
	MenuItem,
	IconButton,
	Tabs,
	Tab,
	Box,
	Tooltip,
} from "@mui/material";
import { Delete, Info, ZoomInMap, ZoomOutMap, Add, Close, ExpandMore, ExpandLess } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import clearAllIcon from "../assets/dsl-icons/Clear_all.png";
import clearTabIcon from "../assets/dsl-icons/CLEAR_ALL_TAB.png";
import copyIcon from "../assets/dsl-icons/CopyEmpty.png";
import pasteIcon from "../assets/dsl-icons/PasteEmpty.png";
import undoIcon from "../assets/dsl-icons/Undo.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import ReactFlow, {
	Controls,
	Background,
	useNodesState,
	useEdgesState,
	addEdge,
	applyEdgeChanges,
	applyNodeChanges,
	ReactFlowProvider,
	MiniMap,
	MarkerType,
} from 'reactflow';

import 'reactflow/dist/style.css';

import Spinner from "../components/Spinner.js";
// import { useSnackbar } from "../utils/index.js";

import useGlobalState from "../use-global-state.js";
import { systemToolboxes } from "../utils/system-toolboxes.js";
import CustomNode from "../components/AppCreator/node-custom.js";
import ThreadNode from "../components/AppCreator/node-thread.js";
import ConditionNode from "../components/AppCreator/node-condition.js";
import RandomNode from "../components/AppCreator/node-random.js";
import CommentNode from "../components/AppCreator/node-comment.js";
import { connect, connectToGeneralBroker, disconnect, disconnectFromGeneralBroker, subscribeToQueue, unsubscribeFromQueue } from "../utils/websocket.js";
import { getModel } from "../api/index.js";
import CreateMission from "../components/create-mission/index.js";
import Popup from "../components/Popup.js";
import Form from "../components/Form.js";
import MDEditor from "@uiw/react-md-editor";
// import { on } from "events";

const PROXIMITY_THRESHOLD = 200;
const GHOST_EDGE_COLOR = "rgba(200, 200, 200, 0.5)";

const nodeTypes = {
	custom: CustomNode,
	thread: ThreadNode,
	condition: ConditionNode,
	random: RandomNode,
	comment: CommentNode,
};

// const evaluatePath = (obj, path) => path.split('.').reduce((acc, key) => acc[key], obj);

const onDragStart = (event, nodeType) => {
	event.dataTransfer.setData('application/reactflow', JSON.stringify(nodeType));
	event.dataTransfer.effectAllowed = 'move';
};

const fixNodesValues = (_nodes, _store) => {
	const nds = _nodes.map((node) => {
		const foundNode = _store.storeNodes.find((n) => n.id === node.id);
		if (foundNode) {
			node.data = {
				...node.data,
				parameters: "parameters" in foundNode?.parameters
					? foundNode?.parameters?.parameters
					: foundNode?.parameters,
			};

			if ("inputs" in foundNode.parameters) {
				node.data.inputs = foundNode.parameters.inputs;
			}

			if ("outputs" in foundNode.parameters) {
				node.data.outputs = foundNode.parameters.outputs;
			}
		}

		return node;
	});
	return nds;
};

const sortLogs = (a, b) => {
	const aTs = new Date(a.payload.timestamp);
	const bTs = new Date(b.payload.timestamp);
	return bTs - aTs;
};

const Testbed = (props) => {
	const { error, success, info } = useSnackbar();
	const isLoading = useMemo(() => false, []);
	const [nodes, setNodes] = useNodesState([]);
	const [edges, setEdges] = useEdgesState([]);
	const [ghostEdge, setGhostEdge] = useState(null);

	const [variables, setVariables] = useState([]);
	const [toolboxVariables, setToolboxVariables] = useState([]);
	const [customVariables, setCustomVariables] = useState([]);
	const [customVariablesWithValues, setCustomVariablesWithValues] = useState([]);
	const [liveVariables, setLiveVariables] = useState({});
	const [customLists, setCustomLists] = useState([]);
	const [customListsWithValues, setCustomListsWithValues] = useState([]);
	const [lists, setLists] = useState([]);
	const [customListVariables, setCustomListVariables] = useState([]);
	const [clearAllPopup, setClearAllPopup] = useState(false);
	const [clearTabPopup, setClearTabPopup] = useState(false);
	const [aliveDeployer, setAliveDeployer] = useState(false);
	const [removeTabPopup, setRemoveTabPopup] = useState(false);
	const [tabToRemove, setTabToRemove] = useState(null);
	const [renameTabPopup, setRenameTabPopup] = useState(false);
	const [tabToRename, setTabToRename] = useState(null);
	const [goaldslUpdate, setGoaldslUpdate] = useState(null);

	const now = new Date();
	const timestamp = now.toLocaleString();

	const customVariablesRef = useRef();
	customVariablesRef.current = customVariables;

	const customListsRef = useRef();
	customListsRef.current = customLists;

	const toolboxVariablesRef = useRef();
	toolboxVariablesRef.current = toolboxVariables;

	const customListVariablesRef = useRef();
	customListVariablesRef.current = customListVariables;

	// const { setViewport } = useReactFlow();
	const reactFlowWrapper = useRef(null);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [toolboxes, setToolboxes] = useState(systemToolboxes);
	const [internalSystemToolboxes, setInternalSystemToolboxes] = useState(systemToolboxes);
	// const [modelToolboxes, setModelToolboxes] = useState([]);
	const [counter, setCounter] = useState(0);
	const [hasChanged, setHasChanged] = useState(false);
	const [autoSaveTimerId, setAutoSaveTimerId] = useState(null);
	const modelUpdate = props.modelUpdate;
	const dispatch = useDispatch();
	const previewMode = props?.previewMode;
	const setIsDeploying = props?.setIsDeploying;

	// Get all variables from redux
	const storeThreads = useSelector((state) => state.threads);
	const storeBrokers = useSelector((state) => state.brokers);
	const storeNodes = useSelector((state) => state.nodes);
	const storeLogs = useSelector((state) => state.logs);
	const storeVars = useSelector((state) => state.variables);
	const storeLists = useSelector((state) => state.lists);
	const [isSelectionChange, setIsSelectionChange] = useState(false);

	const [initialModelLoaded, setInitialModelLoaded] = useState(false);
	const [fitViewExecuted, setFitViewExecuted] = useState(false);

	const prevModel = useRef(props.model);

	const [programRunning, setProgramRunning] = useState(false);
	const [lastExecutionTimestamp, setLastExecutionTimestamp] = useState(0);
	const [runningNodes, setRunningNodes] = useState([]);
	const [selectedNode, setSelectedNode] = useState(null);
	const [highlightedEdges, setHighlightedEdges] = useState([]);
	const [initialLoad, setInitialLoad] = useState(true);
	const [edgeStyle, setEdgeStyle] = useState(() => {
		const storedValue = localStorage.getItem('edgeStyle');
		return storedValue === null ? "default" : JSON.parse(storedValue);
	});
	const [validationErrors, setValidationErrors] = useState([]);
	const [isFromValidation, setIsFromValidation] = useState(false);
	const [currentConnection, setCurrentConnection] = useState(null);
	const connectionRef = useRef();
	connectionRef.current = currentConnection;
	const [connected, setConnected] = useState(false);
	const [isProximityEnabled, setIsProximityEnabled] = useState(false);
	// () => {
	// const storedValue = localStorage.getItem('proximityEnabled');
	// return storedValue === null ? true : JSON.parse(storedValue);
	// }

	const [envpopModel, setEnvpopModel] = useState(null);
	const [envpopSize, setEnvpopSize] = useState("small");
	const envpopRef = useRef(null);
	const [inSimulation, setInSimulation] = useState(props.inSimulation);

	const [nodeTooltip, setNodeTooltip] = useState(null);
	const [modelStateArray, setModelStateArray] = useState([[{ id: 0, label: "Main Tab", nodes: [], edges: [] }]]);

	const [tabs, setTabs] = useState([{ id: 0, label: "Main Tab", nodes: [], edges: [] }]);
	const [activeTab, setActiveTab] = useState(0);
	const [tabCounter, setTabCounter] = useState(1);

	const groupedVariables = useMemo(() => toolboxVariables.reduce((acc, variable) => {
		const groupKey = variable.split(".")[0];
		if (!acc[groupKey]) {
			acc[groupKey] = [];
		}

		acc[groupKey].push(variable);
		return acc;
	}, {}), [toolboxVariables]);

	const tabsRef = useRef();
	tabsRef.current = tabs;

	const handleAddTab = () => {
		const newTab = { id: tabCounter, label: `Tab ${tabCounter}`, nodes: [], edges: [] };
		setTabs([...tabs, newTab]);
		setActiveTab(newTab.id);
		setTabCounter(tabCounter + 1);
		dispatch(addTab(newTab));
		setModelStateArray((prevState) => [
			...prevState,
			[...tabs, newTab],
		]);
	};

	const handleDuplicateTab = (tabId) => {
		const tabToDuplicate = tabs.find((tab) => tab.id === tabId);
		if (!tabToDuplicate) return;

		let newCounter = counter;
		const timestamp = Date.now().toString().slice(-5);
		const nodeIdMap = {};
		const newNodes = tabToDuplicate.nodes.map((node) => {
			newCounter += 1;
			const newId = `0.${timestamp}${newCounter}${timestamp}${newCounter}`;
			nodeIdMap[node.id] = newId;

			return {
				...node,
				id: newId,
				data: { ...node.data, id: newId, count: newCounter },
			};
		});

		const newEdges = tabToDuplicate.edges.map((edge) => {
			const newSource = nodeIdMap[edge.source] || edge.source;
			const newTarget = nodeIdMap[edge.target] || edge.target;
			return {
				...edge,
				id: `reactflow__edge-${newSource}out_0-${newTarget}in_0`,
				source: newSource,
				target: newTarget,
			};
		});

		const newTab = {
			id: tabCounter,
			label: `Tab ${tabCounter}`,
			nodes: newNodes,
			edges: newEdges,
		};

		setTabs([...tabs, newTab]);
		setActiveTab(newTab.id);
		setTabCounter(tabCounter + 1);
		setCounter(newCounter);
		dispatch(addTab(newTab));
		setModelStateArray((prevState) => [...prevState, [...tabs, newTab]]);
	};

	const handleRemoveTab = () => {
		if (tabToRemove === 0) return;
		const nodesToBeDeleted = tabs.find((tab) => tab.id === tabToRemove).nodes.map((node) => node.id);
		for (const node of nodesToBeDeleted) {
			setVariables(variables.filter((v) => v.nodeId !== node));
			setLists(lists.filter((l) => l.nodeId !== node));
			dispatch(deleteNodeParameters({ id: node }));
			dispatch(deleteThread({ id: node }));
			dispatch(deleteVariable({ id: node }));
			dispatch(deleteList({ id: node }));
		}

		const filteredTabs = tabs.filter((tab) => tab.id !== tabToRemove);
		setTabs(filteredTabs);
		setActiveTab(filteredTabs.length > 0 ? filteredTabs[0].id : 0);
		dispatch(deleteTab(tabToRemove));
		setTabToRemove(null);
		setRemoveTabPopup(false);
		setModelStateArray((prevState) => [
			...prevState,
			filteredTabs,
		]);
	};

	const { maxDSLScreen } = useGlobalState((e) => ({
		maxDSLScreen: e.maxDSLScreen,
	}), shallow);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const updateModel = useCallback(() => {
		if (reactFlowInstance === null || programRunning) {
			return;
		}

		const toStore = reactFlowInstance.toObject();
		// Exclude className from nodes and edges
		const nodesWithoutClassName = tabs[0].nodes.map(({ className, selected, data, ...node }) => {
			const { error, ...filteredData } = data || {}; // Remove the error from data
			return { ...node, data: filteredData };
		});
		const edgesWithoutClassName = tabs[0].edges.map(({ className, selected, ...edge }) => edge);

		toStore.nodes = nodesWithoutClassName;
		toStore.edges = edgesWithoutClassName;
		toStore.toolboxes = toolboxes;
		toStore.store = {
			storeVariables: variables,
			storeThreads,
			storeBrokers,
			storeNodes,
			storeLists: lists,
			storeTabs: tabs,
		};

		if (!initialLoad && !isFromValidation) {
			console.info(">>>>>>>>>>>>>>>>>>>>>>> [Testbed] Saving model to DB");
			modelUpdate(JSON.stringify(toStore));
		}

		setHasChanged(false);
		console.log("In update model: has changed --> False");
		setInitialLoad(false);
		setIsFromValidation(false);
	});

	// useEffect(() => {
	// 	localStorage.setItem('proximityEnabled', JSON.stringify(isProximityEnabled));
	// }, [isProximityEnabled]);

	useEffect(() => {
		localStorage.setItem('edgeStyle', JSON.stringify(edgeStyle));
	}, [edgeStyle]);

	useEffect(() => {
		setNodes((nds) => nds.map((node) => {
			node.style = {
				...node.style,
				opacity: programRunning ? 0.3 : 1,
			};
			return node;
		}));
	}, [programRunning]);

	useEffect(() => {
		setTabs((prevTabs) =>
			prevTabs.map((tab) => ({
				...tab,
				nodes: tab.nodes.map((node) => {
					node.style = {
						...node.style,
						opacity: programRunning && (runningNodes.includes(node.id) || runningNodes[0]?.includes(node?.id?.split('.')[1])) ? 1 : (programRunning ? 0.3 : 1),
					};
					return node;
				}),
			})));
	}, [runningNodes]);

	useEffect(() => {
		if (!initialLoad) {
			if (selectedNode === null && highlightedEdges.length === 0) {
				setNodes((nds) => nds.map((node) => ({
					...node,
					className: '',
				})));

				setEdges((eds) => eds.map((edge) => ({
					...edge,
					className: '',
				})));
			} else {
				setNodes((nds) => nds.map((node) => ({
					...node,
					className: node.id === selectedNode ? 'selected' : '',
				})));

				setEdges((eds) => eds.map((edge) => ({
					...edge,
					className: highlightedEdges.includes(edge.id) ? 'selected' : '',
				})));
			}
		}
	}, [selectedNode, highlightedEdges]);

	useEffect(() => {
		if (!programRunning) {
			setTabs((prevTabs) =>
				prevTabs?.map((tab) =>
					tab.id === activeTab
						? {
							...tab,
							nodes: fixNodesValues(tab.nodes, { storeNodes }),
						}
						: tab));
			setNodes((currentNodes) => fixNodesValues(currentNodes, { storeNodes }));
		}
	}, [storeNodes, programRunning]);

	useEffect(() => {
		if (programRunning) {
			return;
		}

		const _tmpVars = [];
		const _tmpLists = [];
		for (const tab of tabs) {
			for (const node of tab.nodes) {
				if (node?.data?.label === "Create variable") {
					_tmpVars.push({
						nodeId: node.id,
						variable: node.data.parameters[0].value,
						data: node,
						count: counter + 1,
						value: node.data.parameters[1].value,
						literalVariables: [node.data.parameters[0].value],
					});

					const storeVar = storeVars.find((v) => v.variableId === node.id);
					if (storeVar && node.data.parameters[0].value !== storeVar.variableName) {
						dispatch(updateVariable({
							id: node.data.id,
							data: node.data,
							parameters: node.parameters,
						}));
					}
				}

				if (node?.data?.label === "Create variables" && node.data.parameters[0].value) {
					const varsObjectArr = JSON.parse(node.data.parameters[0].value);
					const varsObjectsNames = varsObjectArr.map((v) => v?.name);

					const storeVarNames = new Set(storeVars
						.filter((v) => v.variableId === node.id)
						.map((v) => v.variableName));

					const allVarsExist = varsObjectsNames.every((name) => storeVarNames.has(name));

					for (const variable of varsObjectArr) {
						_tmpVars.push({
							nodeId: node.id,
							variable: variable?.name,
							data: node,
							count: counter + 1,
							value: variable?.value,
							literalVariables: [variable?.name],
						});
					}

					if (!allVarsExist) {
						dispatch(updateAllVariables({
							id: node.id,
							variables: varsObjectArr,
						}));
					}
				}

				if (node.data.label === "Create List") {
					_tmpLists.push({
						nodeId: node.id,
						variable: node.data.parameters[0].value,
						data: node,
						count: counter + 1,
						value: node.data.parameters[1].value,
						literalVariables: [node.data.parameters[0].value],
					});

					const storeList = storeLists.find((l) => l.listId === node.id);
					if (storeList && node.data.parameters[0].value !== storeList.listName) {
						dispatch(updateList({
							id: node.data.id,
							data: node.data,
							parameters: node.parameters,
						}));
					}
				}
			}
		}

		const parsedlist = [];
		const parsedVarsList = [];
		const parsedlistList = [];
		const parsedListListWithValues = [];
		const parsedListVariables = [];
		for (const v of _tmpVars) {
			const _n = v.variable ?? "undefined";
			const _v = v.value ?? "undefined";

			for (const l of v.literalVariables) {
				parsedlist.push(l);
				parsedVarsList.push({ name: _n, value: _v });
			}
		}

		for (const l of _tmpLists) {
			const _n = l.variable ?? "undefined";
			const _v = l.value ?? "undefined";
			for (const lv of l.literalVariables) {
				parsedlistList.push(lv);
				parsedListVariables.push(`${lv}_max`, `${lv}_min`, `${lv}_average`, `${lv}_std`);
				parsedListListWithValues.push({ name: _n, value: _v });
			}
		}

		setVariables(_tmpVars);
		setLists(_tmpLists);
		setCustomVariables(parsedlist);
		setCustomVariablesWithValues(parsedVarsList);
		setCustomLists(parsedlistList);
		setCustomListsWithValues(parsedListListWithValues);
		setCustomListVariables(parsedListVariables);
	}, [tabs]);

	// These are only for the initial load
	const updateToolboxVariables = (_toolboxes) => {
		// Check subscribers to add variables
		const tvars = [];
		for (const toolbox of _toolboxes) {
			for (const node of toolbox.nodes) {
				if (node.action && (node.action.type === "subscribe" || node.action.type === "rpc") && node.action.storage) {
					// check if variable already exists in variables
					tvars.push({
						nodeId: node.id,
						variable: node.action.storage,
						data: node,
						count: counter + 1,
						value: undefined,
						literalVariables: node.literalVariables,
					});
				}
			}
		}

		const parsedToolboxVariables = [];
		for (const v of tvars) {
			const _n = v.variable ?? "undefined";
			const _v = v.value ?? "undefined";

			const literals = v.literalVariables;

			for (const l of literals) {
				parsedToolboxVariables.push(l);
			}
		}

		setToolboxVariables(parsedToolboxVariables);
	};

	const updateEdgeTypes = (newType) => {
		setTabs((prevTabs) =>
			prevTabs?.map((tab) => {
				const newEdges = tab.edges.map((edge) => {
					edge.type = newType;
					return edge;
				});
				return { ...tab, edges: newEdges };
			}));
	};

	// Used to load the stored model from the props
	useEffect(() => {
		setValidationErrors(props?.validationErrors);
		// Second time, where the model is properly fetched
		if ((prevModel.current !== props.model && prevModel.current === "" && props.model !== "") || previewMode) {
			const flow = JSON.parse(props.model);
			if (flow) {
				const alreadyExistingToolboxes = flow?.toolboxes.map((tlb) => tlb?.name);

				const newToolboxes = [];

				for (const systemToolbox of systemToolboxes) {
					if (!alreadyExistingToolboxes.includes(systemToolbox?.name)) {
						newToolboxes.push(systemToolbox);
					}
				}

				if (newToolboxes.length > 0) {
					setToolboxes([...newToolboxes, ...flow?.toolboxes]);
				} else {
					setToolboxes(flow?.toolboxes);
				}

				// Update all nodes with the respective parameters in the flow.store.storeNodes
				const nds = fixNodesValues(flow.nodes, flow.store);
				flow.nodes = nds;

				const existingTabs = flow?.store?.storeTabs || [{ id: 0, label: "Main Tab", nodes: [], edges: [] }];
				if (modelStateArray.length < 2) {
					setModelStateArray([existingTabs]);
				}

				setTabs(existingTabs);
				const maxTabId = existingTabs.reduce((max, tab) => Math.max(max, tab.id), 0);

				setTabCounter(maxTabId + 1);
				setNodes(flow.nodes || []);
				setEdges(flow.edges || []);
				// Give the max counter value of nodes plus one to the counter
				for (const node of flow.nodes) {
					if (node.data.count > counter) {
						setCounter(node.data.count);
					}
				}

				// If the model is created directly from appcreator it does not have toolboxes
				if (flow?.toolboxes) {
					updateToolboxVariables(flow.toolboxes);
				}

				dispatch(setTabParameters(flow?.store?.storeTabs || []));
				dispatch(setThreads(flow.store.storeThreads || []));
				dispatch(setBrokers(flow.store.storeBrokers || []));
				dispatch(setNodesParameters(flow.store.storeNodes || []));
				dispatch(setVariableParameters(flow.store.storeVariables || []));
				dispatch(setListParameters(flow.store.storeLists || []));
				dispatch(setLogsParameters([]));
				setInitialModelLoaded(true);

				const storedValue = localStorage.getItem('edgeStyle');
				if (storedValue !== null) {
					setEdgeStyle(JSON.parse(storedValue));
					updateEdgeTypes(JSON.parse(storedValue));
				} else if (flow?.edges && flow?.edges.length > 0) {
					setEdgeStyle(flow.edges[0].type);
				} else {
					setEdgeStyle("default");
				}
			}

			prevModel.current = props.model;
			setProgramRunning(false);
			setLastExecutionTimestamp(Date.now());
		}
	}, [props]);

	useEffect(() => {
		if (reactFlowInstance && !fitViewExecuted && nodes?.length > 1) {
			reactFlowInstance.fitView({ minZoon: 0.5, maxZoom: 0.6 });
			setFitViewExecuted(true);
		}
	}, [reactFlowInstance, initialModelLoaded]);

	const flattenObject = (obj, parentKey = "") => {
		const result = {};

		if (typeof obj === "object" && obj !== null) {
			if (Array.isArray(obj)) {
				result[parentKey] = obj;
			} else {
				for (const key in obj) {
					if (obj.hasOwnProperty(key)) {
						const newKey = parentKey ? `${parentKey}.${key}` : key;
						const value = obj[key];

						if (typeof value === "object" && value !== null && !Array.isArray(value)) {
							Object.assign(result, flattenObject(value, newKey));
						} else {
							result[newKey] = value;
						}
					}
				}
			}
		} else {
			result[parentKey] = obj;
		}

		return result;
	};

	const updateVariableValue = (payload) => {
		const variable = payload.key;
		const value = payload.value;
		const allVariables = [...customVariablesRef.current, ...toolboxVariablesRef.current,
		...customListVariablesRef.current, ...customListsRef.current];

		const flattenedValues = flattenObject(value, variable);
		const filteredValues = Object.fromEntries(
			Object.entries(flattenedValues).filter(([key]) => allVariables.some((vrbl) => key === vrbl || key.startsWith(vrbl))),
		);

		const nodesString = JSON.stringify(tabsRef.current);

		const alwaysIncludedValues = Object.fromEntries(
			Object.entries(filteredValues).filter(([key]) => customListVariables.includes(key)),
		);
		const finalValues = Object.fromEntries(
			Object.entries(filteredValues).filter(([key]) => nodesString.includes(key)),
		);
		const updatedValues = { ...finalValues, ...alwaysIncludedValues };
		if (Object.keys(updatedValues).length > 0) {
			setLiveVariables((prevVars) => ({
				...prevVars,
				...updatedValues,
			}));
		}
	};

	const appExecutorTimeout = useRef(null);
	const onMessage = useCallback((msg) => {
		switch (msg.type) {
			case "connectedToBroker": {
				setConnected(true);
				break;
			}

			case "errorConnectingToBroker": {
				setConnected(false);
				error("There was an error trying to connect to broker. Please check the credentials.");
				break;
			}

			case "appExecutorHeartbeat": {
				console.log(">>>> Message received from alive", msg);
				setAliveDeployer(true);
				clearTimeout(appExecutorTimeout.current);
				appExecutorTimeout.current = setTimeout(() => {
					setAliveDeployer(false);
				}, 20_000);

				break;
			}

			case "appMakerFeedback": {
				// console.log("Message received from feedback", msg);
				const payload = JSON.parse(msg.message);
				console.log("Payload received", payload);
				if (payload.label === "Log" && (payload.message !== "start" && payload.message !== "end")) {
					dispatch(addLog({
						payload: payload.message,
					}));
				}

				if (payload.label === "Score") {
					// setEvaluationScore(payload.message);
					console.log(">>>> Score received", payload.message);
					setGoaldslUpdate(payload.message);
					break;
				}

				let abruptStop = false;
				if ("message" in payload && typeof payload.message !== "string" && payload.message.message.includes("runtime_error")) {
					console.log("Runtime error received", payload);
					dispatch(addLog({
						payload: payload.message,
					}));
					error("Runtime error occurred. Check the logs for more information.");
					abruptStop = true;
				}

				if ("message" in payload && typeof payload.message === "string" && payload.message.includes("Fatal goal triggered")) {
					console.log("Fatal goal triggered", payload);
					error("Fatal goal triggered!");
					abruptStop = true;
				}

				if (abruptStop) {
					setProgramRunning(false);
					setInSimulation(false);
					setIsDeploying(false);
					props.setInSimulation(false);
					props.setIsDeploying(false);
					const ts = Date.now();
					setLastExecutionTimestamp(ts);
				}

				if ("program" in payload) {
					console.log("Program received", payload.program);
					// Make the background of all nodes white
					setProgramRunning(payload.program === "start");

					if (payload.program === "start") {
						dispatch(cleanLogs());
						setLiveVariables({});
						setInSimulation(true);
					} else {
						const ts = Date.now();
						setLastExecutionTimestamp(ts);
						props.setInSimulation(false);
						props.setIsDeploying(false);
						console.log("Program ended on", new Date(ts).toLocaleString("el-GR"));
					}
				}

				if ("node_id" in payload) {
					if (payload.message === "start") {
						// Add the id in the running nodes
						setRunningNodes((prevNodes) => {
							if (prevNodes.includes(payload.node_id)) {
								return prevNodes;
							}

							return [...prevNodes, payload.node_id];
						});
					} else {
						// Remove the id from the running nodes
						setRunningNodes((prevNodes) => prevNodes.filter((id) => id !== payload.node_id));
					}
				}

				if ("type" in payload && payload.type === "storage") {
					updateVariableValue(payload);
				}

				break;
			}

			default: {
				console.log("Unknown message type");
				console.log(msg);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, error, info, props, setIsDeploying]);

	useEffect(() => {
		// console.log("Custom variables changed", customVariables);
	}, [customVariables]);

	useEffect(() => {
		// console.log("Toolbox variables changed", toolboxVariables);
	}, [toolboxVariables]);

	// Will be executed on start
	useEffect(() => {
		(async () => {
			let conn;
			if (!currentConnection) {
				conn = await connect({
					onConnect: () => console.log("WebSocket connected"),
					onError: () => { },
					onMessage,
					type: "appcreator",
				});

				setCurrentConnection(conn);

				connectToGeneralBroker({ connection: conn });
			}

			return async () => {
				if (conn) {
					disconnectFromGeneralBroker({ connection: conn });
					await disconnect({ connection: conn });
				}
			};
		})();
	}, [currentConnection, onMessage]);

	useEffect(() => {
		if (connected && currentConnection) {
			subscribeToQueue(`locsys/app_executor_node/${jwt.decode().id}/heartbeat`, null, currentConnection, "appExecutorHeartbeat");
		}

		return () => {
			if (connected && currentConnection) {
				unsubscribeFromQueue(`locsys/app_executor_node/${jwt.decode().id}/heartbeat`, null, currentConnection);
			}
		};
	}, [connected, currentConnection]);

	useEffect(() => {
		if (inSimulation && currentConnection) {
			subscribeToQueue(`locsys/appmaker/feedback/${props.modelid}`, null, currentConnection, "appMakerFeedback");
		}

		if (!inSimulation && currentConnection) {
			unsubscribeFromQueue(`locsys/appmaker/feedback/${props.modelid}`, null, currentConnection);
		}
	}, [inSimulation, currentConnection, props.modelid]);

	useEffect(() => {
		const timeFromLastExecution = Date.now() - lastExecutionTimestamp;
		if (programRunning || timeFromLastExecution < 500 || isSelectionChange) {
			setIsSelectionChange(false);
			return;
		}

		console.log("Setting has changed to true");
		if (hasChanged === true) {
			setHasChanged(false);
		}

		setHasChanged(true);
		// updateModel
	}, [storeThreads, storeBrokers, storeNodes, initialModelLoaded, tabs]);

	useEffect(() => {
		if (hasChanged === false) {
			console.log("... already false");
			return;
		}

		// stop previous save timer if exists
		if (autoSaveTimerId) {
			clearTimeout(autoSaveTimerId);
			console.log("Auto save timer cleared");
		}

		// set new auto save timer
		const newTimeout = setTimeout(updateModel, 500);
		setHasChanged(false);
		setAutoSaveTimerId(newTimeout);

		// Auto chage the hasChanged to false after 1 second
		setTimeout(() => {
			console.log("___ Auto save timer executed ___");
			setHasChanged(false);
		}, 1000);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasChanged]);

	const onConnect = useCallback(
		async (params) => {
			const edgeExists = edges.some(
				(edge) => edge.source === params.source && edge.target === params.target
			);

			if (edgeExists) {
				return;
			}

			params.type = edgeStyle;
			params.markerEnd = { type: MarkerType.ArrowClosed };

			setTabs((prevTabs) =>
				prevTabs.map((tab) =>
					tab.id === activeTab
						? {
							...tab,
							edges: addEdge(params, tab.edges),
						}
						: tab));
		},
		[edges, setTabs, edgeStyle, activeTab]
	);

	const onNodesChange = useCallback(
		async (changes) => {
			setTabs((prevTabs) =>
				prevTabs.map((tab) =>
					tab.id === activeTab
						? { ...tab, nodes: applyNodeChanges(changes, tab.nodes) }
						: tab));
		},
		[activeTab, tabs]
	);

	const onEdgesChange = useCallback(
		async (changes) => {
			setTabs((prevTabs) =>
				prevTabs.map((tab) =>
					tab.id === activeTab
						? {
							...tab,
							edges: applyEdgeChanges(changes, tab.edges),
						}
						: tab));
		},
		[setTabs, activeTab]
	);

	const onNodeDrag = useCallback(
		(_, draggedNode) => {
			if (!isProximityEnabled) return;
			const { id: draggedNodeId, position } = draggedNode;

			let closestNode = null;
			let minDistance = PROXIMITY_THRESHOLD;

			for (const node of nodes) {
				if (node.id !== draggedNodeId) {
					const dx = node.position.x - position.x;
					const dy = node.position.y - position.y;
					const distance = Math.sqrt(dx * dx + dy * dy);

					if (distance < minDistance) {
						minDistance = distance;
						closestNode = node;
					}
				}
			}

			if (closestNode) {
				const edgeExists = edges.some(
					(edge) => (edge.source === draggedNodeId && edge.target === closestNode.id)
						|| (edge.source === closestNode.id && edge.target === draggedNodeId),
				);

				if (edgeExists || (draggedNode.data.label === "End" && closestNode.data.label === "End") || (draggedNode.data.label === "Start" && closestNode.data.label === "Start")) {
					setGhostEdge(null);
				} else {
					let closeNodeIsSource = false;

					const draggedIsLeftRight = draggedNode.data.label === "Thread split"
						|| draggedNode.data.label === "Thread join"
						|| draggedNode.data.label === "Condition";

					const closestIsLeftRight = closestNode.data.label === "Thread split"
						|| closestNode.data.label === "Thread join"
						|| closestNode.data.label === "Condition";

					const draggedIsTopBottom = !draggedIsLeftRight;
					const closestIsTopBottom = !closestIsLeftRight;

					if (draggedIsTopBottom && closestIsTopBottom) {
						// Both nodes have top-bottom orientation
						closeNodeIsSource = closestNode.position.y < draggedNode.position.y;
					} else if (draggedIsLeftRight && closestIsLeftRight) {
						// Both nodes have left-right orientation
						closeNodeIsSource = closestNode.position.x < draggedNode.position.x;
					} else if (draggedIsLeftRight && closestIsTopBottom) {
						// Left-right dragged node connecting to top-bottom node
						closeNodeIsSource = closestNode.position.x < draggedNode.position.x;
					} else if (draggedIsTopBottom && closestIsLeftRight) {
						// Top-bottom dragged node connecting to left-right node
						closeNodeIsSource = closestNode.position.y < draggedNode.position.y;
					}

					setGhostEdge({
						id: `ghost-${draggedNodeId}-${closestNode.id}`,
						source: closeNodeIsSource ? closestNode.id : draggedNodeId,
						target: closeNodeIsSource ? draggedNodeId : closestNode.id,
						type: edgeStyle,
						animated: true,
						style: { stroke: GHOST_EDGE_COLOR, strokeWidth: 2 },
						sourceHandle: "out_0",
						targetHandle: "in_0",
						markerEnd: { type: MarkerType.ArrowClosed },
					});
				}
			} else {
				setGhostEdge(null);
			}
		}, [nodes, edges, edgeStyle, isProximityEnabled, tabs],
	);

	const onNodeDragStop = useCallback(
		() => {
			let newTabs = [];
			if (ghostEdge) {
				newTabs = tabs.map((tab) =>
					tab.id === activeTab
						? {
							...tab,
							edges: addEdge(
								{ ...ghostEdge, animated: false, type: edgeStyle },
								tab.edges
							),
						}
						: tab);
				setTabs(newTabs);
				setModelStateArray((prevState) => [
					...prevState,
					newTabs,
				]);
			} else {
				setModelStateArray((prevState) => [
					...prevState,
					tabs,
				]);
			}

			setGhostEdge(null);
		},
		[ghostEdge, tabs, edgeStyle, activeTab]
	);

	useEffect(() => {
		const lastModelStateTabObj = modelStateArray.at(-1)?.find((t) => t.id === activeTab);
		const activeTabObj = tabs.find((t) => t.id === activeTab);

		const arraysAreEqual = (compArr, changedArr) => {
			if (compArr?.length !== changedArr?.length) return false;
			return compArr.every((node, index) => node?.id === changedArr[index]?.id);
		};

		const nodesChanged = !lastModelStateTabObj || !arraysAreEqual(lastModelStateTabObj?.nodes, activeTabObj?.nodes);
		const edgesChanged = activeTabObj?.nodes.length > 0
			&& (!lastModelStateTabObj || !arraysAreEqual(lastModelStateTabObj?.edges, activeTabObj?.edges));

		if ((nodesChanged || edgesChanged) && !(nodes.length === 0 && edges.length === 0)) {
			setModelStateArray((prevState) => [
				...prevState,
				tabs,
			]);
		}
	}, [tabs]);

	const applyErrorsToNodes = (nodes, errors) => {
		if (errors) {
			return nodes.map((node) => {
				const nodeErrors = errors?.payload?.filter((error) => error.id === node.data.count);

				return {
					...node,
					data: {
						...node.data,
						error: nodeErrors?.length > 0 ? nodeErrors.map((err) => err.message) : [],
					},
					className: nodeErrors?.length > 0 ? 'node-error' : '',
				};
			});
		}

		return nodes;
	};

	// Example usage in state update
	useEffect(() => {
		setIsFromValidation(true);
		setTabs((prevTabs) =>
			prevTabs.map((tab) => ({
				...tab,
				nodes: applyErrorsToNodes(tab.nodes, validationErrors),
			})));
	}, [validationErrors]);

	useEffect(() => {
		(async () => {
			if (props?.dbitem?.origin && props.dbitem.origin !== "" && envpopModel === null) {
				console.log("Fetching model from", props.dbitem.origin);
				const { success: scs, model: mo } = await getModel(props.dbitem.origin);
				if (scs) {
					setEnvpopModel(mo);
				}
			}
		})();
	}, [props.dbitem, props.model]);

	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	}, []);

	const onNodesDelete = useCallback(
		async (nodesToDelete) => {
			for (const node of nodesToDelete) {
				setVariables(variables.filter((v) => v.nodeId !== node));
				dispatch(deleteVariable({ id: node.id }));

				setLists(lists.filter((l) => l.nodeId !== node));
				dispatch(deleteList({ id: node.id }));

				dispatch(deleteThread({ id: node.id }));
				dispatch(deleteNodeParameters({ id: node.id }));
			}

			setTabs((prevTabs) =>
				prevTabs.map((tab) =>
					tab.id === activeTab
						? {
							...tab,
							nodes: tab.nodes.filter((n) => !nodesToDelete.includes(n.id)),
							edges: tab.edges.filter(
								(e) => !nodesToDelete.includes(e.source) && !nodesToDelete.includes(e.target)
							),
						}
						: tab));
		},
		[dispatch, variables, lists, activeTab]
	);

	const onDrop = useCallback(
		async (event) => {
			event.preventDefault();

			const node = JSON.parse(event.dataTransfer.getData("application/reactflow"));

			// Ensure node type is defined
			if (!node.type) {
				node.type = "default";
			}

			const position = reactFlowInstance.screenToFlowPosition({
				x: event.clientX,
				y: event.clientY,
			});

			// Types mapping
			const typesMapping = {
				"Thread split": "thread",
				"Thread join": "thread",
				Condition: "condition",
				"Random selection": "random",
				Comment: "comment",
			};

			const id = Math.random().toString();
			const newNode = {
				id,
				type: typesMapping[node.name] ?? "custom",
				sourcePosition: "right",
				targetPosition: "left",
				position,
				data: {
					id,
					label: node.name,
					inputs: node.inputs,
					outputs: node.outputs,
					backgroundColor: node.backgroundColor,
					toolboxColor: node.toolboxColor,
					fontColor: node.fontColor,
					toolbox: node.toolbox,
					parameters: node.parameters,
					action: node.action,
					count: counter + 1,
				},
			};

			if (node.name === "Create variable") {
				newNode.data.parameters[0].value = `variable_${counter + 1}`;
			}

			if (node.name === "Create List") {
				newNode.data.parameters[0].value = `list_${counter + 1}`;
				await dispatch(
					addList({
						id: newNode.data.id,
						data: newNode.data,
						parameters: node.parameters,
					})
				);
				await setLists((lsts) => [
					...lsts,
					{
						nodeId: newNode.id,
						list: newNode.data.parameters[0].value,
						data: newNode,
						count: counter + 1,
						value: newNode.data.parameters[1].value,
						literalVariables: [newNode.data.parameters[0].value],
					},
				]);
			}

			// This happens for nodes with variable inputs/outputs but no parameters
			const toDispatch = {
				id,
				parameters: node.parameters ?? [],
			};
			if (["Thread split", "Thread join", "Random"].includes(newNode.data.label)) {
				toDispatch.parameters = newNode.data;
			}

			await dispatch(addNodeParameters(toDispatch));

			// Increment the counter
			await setCounter((prevCount) => prevCount + 1);

			if (newNode.data.label === "Create variable") {
				await dispatch(
					addVariable({
						id: newNode.data.id,
						data: newNode.data,
						parameters: node.parameters,
					})
				);

				await setVariables((vars) => [
					...vars,
					{
						nodeId: newNode.id,
						variable: newNode.data.parameters[0].value,
						data: newNode,
						count: counter + 1,
						value: newNode.data.parameters[1].value,
						literalVariables: [newNode.data.parameters[0].value],
					},
				]);
			}

			if (newNode.data.label === "Thread split") {
				await dispatch(
					addThread({
						id: newNode.data.id,
						data: newNode.data,
						parameters: node.parameters,
					})
				);
			}

			setTabs((prevTabs) =>
				prevTabs.map((tab) =>
					tab.id === activeTab ? { ...tab, nodes: [...tab.nodes, newNode] } : tab));
		},
		[reactFlowInstance, counter, activeTab]
	);
	useEffect(() => {
		const activeTabData = tabs.find((tab) => tab.id === activeTab);

		if (activeTabData) {
			setNodes(activeTabData.nodes);
			setEdges(activeTabData.edges);
		}
	}, [activeTab, tabs]);

	const onNodeClick = (_, node) => {
		setIsSelectionChange(true);
		setSelectedNode(node.id);
		setHighlightedEdges(null);

		const connectedEdges = edges.filter(
			(edge) => edge.source === node.id || edge.target === node.id,
		);
		setHighlightedEdges(connectedEdges.map((edge) => edge.id));
	};

	const onEdgeClick = (_, edge) => {
		setIsSelectionChange(true);
		setSelectedNode(null);
		setHighlightedEdges([edge.id]);
	};

	const handleEdgeStyleChange = (event) => {
		const selectedStyle = event.target.value;
		setEdgeStyle(selectedStyle);
		updateEdgeTypes(selectedStyle);
	};

	const handleClearLogs = () => {
		dispatch(cleanLogs());
	};

	const envpopResize = () => {
		setEnvpopSize(envpopSize === "small" ? "large" : "small");
		if (envpopRef.current) {
			envpopRef.current.onResize();
		}
	};

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const groupByDevice = (dataArray) => dataArray.reduce((acc, item) => {
		const [entity] = item.split('.');
		acc[entity] = acc[entity] || [];
		acc[entity].push(item);
		return acc;
	}, {});

	useEffect(() => {
		setInSimulation(props.inSimulation);
		if (!props.inSimulation) {
			setRunningNodes([]);
			setProgramRunning(false);
		}
	}, [props.inSimulation]);

	const handleUndo = () => {
		const previousToLastElement = modelStateArray.at(-2);

		const lengthOfTabsBeforeUndo = tabs?.length;
		setModelStateArray((prevState) => prevState.slice(0, -1));
		setTabs(previousToLastElement);
		const lengthOfTabsAfterUndo = previousToLastElement?.length;
		if (lengthOfTabsAfterUndo < lengthOfTabsBeforeUndo) {
			setActiveTab(0);
		}
	};

	const renameTabFormContent = [
		{
			customType: "wideInput",
			id: "label",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Changed tab name",
			value: tabToRename?.label,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Submit",
		},
	];

	const handleRenameTab = async (values) => {
		await dispatch(updateTab({
			id: tabToRename.id,
			name: values.label,
		}));
		setTabs((prevTabs) => prevTabs.map((tab) => (tab.id === tabToRename.id ? { ...tab, label: values.label } : tab)));
		setRenameTabPopup(false);
	};

	return (
		<>
			<Spinner open={isLoading} />
			<AreYouSurePopup
				open={clearAllPopup}
				title="Clear application"
				content="Are you sure you want to clear all nodes and connections of your application?"
				onAccept={async () => {
					setTabs([{ id: 0, label: "Main Tab", nodes: [], edges: [] }]);
					setModelStateArray((prevState) => [
						...prevState,
						[{ id: 0, label: "Main Tab", nodes: [], edges: [] }],
					]);
					setActiveTab(0);
					setVariables([]);
					setLists([]);
					setCustomLists([]);
					await dispatch(setListParameters([]));
					await dispatch(setVariableParameters([]));
					await dispatch(setThreads([]));
					await dispatch(setBrokers([]));
					await dispatch(setNodesParameters([]));
					await dispatch(setTabParameters([{ id: 0, label: "Main Tab", nodes: [], edges: [] }]));
					await dispatch(cleanLogs());
					setClearAllPopup(false);
					success("Application cleared successfully. Let's hope you didn't do it by mistake!");
				}}
				onDecline={() => setClearAllPopup(false)}
			/>
			<AreYouSurePopup
				open={clearTabPopup}
				title="Clear tab"
				content="Are you sure you want to clear all nodes and connections of this tab?"
				onAccept={async () => {
					const nodesToBeDeleted = tabs.find((tab) => tab.id === activeTab).nodes.map((node) => node.id);

					setTabs((prevTabs) =>
						prevTabs.map((tab) =>
							tab.id === activeTab ? { ...tab, nodes: [], edges: [] } : tab));

					for (const node of nodesToBeDeleted) {
						setVariables(variables.filter((v) => v.nodeId !== node));
						dispatch(deleteVariable({ id: node }));

						setLists(lists.filter((l) => l.nodeId !== node));
						dispatch(deleteList({ id: node }));

						dispatch(deleteThread({ id: node }));
						dispatch(deleteNodeParameters({ id: node }));
					}

					setVariables([]);
					setLists([]);
					setCustomLists([]);
					await dispatch(setBrokers([]));
					await dispatch(cleanLogs());
					setClearTabPopup(false);
					success("Tab cleared successfully. Let's hope you didn't do it by mistake!");
				}}
				onDecline={() => setClearTabPopup(false)}
			/>
			<AreYouSurePopup
				open={removeTabPopup}
				title="Delete tab"
				content="Are you sure you want to delete the tab?"
				onAccept={() => handleRemoveTab()}
				onDecline={() => setRemoveTabPopup(false)}
			/>
			<Popup
				width="800px"
				open={renameTabPopup}
				title="Rename tab"
				onClose={() => {
					setRenameTabPopup(false);
				}}
			>
				<Form
					content={renameTabFormContent}
					onSubmit={handleRenameTab}
				/>
			</Popup>
			<Grid
				item
				container
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				height="100%"
			>
				{!previewMode && (
					<Grid
						item
						container
						xs={2}
						display="flex"
						direction="row"
						alignContent="flex-start"
						justifyContent="center"
						overflow="auto"
						height={`calc(100vh - ${maxDSLScreen ? 120 : 270}px)`}
						p={1}
						pt={0}
						sx={{
							borderTopLeftRadius: "20px",
							borderBottomLeftRadius: "20px",
							backgroundColor: "primary.main",
						}}
					>
						<Grid
							item
							display="flex"
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							style={{
								width: "100%",
								marginBottom: "10px",
							}}
						>
							<Tooltip
								title="Undo"
								sx={{
									cursor: "pointer",
								}}
								onClick={() => {
									if (!(modelStateArray.length === 1)) {
										handleUndo();
									}
								}}
							>
								<img
									src={undoIcon}
									style={{ width: 24, height: 24, marginRight: 8 }}
									alt="Undo"
								/>
							</Tooltip>
							<Tooltip
								title="Clear All"
								sx={{
									cursor: "pointer",
								}}
								onClick={() => {
									if (!(tabs?.length === 1 && tabs[0]?.nodes.length === 0 && tabs[0]?.edges.length === 0)) {
										setClearAllPopup(true);
									}
								}}
							>
								<img
									src={clearAllIcon}
									alt="Clear All"
									style={{ width: 24, height: 24, marginRight: 8 }}
								/>
							</Tooltip>
							<Tooltip
								title="Clear Tab"
								sx={{
									cursor: "pointer",
								}}
								onClick={() => {
									if (!(tabs?.find((tab) => tab.id === activeTab)?.nodes.length === 0)) {
										setClearTabPopup(true);
									}
								}}
							>
								<img
									src={clearTabIcon}
									style={{ width: 24, height: 24, marginRight: 8 }}
									alt="Clear Tab"
								/>
							</Tooltip>
							<Tooltip
								title="Copy Tab"
								sx={{
									cursor: "pointer",
								}}
								onClick={() => {
									// Copy tab contents to clipboard
									const tabToCopy = tabs.find((tab) => tab.id === activeTab);
									const tabToCopyString = JSON.stringify(tabToCopy);
									navigator.clipboard.writeText(tabToCopyString);
									success("Tab copied to clipboard");
								}}
							>
								<img
									src={copyIcon}
									style={{ width: 24, height: 24, marginRight: 8 }}
									alt="Copy Tab"
								/>
							</Tooltip>
							<Tooltip
								title="Create tab and paste"
								sx={{
									cursor: "pointer",
								}}
								onClick={() => {
									// Create a tab and paste the copied tab contents
									navigator.clipboard.readText().then((text) => {
										const copiedTab = JSON.parse(text);
										let newCounter = counter;
										const timestamp = Date.now().toString().slice(-5);
										const nodeIdMap = {};
										const newNodes = copiedTab.nodes.map((node) => {
											newCounter += 1;
											const newId = `0.${timestamp}${newCounter}${timestamp}${newCounter}`;
											nodeIdMap[node.id] = newId;

											return {
												...node,
												id: newId,
												data: { ...node.data, id: newId, count: newCounter },
											};
										});

										const newEdges = copiedTab.edges.map((edge) => {
											const newSource = nodeIdMap[edge.source] || edge.source;
											const newTarget = nodeIdMap[edge.target] || edge.target;
											return {
												...edge,
												id: `reactflow__edge-${newSource}out_0-${newTarget}in_0`,
												source: newSource,
												target: newTarget,
											};
										});

										const newTab = {
											id: tabCounter,
											label: `${copiedTab.label} (copy)`,
											nodes: newNodes,
											edges: newEdges,
										};
										setCounter(newCounter);
										setTabs([...tabs, newTab]);
										dispatch(addTab(newTab));
										setActiveTab(newTab?.id);
										setTabCounter(tabCounter + 1);
										success("Tab pasted successfully");
									});
								}}
							>
								<img
									src={pasteIcon}
									style={{ width: 24, height: 24, marginRight: 8 }}
									alt="Paste Tab"
								/>
							</Tooltip>
						</Grid>
						<Grid
							item
							display="flex"
							flexDirection="row"
							justifyContent="flex-start"
							alignItems="center"
							style={{
								width: "100%",
								marginBottom: "10px",
								color: "white",
							}}
						>
							<Typography
								style={{
									fontSize: "14px",
									fontWeight: "bold",
									padding: "5px",
									margin: "1px",
									marginTop: "5px",
									borderRadius: "5px",
								}}
							>
								{"Edge Style"}
							</Typography>
							<Select
								value={edgeStyle}
								sx={{
									width: "60%",
									color: "white",
									fontSize: "12px",
									"& .MuiSelect-icon": {
										color: "white !important",
									},
								}}
								MenuProps={{
									fontSize: "10px",
									PaperProps: {
										style: {
											backgroundColor: "white",
											color: "black",
											fontSize: "10px",
										},
									},
								}}
								onChange={(event) => {
									handleEdgeStyleChange(event);
								}}
							>
								<MenuItem value="default">{"Default"}</MenuItem>
								<MenuItem value="straight">{"Straight"}</MenuItem>
								<MenuItem value="step">{"Step"}</MenuItem>
								<MenuItem value="smoothstep">{"Smoothstep"}</MenuItem>
							</Select>
						</Grid>
						{/* Toggle Button for Proximity */}
						{/* <Grid
							item
							display="flex"
							flexDirection="row"
							justifyContent="flex-start"
							alignItems="center"
							style={{
								width: "100%",
								marginBottom: "10px",
								color: "white",
							}}
						>
							<Typography
								style={{
									width: "100%",
									fontSize: "14px",
									fontWeight: "bold",
									padding: "5px",
									margin: "1px",
									marginTop: "5px",
									borderRadius: "5px",
								}}
							>
								{"Proximity Connection"}
							</Typography>
							<Switch
								checked={isProximityEnabled}
								sx={{
									'& .MuiSwitch-switchBase.Mui-checked': {
										color: '#03DAC5',
									},
									'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
										backgroundColor: '#03DAC5',
									},
									'& .MuiSwitch-switchBase': {
										color: '#04598c',
									},
									'& .MuiSwitch-switchBase + .MuiSwitch-track': {
										backgroundColor: '#BAC1C5',
									},
								}}
								onChange={() => setIsProximityEnabled((prev) => !prev)}
							/>

						</Grid> */}
						{/* Parse all toolboxes and nodes */}
						{toolboxes.map((toolbox) => (
							<Grid
								key={toolbox.name}
								item
								display="flex"
								flexDirection="column"
								justifyContent="flex-start"
								alignItems="center"
								style={{
									width: "100%",
									marginBottom: "0px",
									color: "white",
								}}
							>
								<Typography
									style={{
										width: "100%",
										color: toolbox.fontColor ?? "black",
										fontSize: "15px",
										fontWeight: "bold",
										padding: "5px",
										margin: "1px",
										marginTop: "5px",
										borderRadius: "5px",
									}}
								>
									{toolbox.name}
								</Typography>
								{toolbox.nodes.map((node) => (
									<Grid
										key={node.name}
										item
										width="100%"
										flexDirection="row"
										justifyContent="space-between"
										alignItems="center"
										display={node.action && node.action.type === "subscribe" ? "none" : "flex"}
									>
										<Typography
											key={node.name}
											draggable
											style={{
												width: "calc(100% - 20px)",
												backgroundColor: toolbox.backgroundColor ?? "#fff",
												toolboxColor: toolbox.backgroundColor ?? "black",
												color: toolbox.fontColor ?? "black",
												border: "1px solid black",
												padding: "1px",
												margin: "1px",
												borderRadius: "5px",
												cursor: "pointer",
												fontSize: "0.8em",
											}}
											textAlign="center"
											onDragStart={(event) => {
												node.toolbox = toolbox.name;
												node.backgroundColor = toolbox.backgroundColor;
												node.toolboxColor = toolbox.backgroundColor;
												node.fontColor = toolbox.fontColor;
												onDragStart(event, node);
											}}
										>
											{node.name}
										</Typography>
										<IconButton
											aria-label="clone model"
											disabled={!node.documentation}
											sx={{
												margin: "0px 5px",
												width: "10px",
												height: "10px",
											}}
											onClick={() => {
												// Check if this node exists in the internalSystemToolboxes and replace the documentation
												const internalNode = internalSystemToolboxes.find((n) => n.name === node.name);
												if (internalNode) {
													node.documentation = internalNode.documentation;
												}

												setNodeTooltip(node);
											}}
										>
											<Info
												sx={{
													color: node.documentation ? "third.main" : "#333 !important",
												}}
											/>
										</IconButton>
									</Grid>
								))}
							</Grid>
						))}
					</Grid>
				)}
				<Grid
					item
					container
					xs={previewMode ? 12 : 7.5}
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					sx={{
						backgroundColor: "#0D192B",
						border: "1px solid #0D192B",
						borderRadius: "20px",
					}}
				>
					{/* Tabs */}
					<Box sx={{ width: "100%", bgcolor: "#193256", color: "#abc7d9" }}>
						<Tabs
							value={activeTab}
							variant="scrollable"
							scrollButtons="auto"
							textColor="inherit"
							indicatorColor="primary"
							sx={{
								backgroundColor: "#0D192B",
								"& .MuiTabs-indicator": {
									backgroundColor: "#0D192B",
								},
								"& .MuiTab-root": {
									color: "#03DAC5",
									"&.Mui-selected": {
										color: "#03DAC5",
									},
								},
							}}
							onChange={(_, newValue) => setActiveTab(newValue)}
						>
							{tabs?.map((tab) => (
								<Tab
									key={tab.id}
									label={(
										<Box
											display="flex"
											alignItems="center"
											sx={{
												borderRadius: "20px",
												border: "1px solid #193256",
												backgroundColor: activeTab === tab.id ? "#193256" : "#0D192B",
												color: activeTab === tab.id ? "#03DAC5" : "#abc7d9",
												px: "10px",
												py: "3px",
											}}
										>
											{tab.label}
											{tab.id !== 0 && (
												<>
													<IconButton
														size="small"
														sx={{
															ml: 0.5,
															p: 0.5,
															fontSize: "0.5rem",
															color: "#7BB8D1",
															"&:hover": { color: "#04598c" },
														}}
														onClick={(e) => {
															e.stopPropagation();
															setTabToRename(tab);
															setRenameTabPopup(true);
														}}
													>
														<EditIcon fontSize="small" />
													</IconButton>
													<IconButton
														size="small"
														sx={{
															ml: 0.5,
															p: 0.5,
															fontSize: "0.5rem",
															color: "#7BB8D1",
															"&:hover": { color: "#04598c" },
														}}
														onClick={(e) => {
															e.stopPropagation();
															handleDuplicateTab(tab.id);
														}}
													>
														<ContentCopyIcon fontSize="small" />
													</IconButton>
													<IconButton
														size="small"
														sx={{
															ml: 0.5,
															p: 0.5,
															fontSize: "0.5rem",
															color: "#7BB8D1",
															"&:hover": { color: "#04598c" },
														}}
														onClick={(e) => {
															e.stopPropagation();
															setTabToRemove(tab.id);
															setRemoveTabPopup(true);
														}}
													>
														<Close fontSize="small" />
													</IconButton>
												</>
											)}
										</Box>
									)}
									value={tab.id}
								/>
							))}
							<IconButton
								sx={{
									color: "#7BB8D1",
									"&:hover": { color: "#04598c" },
								}}
								onClick={handleAddTab}
							>
								<Add />
							</IconButton>
						</Tabs>
					</Box>
					{/* ReactFlow Instance */}
					<ReactFlowProvider>
						<div
							ref={reactFlowWrapper}
							className="reactflow-wrapper"
							style={{
								width: '100%',
								height: `calc(100vh - ${maxDSLScreen ? 140 : 300}px)`,
							}}
						>
							{tabs?.map(
								(tab) =>
									tab.id === activeTab && (
										<ReactFlow
											key={tab.id}
											style={{
												background: "black"
											}}
											elementsSelectable={!previewMode}
											nodesDraggable={!previewMode}
											nodesConnectable={!previewMode}
											edgesFocusable={!previewMode}
											interactionMode={previewMode ? "zoom" : "pan"}
											nodes={tab?.nodes}
											edges={tab?.edges ? [...tab?.edges, ghostEdge] : tab?.edges}
											nodeTypes={nodeTypes}
											onInit={setReactFlowInstance}
											onNodeClick={previewMode ? undefined : onNodeClick}
											onEdgeClick={previewMode ? undefined : onEdgeClick}
											onNodesChange={previewMode ? undefined : onNodesChange}
											onNodesDelete={previewMode ? undefined : onNodesDelete}
											onEdgesChange={previewMode ? undefined : onEdgesChange}
											onConnect={previewMode ? undefined : onConnect}
											onDrop={previewMode ? undefined : onDrop}
											onDragOver={previewMode ? undefined : onDragOver}
											onNodeDrag={previewMode ? undefined : onNodeDrag}
											onNodeDragStop={previewMode ? undefined : onNodeDragStop}
										>
											<MiniMap
												pannable
												zoomable
												nodeStrokeWidth={3}
												position="bottom-left"
												maskColor="rgba(0,0,0,0.5)"
												nodeColor="rgba(150,0,0,0.5)"
											/>
											{!previewMode && <Controls position="bottom-right" />}
											<Background variant="dots" gap={12} size={1} />
										</ReactFlow>
									)
							)}
						</div>
					</ReactFlowProvider>
				</Grid>
				{!previewMode && (
					<Grid
						item
						container
						xs={2.5}
						display="flex"
						direction="row"
						alignContent="flex-start"
						justifyContent="flex-start"
						overflow="auto"
						height={`calc(100vh - ${maxDSLScreen ? 80 : 270}px)`}
						p={2}
						sx={{
							borderTopRightRadius: "20px",
							borderBottomRightRadius: "20px",
							backgroundColor: "#0D192B",
						}}
					>
						<Grid
							item
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="space-between"
							style={{
								width: "100%",
								marginBottom: "10px",
							}}
						>
							<Typography
								variant="body2"
								sx={{
									m: 0.5,
									color: "white !important",
									fontSize: "12px",
									display: "flex",
									alignItems: "center",
									gap: 1,
								}}
							>
								{`AppCreator deployer is ${aliveDeployer ? "up" : "down"}`}
								<Box
									sx={{
										width: 13,
										height: 13,
										borderRadius: "100%",
										backgroundColor: aliveDeployer ? "#179794" : "#800F13",
										ml: 1,
									}}
								/>
							</Typography>
						</Grid>
						{validationErrors?.payload?.length > 0 && (
							<Grid
								item
								display="flex"
								flexDirection="column"
								justifyContent="flex-start"
								alignItems="center"
								sx={{
									width: "100%",
									marginBottom: "10px",
									color: "primary.main",
									backgroundColor: "third.main",
									minHeight: "20%",
									borderRadius: "10px",
								}}
							>
								<Grid item width="100%" sx={{ mb: "0.5rem" }}>
									<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
										<Typography sx={{ color: "error.main", fontSize: "13px", fontWeight: "bold", ml: "1rem", mt: "0.5rem" }}>
											{"Validation Errors"}
										</Typography>
									</Grid>
									{validationErrors.payload.map((err) => (
										<Typography
											key={Math.random()}
											sx={{
												color: "error.main",
												fontSize: "0.8rem",
												marginTop: "2px",
												ml: "1rem",
											}}
										>
											{err?.id
												? `[${err?.tab} - Node ${err?.id}]: "${err.message}"`
												: `[${err?.tab}]: "${err.message}"`}
										</Typography>
									))}
								</Grid>
							</Grid>
						)}
						<Grid
							item
							display="flex"
							flexDirection="column"
							justifyContent="flex-start"
							alignItems="center"
							sx={{
								width: "100%",
								marginBottom: "10px",
								color: "primary.main",
								backgroundColor: "third.main",
								minHeight: "20%",
								borderRadius: "10px",
							}}
						>
							<Grid item container flexDirection="column" sx={{ width: "100%", mb: "0.5rem", mt: "0.5rem" }}>
								<TreeView
									aria-label="Live Variables"
									defaultCollapseIcon={(
										<IconButton
											size="small"
										>
											<ExpandLess fontSize="small" />
										</IconButton>
									)}
									defaultExpandIcon={(
										<IconButton
											size="small"
										>
											<ExpandMore fontSize="small" />
										</IconButton>
									)}
									defaultExpanded={["header"]}
									style={{ fontSize: '0.75rem', width: '95%' }}
								>
									<TreeItem
										nodeId="header"
										label={(
											<div style={{
												display: 'flex',
												alignItems: 'center',
												height: '40px',
												width: '100%',
												justifyContent: 'flex-start',
												paddingLeft: '0px',
											}}
											>
												<Typography
													variant="h6"
													sx={{ fontSize: "14px", fontWeight: 'bold', ml: 0 }}
												>
													{`Live Variables [${Object.keys(liveVariables)?.length || 0} items]`}
												</Typography>
											</div>
										)}
									>
										<Grid item container flexDirection="column" sx={{ width: "100%", mb: 1 }}>
											{Object.entries(liveVariables).map(([key, value]) => (
												<TreeItem
													key={key}
													nodeId={String(key + 1)}
													label={(
														<div>
															<Typography
																noWrap
																variant="caption"
																sx={{
																	color: 'black',
																	fontSize: '13px',
																	wordBreak: 'break-word',
																	overflowWrap: 'break-word',
																	whiteSpace: 'normal',
																}}
															>
																<span style={{ fontWeight: 'bold' }}>{key}</span>
																{" : "}
																{
																	Array.isArray(value) || typeof value === 'string'
																		? `[${value.slice(0, 20).join ? value.slice(0, 20).join(", ") : value.slice(0, 20)}${value.length > 20 ? ", ..." : ""}]`
																		: JSON.stringify(value)
																}
															</Typography>
														</div>
													)}
												/>
											))}
										</Grid>
									</TreeItem>
								</TreeView>
							</Grid>
							<Divider
								sx={{
									backgroundColor: "primary.main",
									width: "95%",
								}}
							/>
							{goaldslUpdate && (
								<Grid item width="100%">
									<Typography
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontWeight: "bold",
											fontSize: "16px",
											marginTop: "0.5rem",
											color: "black"
										}}
									>
										<span style={{ color: "black", marginRight: 5 }}>{"Success:"}</span>
										<span style={{ color: goaldslUpdate?.data?.score > 0.999 ? "#179794" : "#800F13" }}>
											{`${(goaldslUpdate?.data?.score * 100).toFixed(2)}%`}
										</span>
									</Typography>
									{/* Goals */}
									{goaldslUpdate?.data?.goals?.length > 0 && (
										<Typography
											sx={{
												color: "black",
												fontSize: "13px",
												fontWeight: "bold",
												marginTop: "0.5rem",
												marginLeft: "1rem"
											}}
										>
											{"Goals"}
										</Typography>
									)}
									{goaldslUpdate?.data?.goals?.map((goal) => (
										<Grid
											key={goal.name}
											container
											alignItems="center"
											sx={{ mt: '0.2rem', wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}
										>
											<Typography
												key={goal.name}
												sx={{
													color: "black",
													fontSize: "0.8rem",
													marginTop: "2px",
													ml: "2rem",
													mr: "0.5rem"
												}}
											>
												{`- ${goal.name}`}
											</Typography>
											{goal.state === "COMPLETED" ? (
												<CheckCircleIcon sx={{ color: "#179794", fontSize: 20 }} />
											) : (
												<CancelIcon sx={{ color: "#800F13", fontSize: 20 }} />
											)}
										</Grid>
									))}
									{/* Antigoals */}
									{goaldslUpdate?.data?.anti_goals?.length > 0 && (
										<Typography
											sx={{
												color: "black",
												fontSize: "13px",
												fontWeight: "bold",
												marginTop: "0.5rem",
												marginLeft: "1rem"
											}}
										>
											{"Anti-goals"}
										</Typography>
									)}
									{goaldslUpdate?.data?.anti_goals?.map((goal) => (
										<Grid
											key={goal.name}
											container
											alignItems="center"
											sx={{ mt: '0.2rem', wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}
										>
											<Typography
												key={goal.name}
												sx={{
													color: "black",
													fontSize: "0.8rem",
													marginTop: "2px",
													ml: "2rem",
													mr: "0.5rem"
												}}
											>
												{`- ${goal.name}`}
											</Typography>
											{goal.state === "COMPLETED" ? (
												<CheckCircleIcon sx={{ color: "#179794", fontSize: 20 }} />
											) : (
												<CancelIcon sx={{ color: "#800F13", fontSize: 20 }} />
											)}
										</Grid>
									))}
									{/* Fatal goals */}
									{goaldslUpdate?.data?.fatal_goals?.length > 0 && (
										<Typography
											sx={{
												color: "black",
												fontSize: "13px",
												fontWeight: "bold",
												marginTop: "0.5rem",
												marginLeft: "1rem"
											}}
										>
											{"Fatals"}
										</Typography>
									)}
									{goaldslUpdate?.data?.fatal_goals?.map((goal) => (
										<Grid
											key={goal.name}
											container
											alignItems="center"
											sx={{ mt: '0.2rem', wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}
										>
											<Typography
												key={goal.name}
												sx={{
													color: "black",
													fontSize: "0.8rem",
													marginTop: "2px",
													ml: "2rem",
													mr: "0.5rem"
												}}
											>
												{`- ${goal.name}`}
											</Typography>
											{goal.state === "COMPLETED" ? (
												<CheckCircleIcon sx={{ color: "#179794", fontSize: 20 }} />
											) : (
												<CancelIcon sx={{ color: "#800F13", fontSize: 20 }} />
											)}
										</Grid>
									))}

									<Divider
										sx={{
											margin: "5px 0px",
											backgroundColor: "primary.main",
											width: "95%",
											ml: "2.5%"
										}}
									/>
								</Grid>
							)}

							<Grid item width="100%" sx={{ mt: "0.5rem", mb: "0.5rem" }}>
								<TreeView
									defaultExpandIcon={<ExpandMore />}
									defaultCollapseIcon={<ExpandLess />}
									defaultExpanded={["logs-root"]}
								>
									<TreeItem
										nodeId="logs-root"
										label={(
											<div style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between',
												width: '100%'
											}}
											>
												<Typography sx={{ fontSize: "13px", fontWeight: "bold", color: "black" }}>
													{"Application Logs"}
												</Typography>
												{storeLogs.length > 0 && (
													<IconButton
														sx={{
															padding: 1,
															'&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
														}}
														onClick={handleClearLogs}
													>
														<Delete sx={{ fontSize: 20, color: "primary.main" }} />
													</IconButton>
												)}
											</div>
										)}
									>
										{storeLogs.length > 0 ? (
											[...storeLogs].sort(sortLogs).map((log, index) => (
												<Typography
													key={`log-${index}`}
													sx={{
														color: "black!important",
														fontSize: "0.8rem",
														marginTop: "2px",
														ml: "1rem"
													}}
												>
													{"["}
													{log.payload.timestamp}
													{"] "}
													{log.payload.message}
													{" (Node "}
													{log.payload.node_count}
													{")"}
												</Typography>
											))
										) : (
											<Typography
												variant="body2"
												sx={{
													color: "primary.main!important",
													fontSize: "12px",
													marginTop: "2px",
													opacity: "0.5",
													ml: "1rem",
												}}
											>
												{"No logs exist"}
											</Typography>
										)}
									</TreeItem>
								</TreeView>
							</Grid>
						</Grid>
						<Grid
							item
							display="flex"
							flexDirection="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							sx={{
								width: "100%",
								marginBottom: "10px",
								color: "primary.main",
								backgroundColor: "third.main",
								minHeight: "20%",
								borderRadius: "10px",
							}}
						>
							<Typography sx={{
								color: "black",
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								marginLeft: "0.5rem",
								fontWeight: "bold",
								fontSize: "14px",
								marginTop: "1rem"
							}}
							>
								{`Available Variables [${customLists.length + customVariables.length + toolboxVariables.length} items]`}
							</Typography>
							<Divider
								sx={{
									margin: "5px 0px",
									backgroundColor: "primary.main",
									width: "95%",
									marginLeft: "2.5%"
								}}
							/>
							<Grid item container flexDirection="column" sx={{ width: "100%", mb: "0.5rem", mt: "0.5rem" }}>
								<TreeView
									aria-label="User Variables"
									defaultCollapseIcon={(
										<IconButton
											size="small"
											sx={{
												'&:hover': {
													backgroundColor: 'transparent',
												},
												'&:active': {
													backgroundColor: 'transparent',
												}
											}}
										>
											<ExpandLess fontSize="small" />
										</IconButton>
									)}
									defaultExpandIcon={(
										<IconButton
											size="small"
											sx={{
												'&:hover': {
													backgroundColor: 'transparent',
												},
												'&:active': {
													backgroundColor: 'transparent',
												}
											}}
										>
											<ExpandMore fontSize="small" />
										</IconButton>
									)}
									defaultExpanded={["header"]}
									style={{ fontSize: '0.75rem', width: '95%' }}
								>
									<TreeItem
										nodeId="header"
										label={(
											<div style={{
												display: 'flex',
												alignItems: 'center',
												height: '40px',
												width: '100%',
												justifyContent: 'flex-start',
												paddingLeft: '0px',
											}}
											>
												<Typography
													variant="h6"
													sx={{ fontSize: "14px", fontWeight: 'bold', ml: 0 }}
												>
													{`User Variables [${customVariablesWithValues?.length || 0} items]`}
												</Typography>
											</div>
										)}
									>
										{/* {console.log("[", timestamp, "]", "CURRENT TABS", tabs, hasChanged)} */}
										<Grid item container flexDirection="column" sx={{ mt: "0.6rem", width: "100%", mb: 1 }}>
											{customVariablesWithValues.map((variable, index) => (
												<TreeItem
													key={variable.name}
													nodeId={String(index + 1)}
													label={(
														<div style={{
															display: 'flex',
															alignItems: 'center',
															height: '20px',
															width: '100%',
															overflow: 'hidden',
															paddingLeft: '0px',
														}}
														>
															<Typography
																noWrap
																variant="caption"
																sx={{
																	color: "#800F13",
																	fontSize: '13px',
																	wordBreak: 'break-word',
																	overflowWrap: 'break-word',
																	whiteSpace: 'normal',
																}}
															>
																{`${variable.name} = ${variable.value}`}
															</Typography>
															<IconButton
																size="small"
																style={{ marginLeft: '2px', padding: '1px' }}
																sx={{
																	'&:hover': {
																		backgroundColor: 'transparent',
																	},
																	'&:active': {
																		backgroundColor: 'transparent',
																	}
																}}
																onClick={() => navigator.clipboard.writeText(variable?.name)}
															>
																<ContentCopyIcon style={{
																	fontSize: '13px',
																	color: "#800F13",
																}}
																/>
															</IconButton>
														</div>
													)}
												/>
											))}
										</Grid>
									</TreeItem>
								</TreeView>
							</Grid>

							<Divider
								sx={{
									backgroundColor: "primary.main",
									width: "95%",
									marginLeft: "2.5%"
								}}
							/>
							<Grid item container flexDirection="column" sx={{ width: "100%", mb: "0.5rem", mt: "0.5rem" }}>
								<TreeView
									aria-label="User Variables"
									defaultCollapseIcon={(
										<IconButton
											size="small"
											sx={{
												'&:hover': {
													backgroundColor: 'transparent',
												},
												'&:active': {
													backgroundColor: 'transparent',
												}
											}}
										>
											<ExpandLess fontSize="small" />
										</IconButton>
									)}
									defaultExpandIcon={(
										<IconButton
											size="small"
											sx={{
												'&:hover': {
													backgroundColor: 'transparent',
												},
												'&:active': {
													backgroundColor: 'transparent',
												}
											}}
										>
											<ExpandMore fontSize="small" />
										</IconButton>
									)}
									defaultExpanded={["header"]}
									style={{ fontSize: '0.75rem', width: '95%' }}
								>
									<TreeItem
										nodeId="header"
										label={(
											<div style={{
												display: 'flex',
												alignItems: 'center',
												height: '40px',
												width: '100%',
												justifyContent: 'flex-start',
												paddingLeft: '0px',
											}}
											>
												<Typography
													variant="h6"
													sx={{ fontSize: "14px", fontWeight: 'bold', ml: 0 }}
												>
													{`User Lists [${customListsWithValues?.length || 0} items]`}
												</Typography>
											</div>
										)}
									>
										<Grid item container flexDirection="column" sx={{ mt: "0.6rem", width: "100%", mb: 1 }}>
											{customListsWithValues.map((list, index) => (
												<TreeItem
													key={list.name}
													nodeId={String(index + 1)}
													label={(
														<div style={{
															display: 'flex',
															alignItems: 'center',
															width: '100%',
															overflow: 'hidden',
															paddingLeft: '0px',
														}}
														>
															<Typography
																noWrap
																variant="caption"
																sx={{
																	color: "#800F13",
																	fontSize: '13px',
																	wordBreak: 'break-word',
																	overflowWrap: 'break-word',
																	whiteSpace: 'normal',
																}}
															>
																{`${list.name} = [${list.value.length === 0 ? "" : list.value.slice(0, 20).join(", ")}${list.value.length > 20 ? ", ..." : ""}]`}
															</Typography>

															<IconButton
																size="small"
																style={{ marginLeft: '2px', padding: '1px' }}
																sx={{
																	'&:hover': {
																		backgroundColor: 'transparent',
																	},
																	'&:active': {
																		backgroundColor: 'transparent',
																	}
																}}
																onClick={() => navigator.clipboard.writeText(list?.name)}
															>
																<ContentCopyIcon style={{
																	fontSize: '13px',
																	color: "#800F13",
																}}
																/>
															</IconButton>
														</div>
													)}
												/>
											))}
										</Grid>
									</TreeItem>
								</TreeView>
							</Grid>
							<Divider
								sx={{
									// margin: "10px 0px",
									backgroundColor: "primary.main",
									width: "95%",
									marginLeft: "2.5%"
								}}
							/>
							<Grid item container flexDirection="column" sx={{ width: "100%", mb: "0.5rem", mt: "0.5rem" }}>
								<TreeView
									aria-label="Toolbox Variables"
									defaultCollapseIcon={(
										<IconButton
											size="small"
											sx={{
												'&:hover': {
													backgroundColor: 'transparent',
												},
												'&:active': {
													backgroundColor: 'transparent',
												}
											}}
										>
											<ExpandLess fontSize="small" />
										</IconButton>
									)}
									defaultExpandIcon={(
										<IconButton
											size="small"
											sx={{
												'&:hover': {
													backgroundColor: 'transparent',
												},
												'&:active': {
													backgroundColor: 'transparent',
												}
											}}
										>
											<ExpandMore fontSize="small" />
										</IconButton>
									)}
									defaultExpanded={["header"]}
									style={{ fontSize: '0.75rem', width: '95%' }}
								>
									<TreeItem
										nodeId="header"
										label={(
											<div style={{
												display: 'flex',
												alignItems: 'center',
												height: '40px',
												width: '100%',
												justifyContent: 'flex-start',
											}}
											>
												<Typography
													variant="h6"
													sx={{ fontSize: "14px", fontWeight: 'bold', ml: 0 }}
												>
													{`Toolbox Variables [${toolboxVariables.length} items]`}
												</Typography>
											</div>
										)}
									>
										{Object.entries(groupedVariables).map(([group, vars]) => (
											<TreeItem
												key={group}
												nodeId={group}
												label={(
													<div style={{
														display: 'flex',
														alignItems: 'center',
														minHeight: '20px',
														width: '100%',
														overflow: 'hidden',
													}}
													>
														<Typography
															variant="caption"
															sx={{
																fontSize: "13px",
																fontWeight: "bold",
																color: "black",
																maxWidth: "calc(100% - 28px)",
															}}
														>
															{group}
														</Typography>
													</div>
												)}
											>
												{vars.map((variable, index) => (
													<TreeItem
														key={variable}
														nodeId={`${group}-${index}`}
														label={(
															<div style={{
																display: 'flex',
																alignItems: 'flex-start',
																minHeight: '20px',
																width: '100%',
																overflow: 'visible',
																flexWrap: 'wrap',
																paddingTop: '4px',
																paddingBottom: '4px',
															}}
															>
																<Typography
																	variant="caption"
																	sx={{
																		fontSize: "13px",
																		color: "#800F13",
																		wordBreak: 'break-word',
																		flexGrow: 1,
																		maxWidth: 'calc(100% - 26px)',
																	}}
																>
																	{variable}
																</Typography>
																<IconButton
																	size="small"
																	style={{ marginLeft: '2px', padding: '1px' }}
																	sx={{
																		'&:hover': {
																			backgroundColor: 'transparent',
																		},
																		'&:active': {
																			backgroundColor: 'transparent',
																		}
																	}}
																	onClick={() => navigator.clipboard.writeText(variable)}
																>
																	<ContentCopyIcon style={{
																		fontSize: '13px',
																		color: "#800F13",
																	}}
																	/>
																</IconButton>
															</div>
														)}
													/>
												))}
											</TreeItem>
										))}
									</TreeItem>
								</TreeView>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
			{envpopModel !== null && inSimulation && (
				<Grid
					style={{
						position: "absolute",
						height: envpopSize === "small" ? "400px" : "100%",
						aspectRatio: "3 / 2",
						objectFit: "contain",
						bottom: "0px",
						left: "0px",
						margin: "0px",
						padding: "0px",
						zIndex: 1000,
						opacity: 1,
					}}
				>
					<CreateMission
						ref={envpopRef}
						inPopup
						inSimulation={inSimulation}
						model={envpopModel?.model_text}
						dbitem={envpopModel}
					/>
					<div
						style={{
							position: "absolute",
							top: "0px",
							right: "0px",
							cursor: "pointer",
						}}
					>
						<IconButton aria-label={envpopSize === "small" ? "Expand" : "Shrink"} className="simulationPopupClose" onClick={envpopResize}>
							{envpopSize === "small" ? <ZoomOutMap sx={{ color: "#fff" }} /> : <ZoomInMap sx={{ color: "#fff" }} />}
						</IconButton>
					</div>
				</Grid>
			)}
			<Popup
				width="800px"
				open={nodeTooltip !== null}
				title="Documentation"
				titleColor="#0D192B"
				titleBackgroundColor="#ABC7D9"
				backgroundColor="#DDE8EF"
				closeIconColor="#0D192B"
				onClose={() => {
					setNodeTooltip(null);
				}}
			>
				<MDEditor
					hideToolbar
					value={nodeTooltip?.documentation}
					height={500}
					maxHeight={1200}
					fullscreen={false}
					tabSize={2}
					preview="preview"
					style={{
						zIndex: 100,
					}}
				/>
			</Popup>
		</>
	);
};

export default memo(Testbed);
