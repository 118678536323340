import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Operations between lists node

This node offers operations that involve two lists. An example follows, where the contents of \`list_10\` are copied in \`list_11\`:

![alt text](${transformAsset("lists-operations.png")})
`;

export default documentation;
