import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Random selection node

Random selection is a node that allows for randomness in the flow. It has the following typical form, which includes one entry point and N output handles, where \`N>1\`.

![alt text](${transformAsset("random-selection.png")})

As evident, this node has two buttons denoted with the plus and minus signs \`(+, -)\`. If you press the \`+\` one more output is added, and the last output is removed when you press the \`-\`.

The configuration of a Random node with 3 outputs is evident below:

![alt text](${transformAsset("random-selection-sum-1.png")})

Each input box is associated with its respective output and the number inside represents the possibility of randomly selecting this exact output. E.g. in the above ./assets/image, output 1 will be selected with a probability of 0.2 (or 2 out of 10 times), whereas output 3 will be randomly selected with a probability of 0.7. In the specific example, the sum of all numbers equals 1.0, but this is not a limitation for this node. You can include whatever numbers you want, and the probability will be computed relatively to the sum of these numbers.

For example in the below case, output 2 will be selected with a probability of 9/(12+9+3) = 9/24.

![alt text](${transformAsset("random-selection-sum-24.png")})
`;

export default documentation;
