import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Random number node

This node calculates a random float number and assigns it in an existing variable. You must declare the minimum and maximum of the range, and the selections follows a uniform distribution. An example follows:

![alt text](${transformAsset("random-number.png")})

Here, variable \`x\` is assigned a random number from 0 to 12, and after execution, the value is close to 10.94, as evident from the logs and the Live Variables section.
`;

export default documentation;
