const Sparks = ({
	dimension,
	x,
	y,
}) => (
	<div style={{ position: "absolute", width: `${dimension}px`, height: `${dimension}px`, left: `${x - (dimension / 2)}px`, bottom: `${y - (dimension / 2)}px` }}>
		<div className="spark" style={{ "--spark-rotate": "10deg", "--spark-delay": "223ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "20deg", "--spark-delay": "844ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "30deg", "--spark-delay": "130ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "40deg", "--spark-delay": "747ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "50deg", "--spark-delay": "928ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "60deg", "--spark-delay": "392ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "70deg", "--spark-delay": "483ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "80deg", "--spark-delay": "621ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "90deg", "--spark-delay": "814ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "100deg", "--spark-delay": "802ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "110deg", "--spark-delay": "837ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "120deg", "--spark-delay": "238ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "130deg", "--spark-delay": "642ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "140deg", "--spark-delay": "58ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "150deg", "--spark-delay": "404ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "160deg", "--spark-delay": "576ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "170deg", "--spark-delay": "944ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "180deg", "--spark-delay": "635ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "190deg", "--spark-delay": "205ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "200deg", "--spark-delay": "91ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "210deg", "--spark-delay": "829ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "220deg", "--spark-delay": "969ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "230deg", "--spark-delay": "861ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "240deg", "--spark-delay": "201ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "250deg", "--spark-delay": "173ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "260deg", "--spark-delay": "967ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "270deg", "--spark-delay": "548ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "280deg", "--spark-delay": "392ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "290deg", "--spark-delay": "273ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "300deg", "--spark-delay": "6ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "310deg", "--spark-delay": "1ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "320deg", "--spark-delay": "854ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "330deg", "--spark-delay": "159ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "340deg", "--spark-delay": "60ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "350deg", "--spark-delay": "986ms", "--spark-color": "red", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
		<div className="spark" style={{ "--spark-rotate": "360deg", "--spark-delay": "559ms", "--spark-color": "#ff9f1c", top: `${dimension / 2}px`, left: `${dimension / 2}px` }} />
	</div>
);

export default Sparks;
