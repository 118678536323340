/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable arrow-body-style */
import ParametersInput from "./parameters-input.js";

const DependentInput = ({ param, selectedValue, parameters, setParameters, nodeData }) => {
    return (
        <>
            {selectedValue === param?.dependedValue && (
                <ParametersInput
                    param={param}
                    parameters={parameters}
                    setParameters={setParameters}
                    nodeData={nodeData}
                />
            )}
        </>
    );
};

export default DependentInput;
