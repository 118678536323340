/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import { memo } from "react";
import { useParams } from "react-router-dom";
import AdminModelText from "../components/AdminModelText.js";

const Admin = () => {
    const { content } = useParams();
    return (
        <>
            {content === "model-text" && (
                <AdminModelText />
            )}
            {content === "project-description" && (
                <>
                </>
            )}
        </>
    );
};

export default memo(Admin);
