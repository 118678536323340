/* eslint-disable unicorn/consistent-destructuring */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */

import { useState } from "react";
import { Card, CardContent, Typography, IconButton, Grid, Box, TextField, Chip, Divider } from "@mui/material";
import { DeleteOutline, ContentCopy, Visibility, Folder } from "@mui/icons-material";
import AreYouSurePopup from "./Popups/AreYouSurePopup.js";
import Popup from "./Popup.js";
import ModelCard from "./ModelCard.js";
import { removeProjectFromMarketplace, cloneProjectFromMarketplace, deleteProject, updateProjectPinnedStatus } from "../api/index.js";
import MDEditor from "@uiw/react-md-editor";
import { useNavigate } from "react-router-dom";
import StarIcon from '@mui/icons-material/Star';
import MarkdownPreview from "@uiw/react-markdown-preview";
import removeFromMarketplaceIcon from "../assets/dsl-icons/Remove_marketplace.png";
import StoreIcon from '@mui/icons-material/Store';

const ProjectCard = ({
    project,
    setIsLoading,
    success,
    error,
    fetchData,
    projectModels,
    textualDslOptions,
    graphicalDslOptions,
    dslsImages,
    userProjects,
    isFromProjectScreen,
}) => {
    const [removePopupOpen, setRemovePopupOpen] = useState(false);
    const [viewPopupOpen, setViewPopupOpen] = useState(false);
    const [addProjectPopupOpen, setAddProjectPopupOpen] = useState(false);
    const [addedDescription, setAddedDescription] = useState(project?.description || "");
    const [addedTitle, setAddedTitle] = useState("");

    const { _id, title, description, createdAt, creatorFullName } = project;

    const navigate = useNavigate();
    const handleRemoveProjectFromMarketplace = async () => {
        setIsLoading(true);
        const { success: scs, message } = await removeProjectFromMarketplace(project._id);
        if (scs) {
            success(message);
        } else {
            error(message);
        }

        await fetchData();
        setRemovePopupOpen(false);
        setIsLoading(false);
    };

    const handleViewProject = () => {
        setViewPopupOpen(true);
    };

    const declineRemoveProjectFromMarketplace = () => {
        setRemovePopupOpen(false);
    };

    const addProjectSubmitHandler = async () => {
        setIsLoading(true);
        const { success: scs, message } = await cloneProjectFromMarketplace(project?._id, addedTitle, addedDescription);
        if (scs) {
            success(message);
            const newTabUrl = `${window.location.origin}/projects`;
            window.open(newTabUrl, '_blank');
        } else {
            error(message);
        }

        setAddProjectPopupOpen(false);
        setAddedDescription(null);
        setAddedTitle(null);
        setIsLoading(false);
    };

    const handleDeleteProject = async () => {
        setIsLoading(true);
        try {
            const { success: scs, message } = await deleteProject(project?._id);
            if (scs) {
                success(message);
            } else {
                error(message);
            }
        } catch (error_) {
            error(error_);
        }

        fetchData();
        setIsLoading(false);
    };

    const handlePinnedProject = async () => {
        setIsLoading(true);
        try {
            const { success: scs, message } = await updateProjectPinnedStatus(project?._id);
            if (scs) {
                success(message);
                fetchData();
            } else {
                error(message);
            }
        } catch (error_) {
            error(error_);
        }

        setIsLoading(false);
    };

    return (
        <>
            <Popup
                width="800px"
                open={addProjectPopupOpen}
                title="Add project information"
                titleColor="#0D192B"
                titleBackgroundColor="#ABC7D9"
                backgroundColor="#DDE8EF"
                closeIconColor="#0D192B"
                onClose={() => {
                    setAddProjectPopupOpen(false);
                }}
            >
                <TextField
                    label="title"
                    variant="filled"
                    sx={{
                        width: "100%",
                        mb: 2,
                        '& .MuiInputLabel-root': {
                            color: 'grey.main !important',
                        },
                    }}
                    InputProps={{
                        style: { color: "black", backgroundColor: "white" },
                    }}
                    value={addedTitle}
                    onChange={(e) => {
                        setAddedTitle(e.target.value);
                    }}
                />
                <Typography
                    sx={{
                        color: "#0D192B",
                        fontSize: "0.9rem",
                        mb: 1,
                        pl: 0.5,
                    }}
                >
                    {"Description in MarkDown format:"}
                </Typography>
                <MDEditor
                    hideToolbar
                    value={addedDescription}
                    height={500}
                    maxHeight={1200}
                    fullscreen={false}
                    tabSize={2}
                    style={{
                        zIndex: 100,
                        borderRadius: 10,
                    }}
                    onChange={setAddedDescription}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        mt: 2,
                    }}
                >
                    <Chip
                        key={-1}
                        label="Submit"
                        color="secondary"
                        sx={{
                            mr: 0.5,
                            backgroundColor: "#0D192B",
                            color: "#DDE8EF",
                        }}
                        onClick={addProjectSubmitHandler}
                    />
                </Box>
            </Popup>
            <AreYouSurePopup
                open={removePopupOpen}
                title="Remove Project from Marketplace?"
                content="Are you sure you want to remove this project from the Marketplace?"
                onDecline={declineRemoveProjectFromMarketplace}
                onAccept={handleRemoveProjectFromMarketplace}
            />
            <Popup
                width="100%"
                open={viewPopupOpen}
                title={title || "Untitled Project"}
                titleColor="#0D192B"
                titleBackgroundColor="#ABC7D9"
                backgroundColor="#DDE8EF"
                closeIconColor="#0D192B"
                onClose={() => setViewPopupOpen(false)}
            >
                <Grid
                    container
                    display="flex"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid container spacing={2} mt={2} justifyContent="start" flexDirection="row" width="100%">
                        {projectModels?.length > 0 ? (
                            projectModels.map((model, index) => (
                                <Grid
                                    key={index}
                                    item
                                >
                                    <Box sx={{ width: 300 }}>
                                        <ModelCard
                                            fromProject
                                            model={model}
                                            setIsLoading={setIsLoading}
                                            success={success}
                                            error={error}
                                            fetchData={fetchData}
                                            textualDsls={textualDslOptions.map((dsl) => dsl.value.toLowerCase())}
                                            graphicalDsls={graphicalDslOptions.map((dsl) => dsl.value.toLowerCase())}
                                            // eslint-disable-next-line unicorn/prefer-string-replace-all
                                            dslImage={dslsImages[model?.model_type.toLowerCase().replace(/-/g, "")]}
                                        />
                                    </Box>
                                </Grid>
                            ))
                        ) : (
                            <Typography variant="h6" color="textSecondary" mt={4}>
                                {"No models found."}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Popup>
            <Card
                sx={{
                    width: "100%",
                    height: 300,
                    maxWidth: 400,
                    margin: 2,
                    boxShadow: 6,
                    borderRadius: 3,
                    overflow: "hidden",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    backgroundColor: "#323D4D",
                    "&:hover": {
                        boxShadow: 12,
                        transform: "scale(1.02)",
                        transition: "all 0.3s ease",
                        "& .custom-scrollbar::-webkit-scrollbar": {
                            visibility: "visible",
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#ABC7D9",
                        padding: 1,
                        color: "white.main",
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        variant="h7"
                        fontWeight="bold"
                        sx={{
                            wordWrap: "break-word",
                            textAlign: "start",
                            color: "black",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {title || "Untitled Project"}
                    </Typography>
                    {isFromProjectScreen ? (
                        <IconButton
                            sx={{ height: 25, width: 25 }}
                            onClick={() => handlePinnedProject()}
                        >
                            <StarIcon
                                sx={{
                                    color: project?.pinned ? "warning.main" : "primary.main",
                                    fontSize: "1.5rem",
                                }}
                            />
                        </IconButton>
                    ) : (
                        <Folder sx={{ color: "black", fontSize: "1.5rem" }} />
                    )}
                </Box>
                <CardContent
                    sx={{
                        paddingTop: 2,
                        flexGrow: 1,
                    }}
                >
                    <Grid container direction="column">
                        <Grid item>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                    wordWrap: "break-word",
                                    fontStyle: "italic",
                                    fontWeight: "bold",
                                    color: "white !important",
                                    mb: "1rem",
                                }}
                            >
                                <MarkdownPreview
                                    source={description?.length > 40 ? `${description.slice(0, 40)}...` : description || "No description available."}
                                    style={{ background: "transparent", color: "inherit", fontSize: "0.8rem" }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                sx={{
                                    color: "secondary.main",
                                }}
                            >
                                {`Created At: ${new Date(createdAt).toLocaleDateString("el-GR")}`}
                            </Typography>
                        </Grid>

                        {!isFromProjectScreen && (
                            <Grid item>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    sx={{
                                        color: "white.main",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {`Created By: {${creatorFullName || "Unknown"}}`}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
                <Divider
                    sx={{
                        backgroundColor: "white.main",
                        marginBottom: "1rem",
                    }}
                />

                <Box
                    sx={{
                        position: "relative",
                        bottom: 0,
                        padding: "0.5rem",
                        display: "flex",
                        gap: 1,
                        justifyContent: "flex-end",
                    }}
                >
                    {project?.published && (
                        <IconButton
                            aria-label="clone model"
                            sx={{
                                backgroundColor: "#0D192B !important",
                                "& .MuiSvgIcon-root": {
                                    color: "#ABC7D9 !important",
                                },
                                borderRadius: "50%",
                                boxShadow: 3,
                                padding: 1,
                            }}
                        >
                            <StoreIcon sx={{
                                color: "#0D192B !important",
                            }}
                            />
                        </IconButton>
                    )}
                    <IconButton
                        aria-label="view model"
                        sx={{
                            backgroundColor: "#ABC7D9 !important",
                            borderRadius: "50%",
                            boxShadow: 3,
                            padding: 1,
                            "&:hover": {
                                backgroundColor: "#0D192B !important",
                                "& .MuiSvgIcon-root": {
                                    color: "#ABC7D9 !important",
                                },
                            },
                        }}
                        onClick={isFromProjectScreen ? () => navigate(`/projects/${project?._id}`) : handleViewProject}
                        onAuxClick={(event) => {
                            if (event.button === 1) {
                                event.preventDefault();
                                if (isFromProjectScreen) {
                                    const baseUrl = window.location.origin;
                                    const modelUrl = `${baseUrl}/projects/${project?._id}`;
                                    window.open(modelUrl, "_blank");
                                }
                            }
                        }}
                    >
                        <Visibility sx={{
                            color: "#0D192B !important",
                        }}
                        />
                    </IconButton>
                    {!isFromProjectScreen && (
                        <IconButton
                            aria-label="clone model"
                            sx={{
                                backgroundColor: "#ABC7D9 !important",
                                borderRadius: "50%",
                                boxShadow: 3,
                                padding: 1,
                                "&:hover": {
                                    backgroundColor: "#0D192B !important",
                                    "& .MuiSvgIcon-root": {
                                        color: "#ABC7D9 !important",
                                    },
                                },
                            }}
                            onClick={() => setAddProjectPopupOpen(true)}
                        >
                            <ContentCopy sx={{
                                color: "#0D192B !important",
                            }}
                            />
                        </IconButton>
                    )}
                    {userProjects.includes(_id) && (
                        <IconButton
                            aria-label="remove model"
                            sx={{
                                backgroundColor: isFromProjectScreen ? "transparent" : "#ABC7D9",
                                border: isFromProjectScreen ? `2px solid #CF6679` : null,
                                borderRadius: "50%",
                                boxShadow: 3,
                                padding: 1,
                                "&:hover": {
                                    backgroundColor: isFromProjectScreen ? "#CF6679" : "primary.main",
                                    borderColor: "#CF6679",
                                    "& .MuiSvgIcon-root": {
                                        color: "#0D192B",
                                    },
                                },
                            }}
                            onClick={isFromProjectScreen ? handleDeleteProject : () => setRemovePopupOpen(true)}
                        >
                            {isFromProjectScreen ? (
                                <DeleteOutline sx={{
                                    color: "#CF6679",
                                }}
                                />
                            ) : (
                                <img
                                    src={removeFromMarketplaceIcon}
                                    alt="Remove from Marketplace"
                                    style={{ width: 24, height: 24 }}
                                />
                            )}
                        </IconButton>
                    )}
                </Box>
            </Card>

        </>
    );
};

export default ProjectCard;
