import {
	Typography,
	Grid,
} from "@mui/material";

import { memo, useState } from "react";

import ReactFlow, {
	Position,
	Handle,
} from 'reactflow';

import SettingsIcon from '@mui/icons-material/Settings';

import Popup from "../Popup.js";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ParametersInput from "./parameters-input.js";

import CustomHandle from "./custom-node-handle.js";

import { useDispatch } from 'react-redux';
import { updateNodeParameters } from "./slice-nodes.js";

// Create a custom node based on inputs
const ConditionNode = memo(({ data, isConnectable }) => {
	const [outputs, setOutputs] = useState(data.parameters.length);
	const [inputs, setInputs] = useState(data.inputs);
	const dispatch = useDispatch();

	const [popupOpen, setPopupOpen] = useState(false);
	const [parameters, setParameters] = useState(data.parameters);
	const [internalData, setInternalData] = useState(data);

	return (
		<Grid
			style={{
				background: data.backgroundColor ?? '#fff',
				border: '1px solid #000',
				borderRadius: '10px',
				padding: '10px',
				paddingRight: '30px',
				paddingLeft: '30px',
				paddingTop: `${(outputs + 1) * 7}px`,
				paddingBottom: `${(outputs + 1) * 7}px`,
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				minWidth: "230px",
			}}
		>
			<Popup
				width="600px"
				open={popupOpen}
				title={data.label}
				onClose={async () => {
					setPopupOpen(false);

					// Update the parameters
					setInternalData({ ...internalData, parameters });
					await dispatch(updateNodeParameters({
						id: data.id,
						parameters: { ...internalData, parameters },
					}));
				}}
			>
				<Grid
					container
					display="flex"
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
				>
					{parameters.map((param, i) => (
						<ParametersInput
							key={`${i}${data.name}${data.toolbox}`}
							param={param}
							parameters={parameters}
							setParameters={setParameters}
							nodeData={data}
						/>
					))}
				</Grid>
			</Popup>
			{/* Iterate on data.inputs */}
			{Array.from({ length: inputs ?? 1 }, (_, i) => (
				<CustomHandle
					key={Math.random().toString()}
					id={`in_${i.toString()}`}
					type="target"
					isConnectable={100}
					position={Position.Left}
					label={`in_${i.toString()}`}
					// Place them in different positions
					style={{
						top: `calc(${(i + 1) * (100 / (inputs + 1))}%)`,
						left: "-18px",
						background: '#0a0',
						width: '15px',
						height: '15px',
					}}
					onConnect={(params) => console.log('handle onConnect', params)}
				/>
			))}
			<Grid
				item
				container
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				sx={{
					width: "100%",
					height: "100%",
				}}
			>
				<Grid
					item
					container
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
				>
					<Typography
						px={1}
						sx={{
							borderRadius: "50px",
							marginRight: "10px",
							backgroundColor: data.fontColor,
							color: data.backgroundColor,
							fontSize: "0.8em",
							fontWeight: "bold",
						}}
					>
						{data.count}
					</Typography>
					<Typography
						sx={{
							fontSize: "1.2em",
							color: data.fontColor,
						}}
					>
						{data.label}
					</Typography>
				</Grid>
				<AddCircleIcon
					sx={{
						color: data.fontColor,
						fontSize: "1em",
						marginRight: "5px",
						position: "absolute",
						right: "0.5px",
						top: "5px",
					}}
					onClick={async () => {
						await setOutputs(outputs + 1);
						// Add another parameter before the last one
						const newParams = [
							...parameters.slice(0, -1),
							{
								id: Math.random().toString(),
								label: `Condition #${(parameters.length - 1).toString()}`,
								type: "input",
								value: "False",
								required: true,
							},
							parameters.at(-1),
						];
						await setParameters(newParams);

						await setInternalData({
							...internalData,
							parameters: newParams,
							outputs: outputs + 1,
						});
						await dispatch(updateNodeParameters({
							id: data.id,
							parameters: {
								...internalData,
								parameters: newParams,
								outputs: outputs + 1,
							},
						}));
					}}
				/>
				<RemoveCircleIcon
					sx={{
						color: data.fontColor,
						fontSize: "1em",
						marginRight: "5px",
						position: "absolute",
						right: "0.5px",
						top: "25px",
					}}
					onClick={async () => {
						if (outputs > 1) {
							setOutputs(outputs - 1);
							// Remove the previous to last parameter if there are more than 1
							const newParams = [
								...parameters.slice(0, -2),
								parameters.at(-1),
							];
							await setParameters(newParams);

							// Update the parameters
							setInternalData({
								...internalData,
								parameters: newParams,
								outputs: outputs - 1,
							});
							await dispatch(updateNodeParameters({
								id: data.id,
								parameters: {
									...internalData,
									parameters: newParams,
									outputs: outputs - 1,
								},
							}));
						}
					}}
				/>
				<Grid
					itemc
					container
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="flex-start"
				>
					{parameters.map((param) => (
						<Typography
							key={Math.random().toString()}
							sx={{
								fontSize: "0.8em",
								color: data.fontColor,
							}}
						>
							{param.label}
							{" : "}
							{param.type === "switch" ? (
								`${param.value}`
							) : (
								`${param.value}`
							)}
						</Typography>
					))}
				</Grid>
			</Grid>
			{/* Add a parameters icon which opens the popup */}
			{data.parameters && data.parameters.length > 0 && (
				<Grid
					item
					// container
					display="flex"
					justifyContent="flex-end"
					alignItems="flex-start"
					sx={{
						position: "absolute",
						right: "8px",
						top: "-23.5px",
						padding: "10px",
						paddingBottom: "4px",
						paddingTop: "4px",
						borderWidth: "1px",
						border: "1px solid #666",
						borderBottomWidth: "0px",
						borderRadius: "10px",
						borderBottomRightRadius: "0px",
						borderBottomLeftRadius: "0px",
						// backgroundColor: "gray",
					}}
				>
					<SettingsIcon
						sx={{
							cursor: "pointer",
							color: data.fontColor,
							fontSize: "1em",
						}}
						onClick={() => {
							setPopupOpen(true);
						}}
					/>
				</Grid>
			)}
			{Array.from({ length: outputs ?? 1 }, (_, i) => (
				<Handle
					key={Math.random().toString()}
					type="source"
					position={Position.Right}
					id={`out_${i.toString()}`}
					isConnectable={100}
					style={{
						top: `calc(${(i + 1) * (100 / (outputs + 1))}%)`,
						right: "-30px",
						background: '#a00',
						width: '15px',
						height: '15px',
					}}
					onConnect={(params) => console.log('handle onConnect', params)}
				>
					<Typography
						sx={{
							position: "relative",
							fontSize: "0.6em",
							color: data.fontColor,
							left: "-11px",
						}}
					>
						{`${i}`}
					</Typography>
				</Handle>
			))}
		</Grid>
	);
});

export default ConditionNode;
