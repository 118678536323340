import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {Object} Log
 * @property {string} payload - The log payload.
 */

/**
 * @typedef {Object} LogsState
 * @property {Log[]} - The array of logs.
 */

/**
 * @typedef {import('@reduxjs/toolkit').CaseReducer<LogsState>} LogsCaseReducer
 */

/**
 * @typedef {import('@reduxjs/toolkit').Slice<LogsState, LogsCaseReducer>} LogsSlice
 */

/**
 * The slice for managing logs in the application state.
 * @type {LogsSlice}
 */
export const logsSlice = createSlice({
	name: 'logs',
	initialState: [],
	reducers: {
		/**
		 * Adds a log to the state.
		 * @param {LogsState} state - The current state.
		 * @param {Log} log - The log to be added.
		 */
		addLog: (state, log) => {
			console.log("New log:", log.payload);
			state.push(log.payload);
			return state;
		},
		/**
		 * Cleans the logs array.
		 * @param {LogsState} state - The current state.
		 */
		cleanLogs: (state) => {
			state = [];
			return state;
		},
		setLogsParameters: (state, data) => {
			const logArr = [];

			for (const log of data.payload) {
				logArr.push({
					logId: log.nodeId,
					logName: log.variable,
					logValue: log.value,
				});
			}

			state = logArr;
			return state;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addLog, cleanLogs, setLogsParameters } = logsSlice.actions;

export default logsSlice.reducer;
