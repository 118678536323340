import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Condition node

Condition is another node with 1 input and N outputs, where N>1. A typical view of a Condition node with 3 outputs is the following:

![alt text](${transformAsset("condition-example.png")})

Here, you declare \`N-1\` conditions, and a "default" condition exists which is always True. The conditions are checked in the order of appearance, thus first \`Condition #0\` will be evaluated, if it is False \`Condition #1\` will be evaluated, and if it is False, the Else will be always met. Thus, since a default condition always exist you do not have to create all conditions that are logically complementary.

Each input text for each condition is evaluated, thus you can include \`{}\` for variables substitution or \`||\` for pythonic evaluation. In the above example, if variable \`x\` is smaller than 5 the first output is selected, if it is larger than 10 the second output is selected, and it it is neither (thus \`5 <= x <= 10\`) the third output is selected.
`;

export default documentation;
