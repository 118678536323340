import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Create variables node

This node allows for declaring several variables, all at once! A typical example of this node is the following:

![alt text](${transformAsset("create-variables.png")})

As evident, you declare pairs of variables and their values, using the keyboard (with tab you move to the next field, with enter you add a new variable, with delete you delete a variable).
All these variables fall under the category of "User" variables, to differentiate them from variables that are automatically introduced from 3rd party toolboxes. Thus, these variables are also visible in the right column, under the "User variables" section.
`;

export default documentation;
