// eslint-disable-next-line no-unused-vars
import * as React from 'react';
import { useNavigate } from "react-router-dom";

import {
	Box,
	Grid,
	Typography,
	IconButton,
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { memo, useCallback, useEffect, useState } from "react";

// import { PrimaryBorderButton } from "../components/Buttons.js";
import Popup from "../components/Popup.js";
// import Search from "../components/Search.js";
// import DatePicker from "../components/DatePicker.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import { useSnackbar, jwt, isFuzzyMatch } from "../utils/index.js";
// import { use } from 'i18next';
import AreYouSurePopup from "../components/Popups/AreYouSurePopup.js";
import Search from '../components/Search.js';

import {
	getUsersModels,
	newUserModel,
	removeModel,
	cloneModel,
} from '../api/index.js';

import ModelCard from '../components/ModelCard.js';

import { getDslConstants, getDslsConstants, getDslsImages } from '../dsls/index.js';

const textualDslOptions = [
	{ text: "All DSLs", value: "All DSLs" },
	{ text: "SmAuto", value: "SmAuto" },
	{ text: "CODINTxt", value: "CODINTxt" },
	{ text: "OpenAPI", value: "OpenAPI" },
	{ text: "ToolBoxer", value: "ToolBoxer" },
	{ text: "Goal-dsl", value: "Goal-dsl" },
	{ text: "dflow", value: "dflow" },
	{ text: "DeMoL", value: "DeMoL" },
	{ text: "cps-ml", value: "cps-ml" },
	{ text: "Generos", value: "Generos" },
	{ text: "rosbridge-ml", value: "rosbridge-ml" },
	{ text: "XmasDSL", value: "XmasDSL" },
	{ text: "comm-idl", value: "comm-idl" },
];

const graphicalDslOptions = [
	{ text: "All DSLs", value: "All DSLs" },
	{ text: "AppCreator", value: "AppCreator" },
	{ text: "EnvMaker", value: "EnvMaker" },
	{ text: "EnvPop", value: "EnvPop" },
];

const DslScreen = (params) => {
	const { error, success } = useSnackbar();
	// eslint-disable-next-line no-unused-vars
	const [currentDsl, setCurrentDsl] = useState(params.dsl);
	const [currentDslConstants, setCurrentDslConstants] = useState(null);
	const [currentDslImage, setCurrentDslImage] = useState(null);
	const [dslsImages, setDslsImages] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [supportsValidation, setSupportsValidation] = useState(true);
	const [addModelPopupOpen, setAddModelPopupOpen] = useState(false);
	const [cloneModelPopupOpen, setCloneModelPopupOpen] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [user, setUser] = useState(jwt.decode());
	const [modelToDelete, setModelToDelete] = useState(null);
	const [modelToClone, setModelToClone] = useState(null);
	const [deleteModelPopupOpen, setDeleteModelPopupOpen] = useState(false);
	const [searchFilter, setSearchFilter] = useState(null);
	const [models, setModels] = useState([]);
	const [filteredModels, setFilteredModels] = useState([]);

	const navigate = useNavigate();

	// eslint-disable-next-line no-unused-vars
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const newModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Title",
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "Description",
		},
		{
			customType: "wideInput",
			id: "tags",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "Tags",
			helperText: "You can add multiple tags separated by comma",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add model",
		},
	];

	const cloneModelFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "input",
			multiline: false,
			width: 400,
			placeholder: "New title",
			value: modelToClone?.title,
		},
		{
			customType: "wideInput",
			id: "description",
			type: "input",
			multiline: true,
			minRows: 3,
			width: 400,
			placeholder: "New description",
			value: modelToClone?.description,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Clone model",
		},
	];

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);

			const constants = await getDslsConstants();
			setCurrentDslConstants(constants.find((con) => con._id === currentDsl));

			const dslImages = await getDslsImages();
			setDslsImages(dslImages);
			setCurrentDslImage(dslImages[currentDsl]);
			// const shortDescription
			const description = (await getDslConstants(currentDsl));
			setSupportsValidation(description.hasValidation);

			// Fetch the user's models
			const { success: scs, models: mds } = await getUsersModels(user.id, currentDsl, false);
			console.log("Models", mds);
			if (scs) {
				setModels(mds.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
				setFilteredModels(mds.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
			} else {
				error();
			}

			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[error, user.id],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	useEffect(() => {
		let filtered = [...models];

		if (searchFilter) {
			filtered = filtered.filter((flt) => isFuzzyMatch(flt?.title, searchFilter)
				|| isFuzzyMatch(flt?.description, searchFilter)
				|| flt?.tags.some((tag) => isFuzzyMatch(tag, searchFilter)));
		}

		setFilteredModels(filtered);
	}, [searchFilter, models]);

	const addModelSubmitHandler = async (values) => {
		setIsLoading(true);
		if (values?.tags?.length > 0 && values?.tags.includes(",")) {
			values.tags = values.tags.split(",").map((tag) => tag.trim());
		}

		try {
			const { success: successCode, message, newModel } = await newUserModel(
				user.id,
				currentDsl,
				values.title,
				values.description,
				values.tags,
			);

			if (successCode) {
				success(message);
				window.location.assign(`/dsls/${currentDsl}/${newModel?._id}`);
			} else {
				error(message);
			}

			setAddModelPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const cloneModelSubmitHandler = async (values) => {
		setIsLoading(true);
		console.log(values);
		try {
			const { success: successCode, message } = await cloneModel(
				modelToClone._id,
				values.title,
				values.description,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setModelToClone(null);
			setCloneModelPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const deleteModel = async () => {
		setIsLoading(true);
		const { success: successCode, message } = await removeModel(modelToDelete._id);

		if (successCode) {
			success(message);
		} else {
			error(message);
		}

		await fetchData();

		setIsLoading(false);
		setDeleteModelPopupOpen(false);
		setModelToDelete(null);
	};

	const declineDeleteModel = () => {
		setDeleteModelPopupOpen(false);
		setModelToDelete({});
	};

	return (
		<>
			<Spinner open={isLoading} />
			<AreYouSurePopup
				open={deleteModelPopupOpen}
				title="Delete model?"
				content={`Are you sure you want to delete the model "${modelToDelete?.title}"?`}
				onDecline={declineDeleteModel}
				onAccept={deleteModel}
			/>
			<Popup
				width="800px"
				open={addModelPopupOpen}
				title="Create new model"
				onClose={() => {
					setAddModelPopupOpen(false);
				}}
			>
				<Form
					content={newModelFormContent}
					onSubmit={addModelSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={cloneModelPopupOpen}
				title="Clone the model into a new one"
				onClose={() => {
					setCloneModelPopupOpen(false);
				}}
			>
				<Form
					content={cloneModelFormContent}
					onSubmit={cloneModelSubmitHandler}
				/>
			</Popup>
			<Grid
				container
				item
				width="100%"
				display="flex"
				flexDirection="column"
				maxWidth="1400px"
				mx="auto"
				alignItems="center"
			>
				<Grid
					container
					item
					width="100%"
					display="flex"
					flexDirection="row"
					sx={{
						borderBottom: "1px solid",
						borderColor: "third.main",
						pb: "0.5rem",
					}}
				>
					<Grid item xs={12}>
						<Typography variant="h6" sx={{ color: "third.main", fontWeight: "bold" }}>
							{currentDslConstants?.name}
						</Typography>
					</Grid>
				</Grid>

				<Grid
					container
					item
					width="100%"
					display="flex"
					flexDirection="row"
					sx={{
						mt: "1.5rem",
					}}
				>
					<Grid item xs={2} sx={{ mt: "1rem" }}>
						<img
							src={currentDslImage}
							alt={currentDslImage}
							style={{
								height: 70,
							}}
						/>
					</Grid>
					<Grid
						item
						xs={6}
						display="flex"
						justifyContent="flex-start"
						alignItems="flex-start"
						sx={{ mt: "1rem" }}
					>
						<Typography sx={{ color: "white !important", fontSize: "15px", ml: "2rem" }}>
							{currentDslConstants?.description}
						</Typography>
					</Grid>
				</Grid>

				<Grid
					container
					item
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
					sx={{
						borderBottom: "1px solid",
						borderColor: "third.main",
						pb: "0.5rem",
						mt: "1.5rem",
					}}
				>
					<Grid item>
						<Typography variant="h6" sx={{ color: "third.main", fontWeight: "bold" }}>
							{"Your models"}
						</Typography>
					</Grid>

					<Grid item display="flex" alignItems="center" gap={2} sx={{ width: "400px" }}>
						<Search
							value={searchFilter}
							onChange={(event) => setSearchFilter(event.target.value)}
						/>
						<Tooltip title="Create new model">
							<IconButton onClick={() => setAddModelPopupOpen(true)}>
								<AddCircleIcon
									fontSize="inherit"
									sx={{
										color: "#BB86FC !important",
										width: 40,
										height: 40,
									}}
								/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>

				<Grid
					container
					item
					width="100%"
					display="flex"
					flexDirection="row"
					mx="auto"
					alignItems="center"
				>
					<Grid item xs={12}>
						{filteredModels.length === 0 ? (
							<Typography color="gray" pl={2} pb={1} pt={1} fontSize="0.9rem">
								{"No models found."}
							</Typography>
						) : (
							<Grid
								container
								spacing={2}
								flexDirection="row"
								width="100%"
								justifyContent="center"
								alignItems="center"
							>
								{filteredModels.map((e, ind) => (
									<Grid key={ind} item xs={3} display="flex" justifyContent="center">
										<Box sx={{ width: 330 }}>
											<ModelCard
												fromDslScreen
												supportsValidation={supportsValidation}
												model={e}
												setIsLoading={setIsLoading}
												success={success}
												error={error}
												fetchData={fetchData}
												textualDsls={textualDslOptions.map((dsl) => dsl.value.toLowerCase())}
												graphicalDsls={graphicalDslOptions.map((dsl) => dsl.value.toLowerCase())}
												// eslint-disable-next-line unicorn/prefer-string-replace-all
												dslImage={dslsImages[e?.model_type.toLowerCase().replace(/-/g, "")]}
												removeFromOtherScreen={() => {
													setModelToDelete(e);
													setDeleteModelPopupOpen(true);
												}}
												cloneFromOtherScreen={() => {
													setModelToClone(e);
													setCloneModelPopupOpen(true);
												}}
												viewFromOtherScreen={() => { navigate(`/dsls/${currentDsl}/${e._id}`); }}
											/>
										</Box>
									</Grid>
								))}
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(DslScreen);
