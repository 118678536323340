import { Grid, Typography, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { ArrowUpward, ArrowDownward, Delete } from "@mui/icons-material";
import Dropdown from "./Dropdown.js";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	stepsBox: {
		width: "100%",
		marginBottom: "10px",
		display: "flex",
	},
	steps: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		color: "black",
	},
	list: {
		padding: "0px",
	},
	listItem: {
		padding: "3px 0px !important",
	},
	noItems: {
		color: theme.palette.grey[500],
	},
	arrowButton: {
		color: `${theme.palette.secondary.main} !important`,
		"&:disabled": {
			color: `${theme.palette.grey[500]} !important`,
		},
	},
	arrowButtonIcon: {
		color: "inherit",
	},
	deleteButton: {
		color: `${theme.palette.error.main} !important`,
	},
}));

const ReorderableList = ({ comp, formikProps, disabled }) => {
	const classes = useStyles();

	const handleReorder = (index, direction) => {
		const newValue = [...formikProps.values[comp.id]];
		const swapIndex = direction === "up" ? index - 1 : index + 1;
		if (swapIndex < 0 || swapIndex >= newValue.length) return;
		[newValue[index], newValue[swapIndex]] = [newValue[swapIndex], newValue[index]];
		formikProps.setFieldValue(comp.id, newValue);
		if (comp.onChange) {
			comp.onChange(newValue);
		}
	};

	const handleDelete = (index) => {
		const newValue = [...formikProps.values[comp.id]];
		newValue.splice(index, 1);
		formikProps.setFieldValue(comp.id, newValue);
		if (comp.onChange) {
			comp.onChange(newValue);
		}
	};

	const handleAddItem = (event) => {
		const selectedItem = comp.items.find((item) => item.name === event.target.value);
		if (selectedItem) {
			const newValue = [...formikProps.values[comp.id], selectedItem];
			formikProps.setFieldValue(comp.id, newValue);
			if (comp.onChange) {
				comp.onChange(newValue);
			}
		}
	};

	return (
		<Grid container item className={classes.stepsBox}>
			<Grid item className={classes.steps}>
				<Typography>{comp.label}</Typography>
				<List className={classes.list}>
					{formikProps.values[comp.id].length === 0 && (
						<ListItem className={classes.listItem}>
							<ListItemText primary="No items" className={classes.noItems} />
						</ListItem>
					)}
					{formikProps.values[comp.id]?.map((item, index) => (
						<ListItem
							key={`${comp.id}-${index}`}
							className={classes.listItem}
							classes={{ root: classes.listItem }}
							secondaryAction={(
								<>
									<IconButton
										edge="end"
										disabled={disabled || index === 0}
										className={classes.arrowButton}
										onClick={() => handleReorder(index, "up")}
									>
										<ArrowUpward className={classes.arrowButtonIcon} />
									</IconButton>
									<IconButton
										edge="end"
										disabled={disabled || index === formikProps.values[comp.id].length - 1}
										className={classes.arrowButton}
										onClick={() => handleReorder(index, "down")}
									>
										<ArrowDownward className={classes.arrowButtonIcon} />
									</IconButton>
									<IconButton
										edge="end"
										disabled={disabled}
										className={classes.deleteButton}
										onClick={() => handleDelete(index)}
									>
										<Delete className={classes.arrowButtonIcon} />
									</IconButton>
								</>
							)}
						>
							<ListItemText primary={`${index + 1}. ${item.name}`} />
						</ListItem>
					))}
				</List>
				<Dropdown
					id={comp.id}
					items={comp.items.map((item) => ({ text: item.name, value: item.name }))}
					value=""
					disabled={disabled || comp.disabled}
					size="medium"
					placeholder={comp.placeholder}
					filled={false}
					onChange={handleAddItem}
				/>
			</Grid>
		</Grid>
	);
};

export default ReorderableList;
