import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Set variable node

This node updates the value of a variable that has been created using the "Create variable" node. A typical example is this:

![alt text](${transformAsset("set-variable.png")})

Here, a variable called \`variable_21\` is created with an initial value of 15, and then its value is set to \`3 * {variable_21}\`, thus the final value is 45 (as shown in the live variables section at the right).
`;

export default documentation;
