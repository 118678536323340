import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Log node

Log is an assistive node which prints whatever expression you add as its parameter. It has one parameter which is handled as a string, and it is evaluated as explained in sections 1.1 and 1.2 of the documentation.

The results appear in the "Application logs" section at the right column, as such:

![alt text](${transformAsset("log-example.png")})
`;

export default documentation;
