import hallCorner from "../../../assets/images/background/nuclear/hall_corner.png";
import hallStraight from "../../../assets/images/background/nuclear/hall_straight.png";
import hallT from "../../../assets/images/background/nuclear/hall_t.png";
import hall from "../../../assets/images/background/nuclear/hall.png";
import general from "../../../assets/images/background/nuclear/general.png";
import office from "../../../assets/images/background/nuclear/office.png";
import pipes from "../../../assets/images/background/nuclear/pipes.png";
import reactors from "../../../assets/images/background/nuclear/reactors.png";
import steelHallStraight from "../../../assets/images/background/nuclear/steel_hall_straight.png";
import steelHallStraight2 from "../../../assets/images/background/nuclear/steel_hall_straight_2.png";
import steelHallT from "../../../assets/images/background/nuclear/steel_hall_t.png";
import steelHall from "../../../assets/images/background/nuclear/steel_hall.png";
import tank from "../../../assets/images/background/nuclear/tank.png";

const nuclear = {
	name: "Nuclear Factory",
	components: {
		hallCorner: {
			id: "hallCorner",
			image: hallCorner,
			text: "Hall Corner",
			pois: {
				name: "street",
				items: [
					{ x: 50, y: 17 },
					{ x: 50, y: 50 },
					{ x: 83, y: 50 },
				],
			},
			walls: [
				{ x1: 36, y1: 0, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 64, y2: 64 },
				{ x1: 64, y1: 64, x2: 100, y2: 64 },
				{ x1: 64, y1: 0, x2: 64, y2: 24 },
				{ x1: 64, y1: 24, x2: 76, y2: 36 },
				{ x1: 76, y1: 36, x2: 100, y2: 36 },
			],
			waters: [],
		},
		hallStraight: {
			id: "hallStraight",
			image: hallStraight,
			text: "Hall Straight",
			pois: {
				name: "street",
				items: [
					{ x: 50, y: 50 },
				],
			},
			walls: [
				{ x1: 0, y1: 36, x2: 100, y2: 36 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		hallT: {
			id: "hallT",
			image: hallT,
			text: "Hall T",
			pois: {
				name: "street",
				items: [
					{ x: 50, y: 50 },
				],
			},
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		hall: {
			id: "hall",
			image: hall,
			text: "Hall",
			pois: {
				name: "street",
				items: [
					{ x: 50, y: 17 },
					{ x: 50, y: 50 },
					{ x: 50, y: 83 },
					{ x: 17, y: 50 },
					{ x: 83, y: 50 },
				],
			},
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 64 },
				{ x1: 64, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		general: {
			id: "general",
			image: general,
			text: "General",
			pois: {
				name: "street",
				items: [
					{ x: 50, y: 50 },
				],
			},
			walls: [],
			waters: [],
		},
		office: {
			id: "office",
			image: office,
			text: "Office",
			pois: {
				name: "office",
				items: [
					{ x: 50, y: 15 },
				],
			},
			walls: [
				{ x1: 32, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 68, y2: 0 },
			],
			waters: [],
		},
		pipes: {
			id: "pipes",
			image: pipes,
			text: "Pipes",
			pois: { name: "", items: [] },
			walls: [],
			waters: [],
		},
		reactors: {
			id: "reactors",
			image: reactors,
			text: "Reactors",
			pois: {
				name: "street",
				items: [
					{ x: 50, y: 85 },
				],
			},
			walls: [
				{ x1: 0, y1: 69, x2: 32, y2: 69 },
				{ x1: 32, y1: 69, x2: 32, y2: 100 },
				{ x1: 68, y1: 100, x2: 68, y2: 69 },
				{ x1: 68, y1: 69, x2: 100, y2: 69 },
			],
			waters: [],
		},
		steelHallStraight: {
			id: "steelHallStraight",
			image: steelHallStraight,
			text: "Steel Hall Straight",
			pois: {
				name: "street",
				items: [
					{ x: 50, y: 50 },
				],
			},
			walls: [
				{ x1: 0, y1: 36, x2: 100, y2: 36 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		steelHallStraight2: {
			id: "steelHallStraight2",
			image: steelHallStraight2,
			text: "Steel Hall Straight 2",
			pois: {
				name: "street",
				items: [
					{ x: 50, y: 50 },
				],
			},
			walls: [
				{ x1: 0, y1: 36, x2: 100, y2: 36 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		steelHallT: {
			id: "steelHallT",
			image: steelHallT,
			text: "Steel Hall T",
			pois: {
				name: "street",
				items: [
					{ x: 50, y: 50 },
				],
			},
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		steelHall: {
			id: "steelHall",
			image: steelHall,
			text: "Steel Hall",
			pois: {
				name: "street",
				items: [
					{ x: 50, y: 17 },
					{ x: 50, y: 50 },
					{ x: 50, y: 83 },
					{ x: 17, y: 50 },
					{ x: 83, y: 50 },
				],
			},
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 64 },
				{ x1: 64, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		tank: {
			id: "tank",
			image: tank,
			text: "Tank",
			pois: { name: "", items: [] },
			walls: [
				{ x1: 50, y1: 80, x2: 71, y2: 71 },
				{ x1: 71, y1: 71, x2: 80, y2: 50 },
				{ x1: 80, y1: 50, x2: 71, y2: 29 },
				{ x1: 71, y1: 29, x2: 50, y2: 20 },
				{ x1: 50, y1: 20, x2: 29, y2: 29 },
				{ x1: 29, y1: 29, x2: 20, y2: 50 },
				{ x1: 20, y1: 50, x2: 29, y2: 71 },
				{ x1: 29, y1: 71, x2: 50, y2: 80 },
			],
			waters: [{ x: 50, y: 50, range: 50 }],
		},
	},
};

export default nuclear;
