import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Create variable node

This node allows for declaring the creation of a new variable. A typical example of this node is the following:

![alt text](${transformAsset("create-variable.png")})

In this node you must declare the variable's name (which is restricted according to pythonic variables naming rules), and the variable's initial value. The value can be a number, a string or an expression that will be evaluated (e.g. \`|{x} + 2|\`).
This variable falls under the category of "Custom" variables, to differentiate them from variables that are automatically introduced from 3rd party toolboxes. Thus, these variables are also visible in the right column, under the "Custom variables" section.
`;

export default documentation;
