// import { shortDescription } from "./envmaker/short-description.js";
import { getDocumentation } from "./appcreator/documentation.js";

import AppcreatorLight from "../assets/dsls-logos/appcreator_L.png";
import AppcreatorDark from "../assets/dsls-logos/appcreator_D.png";
import { Typography } from "@mui/material";
import jwt from "../utils/jwt.js";

/**
 * Retrieves the constants for the AppCreator DSL.
 * @returns {Promise<Object>} The constants object.
 */
export const getAppCreatorConstants = async () => {
	const constants = {
		enabled: true,
		_id: "appcreator",
		name: "AppCreator",
		short: "appcreator",
		description: "Tool for visually creating system applications.",
		logoUrl: AppcreatorLight,
		logoUrlDark: AppcreatorDark,
		insertableEntities: {},
		shortDescription: await getDocumentation(),
		documentation: await getDocumentation(),
		subtypes: [],
		hasValidation: true,
		type: "Graphical",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: false,
		generationLanguage: "",
		supportsDeployment: true,
		deploymentStoppable: true,
		generations: ["Default"],
		keywords: [],
		llmSupport: false,
		tags: ["CPS", "Graphical"],
		// autosave: false,
		initiallyCollapsed: true,
		deploymentInstructions: (
			<>
				<Typography color="white" sx={{ mb: 2, fontSize: "1.1rem" }}>
					{"You are about to deploy this model. Please follow the instructions below: "}
				</Typography>
				<Typography color="white" sx={{ fontSize: "1.1rem" }}>
					{"1. Open and clone the following notebook in Google Colab: "}
				</Typography>
				<a href="https://colab.research.google.com/drive/1WTsEktvQwrfAWsXb7jHXbYR7MrCYlF6O?usp=sharing" target="_blank" rel="noreferrer" style={{ color: "inherit" }}>
					{"https://colab.research.google.com/drive/1WTsEktvQwrfAWsXb7jHXbYR7MrCYlF6O?usp=sharing"}
				</a>
				<Typography color="white" sx={{ mt: 2, fontSize: "1.1rem" }}>
					{"2. In the first line of the notebook, replace the UID value with the following ID: "}
				</Typography>
				<Typography color="white" sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
					{jwt.decode().id}
				</Typography>
				<Typography color="white" sx={{ mt: 2, fontSize: "1.1rem" }}>
					{"3. Run the notebook and wait until you see the 'READY !!!' message. After that, you can go back to LocSys."}
				</Typography>
				<Typography color="white" sx={{ mt: 2, fontSize: "1.1rem" }}>
					{"4. At the AppCreator page, at the top right, you should see a green light next to a a message saying 'AppCreator deployer is up'. If you see this message, you can click the 'Continue' button and the application will be executed."}
				</Typography>
			</>
		),
	};
	return constants;
};
