import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Random number node

This node calculates a random integer number and assigns it in an existing variable. You must declare the minimum and maximum of the range, and the selections follows a uniform distribution. An example follows:

![alt text](${transformAsset("random-integer.png")})

Here, variable \`x\` is assigned a random integer from 0 to 1, and after execution, the value is close to 2, as evident from the logs and the Live Variables section.
`;

export default documentation;
