import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# GoalDSL nodes

GoalDSL is an external Domain-Specific Language (DSL) for the behaviour verification of IoT-enabled CPS applications and systems, based on a goal-driven approach. The general idea is that goal-driven rules can be defined for entities (smart objects, virtual artefacts, etc.) in a CPS, a smart environment or a digital twin.

The AppCreator is directly integrated with GoalDSL, meaning that you can create a GoalDSL model that verifies the behavior of your application, and use it in your AppCreator model. 

The GoalDSL toolbox offers two nodes, \`Deploy GoalDSL model\` and \`Stop GoalDSL model\`, as evident from the image below:

![alt text](${transformAsset("goaldsl-toolbox.png")})

The \`Deploy GoalDSL model\` node offers a dropdown containing all the GoalDSL models you have access to (either you are the creator or they exist in a LocSys project that you participate). If you declare one GoalDSL model, and you have the GoalDSL deployer up (see instructions when you press Deploy in the AppCreator model), the verification checks will start and the goals will start dispatching in the AppCreator page, like so:

![alt text](${transformAsset("goaldsl-goals.png")})

If you used the \`Deploy GoalDSL model\` don't forget to declare a \`Stop GoalDSL model\` at the end of your application, to stop the GoalDSL deployer gracefully.
`;

export default documentation;
