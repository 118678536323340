import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Execute tab node

The execute tab node allows you to execute a tab at a specific point of the application. Yes, we support tabs as sub-flows. At the following image you can see that a "Main tab" always exists, and you can create more tabs at will, rename them, clone them or delete them.

![alt text](${transformAsset("subflow.png")})

Each tab must have only one Start node and one End node. What happens is that when you use the Execute tab node and declare a tab, its contents are executed at this point. For example, we have a tab named \`TAB 1\`:

![alt text](${transformAsset("tab-1.png")})

and the main tab which uses the Execute tab functionality:

![alt text](${transformAsset("tab-main.png")})

When you execute the app you will get this:

![alt text](${transformAsset("tab-executed.png")})

meaning that Tab 1 was executed, and the log inside it was executed as well. 
`;

export default documentation;
