import { shortDescription } from "./demol/short-description.js";
import { getDocumentation } from "./demol/documentation.js";
import { example } from "./demol/example.js";

import DemolLight from "../assets/dsls-logos/demol_L.png";
import DemolDark from "../assets/dsls-logos/demol_D.png";

/**
 * Retrieves the constants for the DeMoL DSL.
 * @returns {Promise<Object>} The constants object.
 */
export const getDemolConstants = async () => {
	const constants = {
		enabled: true,
		_id: "demol",
		name: "DeMoL",
		short: "demol",
		description: "Device Modeling Language (DeMoL) - A DSL for modeling IoT devices. Enables automated source code generation currently for RaspberryPi and RiotOS.",
		logoUrl: DemolLight,
		logoUrlDark: DemolDark,
		insertableEntities: {
			Example: example,
		},
		shortDescription,
		documentation: await getDocumentation(),
		subtypes: ["device", "peripheral"],
		hasValidation: true,
		type: "Textual",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: true,
		supportsDeployment: false,
		deploymentStoppable: false,
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		generationLanguage: "",
		keywords: [],
		llmSupport: false,
		tags: ["CPS", "Textual"],
		initiallyCollapsed: false,
		experimental: true,
	};
	return constants;
};
