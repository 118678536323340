import startDocumentation from "./toolboxes-documentation/start.js";
import endDocumentation from "./toolboxes-documentation/end.js";
import delayDocumentation from "./toolboxes-documentation/delay.js";
import logDocumentation from "./toolboxes-documentation/log.js";
import conditionDocumentation from "./toolboxes-documentation/condition.js";
import randomDocumentation from "./toolboxes-documentation/random.js";
import createVariableDocumentation from "./toolboxes-documentation/create-variable.js";
import createVariablesDocumentation from "./toolboxes-documentation/create-variables.js";
import setVariableDocumentation from "./toolboxes-documentation/set-variable.js";
import createListDocumentation from "./toolboxes-documentation/create-list.js";
import manageListDocumentation from "./toolboxes-documentation/manage-list.js";
import listOperationDocumentation from "./toolboxes-documentation/list-operation.js";
import threadSplitDocumentation from "./toolboxes-documentation/thread-split.js";
import threadJoinDocumentation from "./toolboxes-documentation/thread-join.js";
import killProcessDocumentation from "./toolboxes-documentation/kill-process.js";
import randomNumberDocumentation from "./toolboxes-documentation/random-number.js";
import randomIntegerDocumentation from "./toolboxes-documentation/random-integer.js";
import executeTabDocumentation from "./toolboxes-documentation/execute-tab.js";
import goaldslStartStopDocumentation from "./toolboxes-documentation/goaldsl-start-stop.js";
import operationsBetweenListsDocumentation from "./toolboxes-documentation/lists-operations.js";

export const systemToolboxes = [
	{
		name: "Utilities",
		backgroundColor: "#666",
		fontColor: "#fff",
		nodes: [
			{
				name: "Start",
				inputs: 0,
				outputs: 1,
				parameters: [
					{
						id: "delay",
						type: "input",
						label: "Artificial delay",
						value: 0.001,
						required: true,
						show: true,
					},
				],
				documentation: startDocumentation,
			},
			{
				name: "End",
				inputs: 1,
				outputs: 0,
				documentation: endDocumentation,
			},
			{
				name: "Delay",
				inputs: 1,
				documentation: delayDocumentation,
				parameters: [
					{
						id: "time",
						type: "input",
						label: "Time (in seconds)",
						value: 1,
						required: true,
						show: true,
					},
				],
			},
			{
				name: "Log",
				inputs: 1,
				documentation: logDocumentation,
				parameters: [
					{
						id: "message",
						type: "input",
						label: "Message",
						required: true,
						show: true,
					},
				],
			},
			{
				name: "Condition",
				outputs: 2,
				documentation: conditionDocumentation,
				parameters: [
					{
						id: "condition_0",
						type: "input",
						label: "Condition #0",
						required: true,
						value: "False",
					},
					{
						id: "default",
						type: "input",
						label: "Else",
						value: "Go to last output",
						locked: true,
					},
				],
			},
			{
				name: "Random selection",
				outputs: 2,
				documentation: randomDocumentation,
				parameters: [
					{
						id: "probability_0",
						type: "input",
						label: "Probability #0",
						required: true,
						value: 0.5,
						show: true,
					},
					{
						id: "probability_1",
						type: "input",
						label: "Probability #1",
						required: true,
						value: 0.5,
						show: true,
					},
				],
			},
			{
				name: "Create variable",
				visibleParameters: true,
				inputs: 1,
				documentation: createVariableDocumentation,
				parameters: [
					{
						id: "variable",
						type: "input",
						label: "Variable name :",
						required: true,
						show: true,
						value: `variable`,
					},
					{
						id: "value",
						type: "input",
						label: "Initial value = ",
						required: true,
						show: true,
						value: 0,
					},
				],
			},
			{
				name: "Create variables",
				visibleParameters: true,
				inputs: 1,
				documentation: createVariablesDocumentation,
				parameters: [
					{
						id: "variable",
						type: "input-pair",
						label: "New variables",
						required: true,
						show: true,
						value: null,
					},
				],
			},
			{
				name: "Set variable",
				inputs: 1,
				documentation: setVariableDocumentation,
				parameters: [
					{
						id: "variable",
						type: "variable",
						label: "Variable name : ",
						required: true,
						show: true,
					},
					{
						id: "value",
						type: "input",
						label: "Value = ",
						required: true,
						show: true,
						value: "value",
					},
				],
			},
			{
				name: "Random number",
				inputs: 1,
				documentation: randomNumberDocumentation,
				parameters: [
					{
						id: "variable",
						type: "variable",
						label: "Set to variable : ",
						required: true,
						show: true,
					},
					{
						id: "minimum",
						type: "input",
						label: "Minimum = ",
						required: true,
						show: true,
						value: "0",
					},
					{
						id: "maximum",
						type: "input",
						label: "Maximum = ",
						required: true,
						show: true,
						value: "1",
					},
				],
			},
			{
				name: "Random integer",
				inputs: 1,
				documentation: randomIntegerDocumentation,
				parameters: [
					{
						id: "variable",
						type: "variable",
						label: "Set to variable : ",
						required: true,
						show: true,
					},
					{
						id: "minimum",
						type: "input",
						label: "Minimum = ",
						required: true,
						show: true,
						value: "0",
					},
					{
						id: "maximum",
						type: "input",
						label: "Maximum = ",
						required: true,
						show: true,
						value: "10",
					},
				],
			},
			{
				name: "Create List",
				visibleParameters: true,
				inputs: 1,
				documentation: createListDocumentation,
				parameters: [
					{
						id: "List",
						type: "input",
						label: "List name :",
						required: true,
						show: true,
						value: `value`,
					},
					{
						id: "values",
						type: "listInput",
						label: "Values:",
						show: true,
						value: "",
					},
				],
			},
			{
				name: "Manage list",
				visibleParameters: true,
				inputs: 1,
				documentation: manageListDocumentation,
				parameters: [
					{
						id: "List",
						type: "list",
						label: "List name : ",
						required: true,
						show: true,
					},
					{
						id: "values",
						type: "select",
						label: "Operation : ",
						required: true,
						show: true,
						options: [
							{ value: "Pop", label: "Pop" },
							{ value: "Push", label: "Push" },
							{ value: "Sort Ascending", label: "Sort Ascending" },
							{ value: "Sort Descending", label: "Sort Descending" },
							{ value: "Delete by index", label: "Delete by index" },
							{ value: "Delete All", label: "Delete All" },
							{ value: "Delete by value", label: "Delete by value" },
							{ value: "Set element by index", label: "Set element by index" },
						],
						value: "",
						depender: true,
					},
					{
						id: "Push_value",
						type: "dependent-input",
						label: "Value to push",
						show: true,
						value: ``,
						dependedValue: "Push",
					},
					{
						id: "Delete_index",
						type: "dependent-input",
						label: "Index to delete",
						show: true,
						value: ``,
						dependedValue: "Delete by index",
					},
					{
						id: "Delete_value",
						type: "dependent-input",
						label: "Value to delete",
						show: true,
						value: ``,
						dependedValue: "Delete by value",
					},
					{
						id: "Element_index_for_value",
						type: "dependent-input",
						label: "Element index",
						show: true,
						value: ``,
						dependedValue: "Set element by index",
					},
					{
						id: "Element_value_by_index",
						type: "dependent-input",
						label: "Value to set",
						show: true,
						value: ``,
						dependedValue: "Set element by index",
					},
				],
			},
			{
				name: "List operation",
				visibleParameters: true,
				inputs: 1,
				documentation: listOperationDocumentation,
				parameters: [
					{
						id: "List",
						type: "list",
						label: "List name : ",
						required: true,
						show: true,
						value: ``,
					},
					{
						id: "Variable",
						type: "variable",
						label: "Variable name : ",
						required: true,
						show: true,
						value: ``,
					},
					{
						id: "operation",
						type: "select",
						label: "Operation :",
						required: true,
						show: true,
						options: [
							{ value: "Max", label: "Max" },
							{ value: "Min", label: "Min" },
							{ value: "Average", label: "Average" },
							{ value: "Standard Deviation", label: "Standard Deviation" },
							{ value: "Get element by index", label: "Get element by index" },
							{ value: "Get index of element", label: "Get index of element" },
							{ value: "Includes", label: "Includes" },
							{ value: "Length", label: "Length" },
							{ value: "Element count", label: "Element count" },
						],
						value: "",
						depender: true,
					},
					{
						id: "Includes_value",
						type: "dependent-input",
						label: "Value to check",
						show: true,
						value: ``,
						dependedValue: "Includes",
					},
					{
						id: "Element_value",
						type: "dependent-input",
						label: "Value to count",
						show: true,
						value: ``,
						dependedValue: "Element count",
					},
					{
						id: "Index_value",
						type: "dependent-input",
						label: "Index of element",
						show: true,
						value: ``,
						dependedValue: "Get element by index",
					},
					{
						id: "Element_value",
						type: "dependent-input",
						label: "Element value",
						show: true,
						value: ``,
						dependedValue: "Get index of element",
					},
				],
			},
			{
				name: "Operations between lists",
				visibleParameters: true,
				inputs: 1,
				documentation: operationsBetweenListsDocumentation,
				parameters: [
					{
						id: "Source_list",
						type: "list",
						label: "Source List: ",
						required: true,
						show: true,
					},
					{
						id: "Target_list",
						type: "list",
						label: "Target List: ",
						required: true,
						show: true,
					},
					{
						id: "operation",
						type: "select",
						label: "Operation :",
						required: true,
						show: true,
						options: [
							{ value: "Copy", label: "Copy" },
							{ value: "Append", label: "Append" },
						],
						value: "",
					},
				],
			},
			{
				name: "Thread split",
				documentation: threadSplitDocumentation,
				inputs: 1,
				outputs: 2,
			},
			{
				name: "Thread join",
				documentation: threadJoinDocumentation,
				inputs: 2,
				outputs: 1,
			},
			{
				name: "Kill process",
				documentation: killProcessDocumentation,
				inputs: 1,
				outputs: 1,
				parameters: [
					{
						id: "thread",
						type: "thread",
						label: "Thread to kill :",
						required: true,
						show: true,
					},
				],
			},
			{
				name: "Execute Tab",
				visibleParameters: true,
				inputs: 1,
				documentation: executeTabDocumentation,
				parameters: [
					{
						id: "Tab",
						type: "tab",
						label: "Tab name :",
						required: true,
						show: true,
						value: `value`,
					},
				],
			},
			{
				name: "Comment",
				visibleParameters: true,
				documentation: executeTabDocumentation,
				inputs: 0,
				outputs: 0,
				parameters: [
					{
						id: "comment",
						type: "input",
						label: "Comment :",
						required: false,
						show: true,
						value: "",
					},
				],
			},
		],
	},
	{
		name: "GoalDSL toolbox",
		backgroundColor: "#907",
		fontColor: "#fff",
		nodes: [
			{
				name: "Deploy GoalDSL model",
				type: "start_goaldsl",
				documentation: goaldslStartStopDocumentation,
				inputs: 1,
				outputs: 1,
				parameters: [{
					id: "goaldsl_model",
					type: "goaldsl_model",
					label: "GoalDSL Model",
					value: null,
					required: true,
					show: true,
				}],
			},
			{
				id: "stop_goaldsl",
				name: "Stop GoalDSL model",
				type: "stop_goaldsl",
				documentation: goaldslStartStopDocumentation,
				inputs: 1,
				outputs: 1,
			},
		],
	},
];
