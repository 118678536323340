const documentation = `# Start node

The Start node must exist in each application, since the deployment starts from there. Its configuration has one property called \`Artificial delay\`. This is measured in seconds and denotes a delay that will be added after each node's execution. Use this in order to have time to see the actual flow. 

Fun fact: you can have multiple Start nodes in your application. This means that the flows starting from these nodes will be initialized and executed in parallel.

Note: Even if you declare an artificial delay of 0, the AppCreator deployer will enforce a minimum delay of 0.001 in order to internal stuff to properly work!
`;

export default documentation;
