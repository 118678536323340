/* eslint-disable react/jsx-indent */
/* eslint indent: "off", "react/jsx-indent-props": "off" */

import { useEffect, useState } from "react";

import {
    Grid,
    Typography,
    Select,
    MenuItem,
} from "@mui/material";

import { useSelector } from 'react-redux';

const ParametersTab = ({ param, parameters, setParameters }) => {
    const [tab, setTab] = useState(parameters.find((p) => p.id === param.id).value);
    const tabs = useSelector((state) => state.tabs);

    useEffect(() => {
        setTab(parameters.find((p) => p.id === param.id).value);
    }, [parameters]);

    return (
        <Grid
            item
            container
            display="flex"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                marginBottom: "5px",
                width: "100%",
                "& .MuiInputBase-input": {
                    color: "black !important",
                },
            }}
        >
            <Typography sx={{ marginRight: "10px", width: "40%" }}>
                {param.label}
            </Typography>
            <Select
                variant="filled"
                sx={{
                    width: "55%",
                }}
                value={tab ?? ""}
                onChange={(e) => {
                    const newParams = parameters.map((p) => {
                        if (p.id === param.id) {
                            return {
                                ...p,
                                value: e.target.value,
                            };
                        }

                        return p;
                    });
                    setParameters(newParams);
                }}
            >
                <MenuItem
                    key="none"
                    value=""
                >
                    {""}
                </MenuItem>
                {tabs?.map((t) => (
                    <MenuItem
                        key={`${t.tabName}`}
                        value={`${t.tabName}`}
                    >
                        {`${t.tabName}`}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    );
};

export default ParametersTab;
