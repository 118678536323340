import { MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
	primary_filled: {
		backgroundColor: theme.palette.primary.main,
		color: "black!important",
		borderRadius: "10px",
		borderBottom: "0px",
		"&, &:before, &:after": {
			borderBottom: "0px!important",
		},
		"&:hover": {
			backgroundColor: theme.palette.primaryDark.main,
			borderBottom: "0px",
		},
		"&:focus": {
			backgroundColor: theme.palette.primaryDark.main,
			borderBottom: "0px",
		},
		"&:before": {
			borderBottom: "0px",
		},
	},
	primary_outlined: {
		backgroundColor: "transparent",
		color: "white!important",
		borderColor: theme.palette.primary.main,
		borderRadius: "10px",
		borderBottom: "0px",
		"&, &:before, &:after": {
			borderBottom: "0px!important",
		},
		"&:hover": {
			backgroundColor: "transparent",
			borderBottom: "0px",
		},
		"&:focus": {
			backgroundColor: "transparent",
			borderBottom: "0px",
		},
		"&:before": {
			borderBottom: "0px",
		},
	},
	secondary_filled: {
		backgroundColor: theme.palette.secondary.main,
		color: "black!important",
		borderRadius: "10px",
		borderBottom: "0px",
		"&, &:before, &:after": {
			borderBottom: "0px!important",
		},
		"&:hover": {
			backgroundColor: theme.palette.secondaryDark.main,
			borderBottom: "0px",
		},
		"&:focus": {
			backgroundColor: theme.palette.secondaryDark.main,
			borderBottom: "0px",
		},
		"&:before": {
			borderBottom: "0px",
		},
	},
	secondary_outlined: {
		backgroundColor: "transparent",
		borderColor: theme.palette.secondary.main,
		borderRadius: "10px",
		borderBottom: "0px",
		"&, &:before, &:after": {
			borderBottom: "0px!important",
		},
		"&:hover": {
			backgroundColor: "transparent",
			borderBottom: "0px",
		},
		"&:focus": {
			backgroundColor: "transparent",
			borderBottom: "0px",
		},
		"&:before": {
			borderBottom: "0px",
		},
	},
	third_filled: {
		backgroundColor: theme.palette.third.main,
		color: "black!important",
		borderRadius: "10px",
		borderBottom: "0px",
		"&, &:before, &:after": {
			borderBottom: "0px!important",
		},
		"&:hover": {
			backgroundColor: theme.palette.thirdDark.main,
			borderBottom: "0px",
		},
		"&:focus": {
			backgroundColor: theme.palette.thirdDark.main,
			borderBottom: "0px",
		},
		"&:before": {
			borderBottom: "0px",
		},
	},
	third_outlined: {
		backgroundColor: "transparent",
		borderColor: "third",
		borderRadius: "10px",
		borderBottom: "0px",
		"&, &:before, &:after": {
			borderBottom: "0px!important",
		},
		"&:hover": {
			backgroundColor: "transparent",
			borderBottom: "0px",
		},
		"&:focus": {
			backgroundColor: "transparent",
			borderBottom: "0px",
		},
		"&:before": {
			borderBottom: "0px",
		},
	},
	icon: {
		fill: "black",
	},
}));

const Dropdown = ({
	id = "custom-dropdown",
	size = "",
	placeholder = "Placeholder",
	filled = true,
	color = "black",
	background = "secondary",
	showPlaceholder = true,
	width = "",
	items = [],
	value: propsValue,
	onChange,
	iconColor = "black",
}) => {
	const classes = useStyles();
	const [value, setValue] = useState(propsValue);

	useEffect(() => {
		setValue(propsValue);
	}, [propsValue]);

	return (
		<Select
			id={id}
			value={value}
			displayEmpty={showPlaceholder}
			className={classes[`${background}_${(filled ? "filled" : "outlined")}`]}
			color={background}
			size={size}
			style={{ color, width, height: "100%" }}
			autoWidth={!width}
			classes={{
				filled: classes[`${background}_${(filled ? "filled" : "outlined")}`],
				iconFilled: classes[`${background}_${(filled ? "filled" : "outlined")}`],
			}}
			sx={{
				">.MuiOutlinedInput-notchedOutline": {
					border: filled ? "none" : "1px solid",
					borderColor: `${background}.main`,
				},
				"& .MuiSelect-icon": {
					color: iconColor || "inherit",
				},
			}}
			renderValue={(selected) => (selected || placeholder)}
			MenuProps={{
				sx: {
					"&& .MuiList-root": {
						background: "#DDE8EF",
					},
				},
			}}
			onChange={onChange}
		>
			{items.map((it) => (
				<MenuItem key={it.text} value={it.value}>{it.text}</MenuItem>
			))}
			{showPlaceholder && items.length === 0 && <MenuItem disabled value="">{"No options"}</MenuItem>}
		</Select>
	);
};

export default Dropdown;
