/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-indent */
/* eslint indent: "off", "react/jsx-indent-props": "off" */

import { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    Paper,
    Box,
} from "@mui/material";

const ParametersInputPair = ({ param, parameters, setParameters, nodeData }) => {
    const [variables, setVariables] = useState([]);
    const [focusIndex, setFocusIndex] = useState(null);
    const inputRefs = useRef([]);

    useEffect(() => {
        const paramValue = parameters.find((p) => p.id === param.id)?.value || '';
        try {
            const parsed = paramValue ? JSON.parse(paramValue) : [];
            setVariables(parsed.length > 0 ? parsed : [{ name: '', value: '' }]);
        } catch {
            setVariables([{ name: '', value: '' }]);
        }
    }, [param.id, parameters]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, variables.length * 2);
    }, [variables]);

    const updateParameters = (newVars) => {
        const newParams = parameters.map((p) => {
            if (p.id === param.id) {
                return {
                    ...p,
                    value: JSON.stringify(newVars),
                };
            }

            return p;
        });

        setParameters(newParams);
    };

    const handleVariableChange = (index, field, value) => {
        const newVars = [...variables];
        // eslint-disable-next-line unicorn/better-regex
        const pythonicRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
        if (field === 'name'
            && ((nodeData?.label === "Create variable" && param?.label === "Variable name")
                || (nodeData?.label === "List operation" && param?.label === "Variable name")
                || (nodeData?.label === "Create List" && param?.label === "List name")) && !pythonicRegex.test(value)) {
            return;
        }

        if (field === "name" && param?.label === "Create new variables" && !pythonicRegex.test(value)) {
            return;
        }

        newVars[index][field] = value;
        setVariables(newVars);

        updateParameters(newVars);
    };

    const handleKeyDown = (e, index, field) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            const newVars = [...variables];
            newVars.splice(index + 1, 0, { name: '', value: '' });

            setVariables(newVars);

            updateParameters(newVars);

            setTimeout(() => {
                setFocusIndex((index + 1) * 2);
            }, 0);
        } else if (e.key === 'Delete'
            && variables.length > 1) {
            e.preventDefault();

            const newVars = [...variables];
            newVars.splice(index, 1);

            setVariables(newVars);

            updateParameters(newVars);

            if (index > 0) {
                setTimeout(() => {
                    setFocusIndex((index - 1) * 2 + (field === 'name' ? 0 : 1));
                }, 0);
            }
        } else if (e.key === 'Tab') {
            if (field === 'name' && !e.shiftKey) {
                e.preventDefault();
                setFocusIndex(index * 2 + 1);
            } else if (field === 'value' && e.shiftKey) {
                e.preventDefault();
                setFocusIndex(index * 2);
            } else if (field === 'value' && !e.shiftKey) {
                e.preventDefault();
                if (index === variables.length - 1) {
                    const newVars = [...variables, { name: '', value: '' }];

                    setVariables(newVars);

                    updateParameters(newVars);

                    setTimeout(() => {
                        setFocusIndex((index + 1) * 2);
                    }, 0);
                } else {
                    setFocusIndex((index + 1) * 2);
                }
            } else if (field === 'name' && e.shiftKey && index > 0) {
                e.preventDefault();
                setFocusIndex((index - 1) * 2 + 1);
            }
        }
    };

    useEffect(() => {
        if (focusIndex !== null && inputRefs.current[focusIndex]) {
            inputRefs.current[focusIndex].focus();
            setFocusIndex(null);
        }
    }, [focusIndex, variables]);

    const handleEmptyClick = () => {
        const newVars = [{ name: '', value: '' }];
        setVariables(newVars);
        updateParameters(newVars);
        setTimeout(() => {
            setFocusIndex(0);
        }, 0);
    };

    return (
        <Grid
            item
            container
            display="flex"
            direction="column"
            sx={{
                marginBottom: "5px",
                width: "100%",
            }}
        >
            <Typography sx={{ marginBottom: "10px" }}>
                {param.label}
            </Typography>

            <Paper
                elevation={0}
                variant="outlined"
                sx={{
                    padding: 2,
                    backgroundColor: "#f5f5f5",
                    width: "100%",
                    minHeight: "120px",
                    position: "relative",
                }}
            >
                {variables.length > 0 && variables.map((variable, index) => (
                    <Box
                        key={`var-${index}-${variables.length}`}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 1,
                            "& input": {
                                border: "none",
                                backgroundColor: "transparent",
                                fontFamily: "monospace",
                                fontSize: "14px",
                                outline: "none",
                                padding: "6px",
                                width: "100%",
                            },
                            "&:hover": {
                                backgroundColor: "#e3e3e3",
                                borderRadius: "4px",
                            },
                        }}
                    >
                        <Typography sx={{ width: "30px", color: "#666" }}>
                            {index + 1}
                            {"."}
                        </Typography>
                        <input
                            ref={(el) => inputRefs.current[index * 2] = el}
                            value={variable.name}
                            placeholder="variable"
                            onChange={(e) => handleVariableChange(index, 'name', e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, index, 'name')}
                        />

                        <Typography sx={{ mx: 1 }}>{"="}</Typography>

                        <input
                            ref={(el) => inputRefs.current[index * 2 + 1] = el}
                            value={variable.value}
                            placeholder="value"
                            style={{ flexGrow: 1 }}
                            onChange={(e) => handleVariableChange(index, 'value', e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, index, 'value')}
                        />
                    </Box>
                ))}

                {variables.length === 0 && (
                    <Typography sx={{ color: "#999", fontStyle: "italic", padding: 1 }}>
                        {"Click to add variables"}
                    </Typography>
                )}

                {variables.length === 0 && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            cursor: "text",
                        }}
                        onClick={handleEmptyClick}
                    />
                )}
            </Paper>

            <Typography variant="caption" sx={{ mt: 1, color: "#666" }}>
                {"Press Enter to add a new variable, Delete to remove a variable"}
            </Typography>
        </Grid>
    );
};

export default ParametersInputPair;
