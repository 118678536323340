/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import { memo, useState, useMemo } from "react";
import {
    Box,
    Typography,
    Button,
} from "@mui/material";
import Form from "./Form.js";
import Spinner from "./Spinner.js";
import { adminChangeClonedModelsText } from "../api/index.js";
import { useSnackbar } from "../utils/index.js";
import Table from "./Table.js";

const AdminModelText = () => {
    const [modelId, setModelId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modelsThatChanged, setModelsThatChanged] = useState([]);
    const [step, setStep] = useState(1);

    const { success, error } = useSnackbar();

    const formContent = [
        {
            customType: "input",
            id: "modelId",
            type: "input",
            multiline: false,
            width: 400,
            placeholder: "Model Id",
            required: true,
        },
        {
            customType: "button",
            id: "submit",
            type: "submit",
            text: "Submit",
        },
    ];

    const tableColumns = useMemo(() => [
        {
            Header: <Typography id="model_id" variant="h6">{"Model ID"}</Typography>,
            accessor: "modelId",
            id: "modelId",
            filterable: false,
            minWidth: 200,
            Cell: ({ value }) => (
                <Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
                    <Typography color={value.toLowerCase() === "active" ? "success.main" : (value.toLowerCase() === "inactive" ? "error.main" : "black")}>{value}</Typography>
                </Box>
            ),
        },
        {
            Header: <Typography variant="h6">{"Creator"}</Typography>,
            accessor: "creatorFullname",
            id: "creatorFullname",
            filterable: false,
            minWidth: 250,
            maxWidth: 380,
            Cell: ({ value }) => (
                <Box sx={{ display: "flex", ml: 1, alignItems: "center", justifyContent: "center" }}>
                    <Typography color={value.toLowerCase() === "active" ? "success.main" : (value.toLowerCase() === "inactive" ? "error.main" : "black")}>{value}</Typography>
                </Box>
            ),
        },
    ], []);

    const handleSubmit = async (values) => {
        setIsLoading(true);
        setModelId(values?.modelId);
        const { success: scs, message, updatedModels } = await adminChangeClonedModelsText(values?.modelId);
        if (scs) {
            success(message);
            setModelsThatChanged(updatedModels);
            setStep(2);
        } else {
            error(message);
        }

        setIsLoading(false);
        console.log("Submitted Model ID:", values);
    };

    return (
        <>
            <Spinner open={isLoading} />
            {step === 1 && (
                <Box sx={{ mt: 3, p: 3, maxWidth: 500, mx: "auto", textAlign: "center", backgroundColor: "third.main" }}>
                    <Typography gutterBottom variant="h5" sx={{ color: "primary.main" }}>
                        {"Admin - Update Model Text"}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3, color: "primary.main" }}>
                        {"Enter a Model ID below. The model text of the specified model"}
                        {"will be set as the modelText for all models that have the given"}
                        {"modelId as their first storeOrigin ID."}
                    </Typography>
                    <Form content={formContent} onSubmit={handleSubmit} />
                </Box>
            )}
            {step === 2 && (
                <Box sx={{ mt: 3, p: 3, maxWidth: 800, mx: "auto", textAlign: "center", backgroundColor: "third.main" }}>
                    <Typography gutterBottom variant="h5" sx={{ color: "primary.main" }}>
                        {"Model Text Updated"}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3, color: "primary.main" }}>
                        {`The modelText from model with ID: ${modelId} `}
                        {"has been successfully applied to the following models."}
                    </Typography>
                    <Table
                        data={modelsThatChanged}
                        noDataText={isLoading ? "Fetching data..." : "No models were updated."}
                        columns={tableColumns}
                    />
                    {/* Return Button */}
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={() => setStep(1)}
                    >
                        {"Return"}
                    </Button>
                </Box>
            )}
        </>
    );
};

export default memo(AdminModelText);
