/* eslint-disable no-continue */
/* eslint-disable jsx-a11y/media-has-caption */
import { Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useResizeDetector } from "react-resize-detector";
import { LightMode, Opacity, Science, Thermostat } from "@mui/icons-material";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import Accordion from "../Accordion.js";
import { getElementDimension, getMapDimensions } from "../create-map/utilities.js";
import Slider from "../Slider.js";
import Switch from "../Switch.js";
import availableItems from "./items/index.js";
import Item from "./items/item.js";
import DoubleItem from "./items/double-item.js";
import { connect, connectToGeneralBroker, disconnect, disconnectFromGeneralBroker, subscribeToQueue } from "../../utils/websocket.js";
import useSnackbar from "../../utils/use-snackbar.js";
import jwt from "../../utils/jwt.js";
import Spinner from "../Spinner.js";
import categories from "../create-map/categories/index.js";
import { SecondaryBackgroundButton } from "../Buttons.js";
import Popup from "../Popup.js";
import Form from "../Form.js";
import Tooltip from "../Tooltip.js";
import Sparks from "./items/sparks.js";
import RobotCrashAudio from "../../assets/audios/robot_crash.mp3";
import HumanCrashMaleAudio from "../../assets/audios/human_crash_male.mp3";
import HumanCrashFemaleAudio from "../../assets/audios/human_crash_female.mp3";
import HumanCrashNoneAudio from "../../assets/audios/human_crash_none.mp3";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100%",
		padding: "0px",
		margin: "0px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		overflow: "hidden",
		position: "relative",
	},
	mapRoot: {
		width: "100%",
		height: "100%",
		padding: "0px",
		margin: "0px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(255, 255, 255, 0.1)",
		position: "relative",
		zIndex: 0,
	},
	controlsRoot: {
		width: "100%",
		height: "100%",
		padding: "0px",
		margin: "0px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		color: "white",
		overflowY: "auto",
		overflowX: "hidden",
	},
	map: {
		padding: "0px",
		margin: "0px",
		backgroundColor: "white",
		position: "relative",
	},
	sliderBox: {
		width: "100%",
		padding: "0px 20px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	optionsRow: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	divider: {
		width: "90%",
		margin: "10px 0px",
		backgroundColor: theme.palette.greyDark.main,
	},
	tilesBox: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-evenly",
		alignItems: "center",
	},
	menuTile: {
		width: "70px",
		margin: "5px",
		cursor: "move",
	},
	draggingTile: {
		width: "30px",
		height: "30px",
		borderRadius: "30px",
		backgroundColor: theme.palette.secondary.main,
		opacity: 0.6,
		position: "fixed",
		cursor: "move",
		zIndex: 1,
		transform: "translate(-50%, -50%)",
	},
	lightShadow: {
		width: "100%",
		height: "100%",
		zIndex: 100,
		position: "absolute",
		top: 0,
		left: 0,
		mixBlendMode: "hard-light",
		pointerEvents: "none",
	},
	environmentalIndications: {
		zIndex: 100,
		position: "absolute",
		bottom: 0,
		right: 0,
		padding: "10px",
		backgroundColor: "rgb(3, 19, 32)",
		opacity: 0.7,
	},
}));

const INITIAL_MAP_METERS = 100;
const INITIAL_GRID_DIMENSION = 120;

const categoriesPrefix = {
	general: "gn",
	sensors: "sn",
	effectors: "ef",
};

const panTiltMappings = {
	panMin: "panMin",
	panMax: "panMax",
	tiltMin: "tiltMin",
	tiltMax: "tiltMax",
	panTiltMode: "mode",
	panTiltOperation: "operation",
	panTiltHz: "hz",
	panTiltStep: "step",
};

const toRadians = (angle) => (
	angle * (Math.PI / 180)
);

const toDegrees = (angle) => (
	angle * (180 / Math.PI)
);

const transformWater = (water, boxPosition, rotation, mapPosition, nextId, resolution) => {
	const xBox = boxPosition.width * (water.x / 100);
	const x2Box = boxPosition.width * ((water.x + water.range) / 100);
	const yBox = boxPosition.height * (water.y / 100);
	const newXBox = (xBox - (boxPosition.width / 2)) * Math.cos(toRadians(-rotation))
		- (yBox - (boxPosition.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPosition.width / 2);
	const newYBox = (xBox - (boxPosition.width / 2)) * Math.sin(toRadians(-rotation))
		+ (yBox - (boxPosition.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPosition.height / 2);
	const newX2Box = (x2Box - (boxPosition.width / 2)) * Math.cos(toRadians(-rotation))
		- (yBox - (boxPosition.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPosition.width / 2);
	const newY2Box = (x2Box - (boxPosition.width / 2)) * Math.sin(toRadians(-rotation))
		+ (yBox - (boxPosition.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPosition.height / 2);
	const xPx = newXBox + boxPosition.left - mapPosition.left;
	const x = (xPx / mapPosition.width) * 100;
	const yPx = newYBox - boxPosition.bottom + mapPosition.bottom;
	const y = (yPx / mapPosition.height) * 100;
	const x2Px = newX2Box + boxPosition.left - mapPosition.left;
	const y2Px = newY2Box - boxPosition.bottom + mapPosition.bottom;

	const rangePx = Math.sqrt((xPx - x2Px) * (xPx - x2Px) + (yPx - y2Px) * (yPx - y2Px));
	const range = rangePx * resolution;
	return {
		x, y, range, id: nextId,
	};
};

const transformPoi = (poi, boxPosition, rotation, mapPosition, nextId, poiName) => {
	const xBox = boxPosition.width * (poi.x / 100);
	const yBox = boxPosition.height * (poi.y / 100);
	const newXBox = (xBox - (boxPosition.width / 2)) * Math.cos(toRadians(-rotation))
		- (yBox - (boxPosition.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPosition.width / 2);
	const newYBox = (xBox - (boxPosition.width / 2)) * Math.sin(toRadians(-rotation))
		+ (yBox - (boxPosition.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPosition.height / 2);
	const xPx = newXBox + boxPosition.left - mapPosition.left;
	const x = (xPx / mapPosition.width) * 100;
	const yPx = newYBox - boxPosition.bottom + mapPosition.bottom;
	const y = (yPx / mapPosition.height) * 100;

	return {
		x, y, id: nextId, name: poiName,
	};
};

const convertVariables = (variables) => {
	const newVariables = {};
	for (const [key, value] of Object.entries(variables)) {
		if (panTiltMappings[key]) {
			newVariables[panTiltMappings[key]] = value;
		}
	}

	return newVariables;
};

let autoItemsFlag = false;

const properties = {
	temperature: {
		format: "°C",
		step: 1,
		precision: 3,
		min: -20,
		max: 100,
		constant: 22,
		minV: 15,
		maxV: 80,
		mean: 22,
		amplitude: 10,
		minS: 1,
		maxS: 5,
	},
	humidity: {
		format: "%",
		step: 1,
		precision: 3,
		min: 0,
		max: 100,
		constant: 60,
		minV: 10,
		maxV: 90,
		mean: 60,
		amplitude: 50,
		minS: 1,
		maxS: 5,
	},
	luminosity: {
		format: "%",
		step: 1,
		precision: 3,
		min: 0,
		max: 100,
		constant: 80,
		minV: 10,
		maxV: 90,
		mean: 60,
		amplitude: 50,
		minS: 1,
		maxS: 5,
	},
	ph: {
		format: "",
		step: 0.1,
		precision: 3,
		min: 0,
		max: 14,
		constant: 7,
		minV: 5,
		maxV: 9,
		mean: 7,
		amplitude: 7,
		minS: 0.1,
		maxS: 5,
	},
};

const environmentalProperties = (element) => ({
	operation: {
		type: "select",
		name: "Operation Type",
		value: "constant",
		options: ["constant", "random", "triangle", "normal", "sinus"],
	},
	constant: {
		type: "number",
		name: "Constant Value",
		value: properties[element].constant,
		min: properties[element].min,
		max: properties[element].max,
		step: properties[element].step,
		precision: properties[element].precision,
		format: properties[element].format,
		needs: { operation: ["constant"] },
	},
	min: {
		type: "number",
		name: "Min Value",
		value: properties[element].minV,
		min: properties[element].min,
		max: "max",
		step: properties[element].step,
		precision: properties[element].precision,
		format: properties[element].format,
		needs: { operation: ["random", "triangle"] },
	},
	max: {
		type: "number",
		name: "Max Value",
		value: properties[element].maxV,
		min: "min",
		max: properties[element].max,
		step: properties[element].step,
		precision: properties[element].precision,
		format: properties[element].format,
		needs: { operation: ["random", "triangle"] },
	},
	step: {
		type: "number",
		name: "Step Value",
		value: properties[element].step,
		min: properties[element].minS,
		max: properties[element].maxS,
		step: properties[element].step,
		precision: properties[element].precision,
		format: properties[element].format,
		needs: { operation: ["triangle", "sinus"] },
	},
	mean: {
		type: "number",
		name: "Mean Value",
		value: properties[element].mean,
		min: properties[element].min,
		max: properties[element].max,
		step: properties[element].step,
		precision: properties[element].precision,
		format: properties[element].format,
		needs: { operation: ["normal"] },
	},
	std: {
		type: "number",
		name: "Standard Deviation",
		value: 1,
		min: 0,
		step: 0.1,
		precision: 3,
		needs: { operation: ["normal"] },
	},
	dc: {
		type: "number",
		name: "DC Value",
		value: properties[element].mean,
		min: properties[element].min,
		max: properties[element].max,
		step: properties[element].step,
		precision: properties[element].precision,
		format: properties[element].format,
		needs: { operation: ["sinus"] },
	},
	amplitude: {
		type: "number",
		name: "Amplitude Value",
		value: properties[element].amplitude,
		min: properties[element].min,
		max: properties[element].max,
		step: properties[element].step,
		precision: properties[element].precision,
		format: properties[element].format,
		needs: { operation: ["sinus"] },
	},
});

const CreateMission = forwardRef(({
	model: propsModel = null,
	dbitem: propsDbItem = null,
	inSimulation: propsInSimulation = false,
	isEditable: propsIsEditable = true,
	inPopup: propsInPopup = false,
	modelUpdate: propsModelUpdate = () => {},
}, ref) => {
	const classes = useStyles();
	const { success, error, info, warning } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);

	const [nRows, setNRows] = useState(6);
	const [nCols, setNCols] = useState(9);
	const [boxes, setBoxes] = useState({});
	const [grid, setGrid] = useState([]);
	const [autoWalls, setAutoWalls] = useState([]);

	const [mapWidth, setMapWidth] = useState(100);
	const [mapHeight, setMapHeight] = useState(80);
	const [gridDimension, setGridDimension] = useState(INITIAL_GRID_DIMENSION);
	const [gridMeters, setGridMeters] = useState((INITIAL_MAP_METERS / mapWidth) * INITIAL_GRID_DIMENSION);
	const [resolution, setResolution] = useState(gridMeters / INITIAL_GRID_DIMENSION);
	const [mapWidthMeters, setMapWidthMeters] = useState(INITIAL_MAP_METERS);
	const [temperature, setTemperature] = useState(environmentalProperties("temperature"));
	const [humidity, setHumidity] = useState(environmentalProperties("humidity"));
	const [luminosity, setLuminosity] = useState(environmentalProperties("luminosity"));
	const [ph, setPh] = useState(environmentalProperties("ph"));
	const [environmentPopupOpen, setEnvironmentPopupOpen] = useState(false);
	const [environmentTabStep, setEnvironmentTabStep] = useState(0);
	const [tmpEnvironmentalValues, setTmpEnvironmentalValues] = useState({
		temperature: { ...temperature },
		humidity: { ...humidity },
		luminosity: { ...luminosity },
		ph: { ...ph },
	});

	const [simulationTemperature, setSimulationTemperature] = useState(temperature.constant.value);
	const [simulationHumidity, setSimulationHumidity] = useState(humidity.constant.value);
	const [simulationLuminosity, setSimulationLuminosity] = useState(luminosity.constant.value);
	const [simulationPh, setSimulationPh] = useState(ph.constant.value);

	const [useAutoItems, setUseAutoItems] = useState(true);
	const [showAutoItems, setShowAutoItems] = useState(false);
	const [showPOIs, setShowPOIs] = useState(true);
	const [showMapGuides, setShowMapGuides] = useState(true);
	const [nextId, setNextId] = useState(1);
	const [items, setItems] = useState({});
	const [elementDimension, setElementDimension] = useState(30);
	const [objects, setObjects] = useState([]);
	const [lights, setLights] = useState([]);
	const [autoWaters, setAutoWaters] = useState([]);
	const [autoPois, setAutoPois] = useState([]);

	const [dragging, setDragging] = useState(false);
	const [draggingPosition, setDraggingPosition] = useState({ x: 0, y: 0 });
	const [descriptionOpen, setDescriptionOpen] = useState(false);
	const [descriptionTitle, setDescriptionTitle] = useState("");
	const [descriptionBody, setDescriptionBody] = useState("");

	const [isEditable, setIsEditable] = useState(propsIsEditable);
	const [inPopup, setInPopup] = useState(propsInPopup);
	const [inSimulation, setInSimulation] = useState(false);
	const [connection, setConnection] = useState(null);
	const [connected, setConnected] = useState(false);
	const [sparksActive, setSparksActive] = useState(false);
	const [sparksPosition, setSparksPosition] = useState({ x: 0, y: 0 });
	const itemsRefs = useRef({});

	const connectionRef = useRef();
	connectionRef.current = connection;
	const itemsRef = useRef();
	itemsRef.current = items;
	const mapWidthRef = useRef();
	mapWidthRef.current = mapWidth;
	const mapHeightRef = useRef();
	mapHeightRef.current = mapHeight;
	const resolutionRef = useRef();
	resolutionRef.current = resolution;
	const lightsRef = useRef();
	lightsRef.current = lights;

	const updateModel = (values) => {
		const tmpModel = {
			resolution,
			gridMeters,
			gridDimension,
			mapWidth,
			mapHeight,
			mapWidthMeters,
			temperature,
			humidity,
			luminosity,
			ph,
			useAutoItems,
			showAutoItems,
			showPOIs,
			nextId,
			items,
			autoWalls,
		};

		for (const [key, value] of Object.entries(values)) {
			tmpModel[key] = value;
		}

		propsModelUpdate(JSON.stringify({ ...tmpModel }));
	};

	const changeMapDimensions = useCallback((previousMapWidthMeters) => {
		const mainmap = document.querySelector("#mainmap");
		const mainmapWidth = mainmap.offsetWidth;
		const mainmapHeight = mainmap.offsetHeight;
		const { width, height } = getMapDimensions(mainmapWidth, mainmapHeight);
		const newResolution = (previousMapWidthMeters ?? mapWidthMeters) / width;
		const gridD = gridMeters * (1 / newResolution);
		setMapWidth(width);
		setMapHeight(height);
		setElementDimension((35 / 750) * width);
		setGridDimension(gridD);
		setResolution(newResolution);
		if (previousMapWidthMeters) setMapWidthMeters(previousMapWidthMeters);
	}, [gridMeters, mapWidthMeters]);

	const { ref: resizeRef } = useResizeDetector({
		refreshMode: "debounce",
		refreshRate: 50,
		onResize: () => { changeMapDimensions(); },
	});

	const changeMapWidthMeters = (value) => {
		const newResolution = value / mapWidth;
		const newGridMeters = gridDimension * newResolution;
		setGridMeters(newGridMeters);
		setResolution(newResolution);
		setMapWidthMeters(value);
		updateModel({ gridMeters: newGridMeters, resolution: newResolution, mapWidthMeters: value });
	};

	const changeUseAutoItems = (event) => {
		setUseAutoItems(!useAutoItems);
		if (!event.target.checked) {
			setShowAutoItems(false);
			updateModel({ useAutoItems: !useAutoItems, showAutoItems: false });
			return;
		}

		updateModel({ useAutoItems: !useAutoItems });
	};

	const changeShowAutoItems = () => {
		setShowAutoItems(!showAutoItems);
		updateModel({ showAutoItems: !showAutoItems });

		if (!showAutoItems) {
			warning("Displaying the auto-walls may cause the whole application to speed down.");
		}
	};

	const changeShowPOIs = () => {
		setShowPOIs(!showPOIs);
		updateModel({ showPOIs: !showPOIs });
	};

	const changeShowMapGuides = () => {
		setShowMapGuides(!showMapGuides);
	};

	const createNewItem = (cat, itemCat, x, y, id = null, hostItemCat = null, hostCat = null) => {
		const tmpItems = { ...items };
		const newItem = {
			id: nextId,
			itemName: `${categoriesPrefix[cat]}_${itemCat}_${nextId}`,
			itemCat,
			cat,
			x,
			y,
			x1: x - (elementDimension / mapWidth) * 100,
			y1: y,
			x2: x + (elementDimension / mapWidth) * 100,
			y2: y,
			variables: (id && hostItemCat && hostCat)
				? convertVariables(tmpItems[hostCat][hostItemCat][id].variables)
				: availableItems[cat].items[itemCat].variables,
			itemType: availableItems[cat].items[itemCat].itemType,
		};

		if (id && hostItemCat && hostCat) {
			tmpItems[hostCat][hostItemCat][id].variables.host.value = nextId;
		}

		itemsRefs.current[nextId] = createRef();

		tmpItems[cat][itemCat][nextId] = newItem;
		setItems(tmpItems);
		setNextId(nextId + 1);
		updateModel({ items: tmpItems, nextId: nextId + 1 });
	};

	const updateItem = (cat, itemCat, id, newState, updateHost) => {
		const tmpItems = { ...items };
		tmpItems[cat][itemCat][id] = newState;
		if (newState?.variables?.host?.value && updateHost) {
			const panTiltId = newState.variables.host.value;
			tmpItems.effectors.panTilt[panTiltId].x = newState.x;
			tmpItems.effectors.panTilt[panTiltId].y = newState.y;
			for (const variab of ["panMin", "panMax", "tiltMin", "tiltMax", "panTiltOperation", "panTiltHz", "panTiltStep"]) {
				tmpItems.effectors.panTilt[panTiltId].variables[panTiltMappings[variab]].value = newState.variables[variab].value;
			}
		}

		setItems(tmpItems);
		updateModel({ items: tmpItems });
	};

	const deleteItem = (cat, itemCat, id, hostId = null, hostItemCat = null, hostCat = null) => {
		const tmpItems = { ...items };

		if (tmpItems[cat][itemCat][id]?.variables?.host?.value) {
			delete tmpItems.effectors.panTilt[tmpItems[cat][itemCat][id].variables.host.value];
		}

		if (itemCat === "poi") {
			for (const robotId of Object.keys(tmpItems.general.robot)) {
				if (tmpItems.general.robot[robotId]?.variables?.automationPoints?.value
					&& tmpItems.general.robot[robotId].variables.automationPoints.value.some((el) => el.id === id)) {
					const prevValue = tmpItems.general.robot[robotId].variables.automationPoints.value;
					tmpItems.general.robot[robotId].variables.automationPoints.value = prevValue.filter((el) => el.id !== id);
				}
			}
		}

		delete tmpItems[cat][itemCat][id];
		if (id && hostItemCat && hostCat) {
			tmpItems[hostCat][hostItemCat][hostId].variables.host.value = null;
		}

		itemsRefs.current[id] = null;

		setItems(tmpItems);
		updateModel({ items: tmpItems });
	};

	const checkNameExists = (nameToCheck, initialName, cat, itemCat, id) => {
		if (nameToCheck === initialName) {
			return { matchesPattern: false, found: false };
		}

		const tmpItems = { ...items };
		let found = false;
		let matchesPattern = false;
		for (const category of Object.keys(tmpItems)) {
			for (const itemCategory of Object.keys(tmpItems[category])) {
				const checkResult = nameToCheck.match(new RegExp(`${categoriesPrefix[cat]}_${itemCategory}_[1-9]{1,3}`, "g"));
				if (checkResult && checkResult[0] === nameToCheck) {
					matchesPattern = true;
				}

				for (const item of Object.keys(tmpItems[category][itemCategory])) {
					if (tmpItems[category][itemCategory][item].itemName === nameToCheck
						&& !(category === cat && itemCategory === itemCat && Number.parseInt(item, 10) === id)) {
						found = true;
						break;
					}
				}
			}
		}

		return { matchesPattern, found };
	};

	const onDrag = (x, y) => {
		setDraggingPosition({ x, y });
		setDragging(true);
	};

	const onDragStop = () => {
		setDraggingPosition({ x: 0, y: 0 });
		setDragging(false);
	};

	const updatePose = (x, y, theta, name) => {
		const tmpItems = { ...itemsRef.current };
		for (const cat of Object.keys(tmpItems)) {
			for (const itemCat of Object.keys(tmpItems[cat])) {
				for (const item of Object.keys(tmpItems[cat][itemCat])) {
					if (tmpItems[cat][itemCat][item].itemName === name) {
						tmpItems[cat][itemCat][item].x = ((x / resolutionRef.current) / mapWidthRef.current) * 100;
						tmpItems[cat][itemCat][item].y = ((y / resolutionRef.current) / mapHeightRef.current) * 100;
						if (tmpItems[cat][itemCat][item]?.variables?.theta?.value !== undefined) {
							tmpItems[cat][itemCat][item].variables.theta.value = toDegrees(theta);
						}
					}
				}
			}
		}

		setItems(tmpItems);
	};

	const updateEnvProperties = (simTemp, simHum, simLum, simPh) => {
		setSimulationTemperature(simTemp);
		setSimulationHumidity(simHum);
		setSimulationLuminosity(simLum);
		setSimulationPh(simPh);
	};

	const handleHumanCrash = (name) => {
		const tmpItems = { ...itemsRef.current };
		let human;
		let humanCrashTimeout;

		for (const cat of Object.keys(tmpItems)) {
			for (const itemCat of Object.keys(tmpItems[cat])) {
				for (const item of Object.keys(tmpItems[cat][itemCat])) {
					if (tmpItems[cat][itemCat][item].itemName === name) {
						human = tmpItems[cat][itemCat][item];
					}
				}
			}
		}

		if (human) {
			const x = (human.x * mapWidthRef.current) / 100;
			const y = (human.y * mapHeightRef.current) / 100;
			if (!sparksActive) {
				setSparksActive(true);
				setSparksPosition({ x, y });
				switch (human.variables.gender.value) {
					case "Male": {
						document.querySelector('#human_crash_male').currentTime = 0;
						document.querySelector('#human_crash_male').play();
						if (humanCrashTimeout) clearTimeout(humanCrashTimeout);
						humanCrashTimeout = setTimeout(() => {
							setSparksActive(false);
							document.querySelector('#human_crash_male').pause();
							document.querySelector('#human_crash_male').currentTime = 0;
						}, 5000);
						break;
					}

					case "Female": {
						document.querySelector('#human_crash_female').currentTime = 0;
						document.querySelector('#human_crash_female').play();
						if (humanCrashTimeout) clearTimeout(humanCrashTimeout);
						humanCrashTimeout = setTimeout(() => {
							setSparksActive(false);
							document.querySelector('#human_crash_female').pause();
							document.querySelector('#human_crash_female').currentTime = 0;
						}, 5000);
						break;
					}

					default: {
						document.querySelector('#human_crash_none').currentTime = 0;
						document.querySelector('#human_crash_none').play();
						if (humanCrashTimeout) clearTimeout(humanCrashTimeout);
						humanCrashTimeout = setTimeout(() => {
							setSparksActive(false);
							document.querySelector('#human_crash_none').pause();
							document.querySelector('#human_crash_none').currentTime = 0;
						}, 5000);
						break;
					}
				}
			}
		}
	};

	const handleDetection = (name, type, result, from) => {
		const tmpItems = { ...itemsRef.current };
		let detectionSrc;
		let fromName;
		let detectionTarget;
		let detectionInfo;

		if (result?.[type]?.result) {
			const { name: frmName, info: fromInfo } = from;
			detectionInfo = fromInfo;
			fromName = frmName;
		}

		for (const cat of Object.keys(tmpItems)) {
			for (const itemCat of Object.keys(tmpItems[cat])) {
				for (const item of Object.keys(tmpItems[cat][itemCat])) {
					if (name.includes(tmpItems[cat][itemCat][item].itemName)) {
						detectionSrc = tmpItems[cat][itemCat][item];
					}

					if (fromName.includes(tmpItems[cat][itemCat][item].itemName)) {
						detectionTarget = tmpItems[cat][itemCat][item];
					}
				}
			}
		}

		if (detectionSrc && itemsRefs.current[detectionSrc.id].current) {
			itemsRefs.current[detectionSrc.id].current.simulationRangeStart();
		}

		if (detectionTarget && itemsRefs.current[detectionTarget.id].current) {
			itemsRefs.current[detectionTarget.id].current.simulationMessageStart(detectionInfo);
		}
	};

	const handleEffectorStateChange = (name, state) => {
		const tmpItems = { ...itemsRef.current };
		let effector;
		let effectorItemCat;

		for (const cat of Object.keys(tmpItems)) {
			for (const itemCat of Object.keys(tmpItems[cat])) {
				for (const item of Object.keys(tmpItems[cat][itemCat])) {
					if (name.includes(tmpItems[cat][itemCat][item].itemName)) {
						effector = tmpItems[cat][itemCat][item];
						effectorItemCat = itemCat;
					}
				}
			}
		}

		if (effectorItemCat === "light") {
			const tmpLights = [...(lightsRef.current)];
			const lightIndex = tmpLights.findIndex((light) => light.id === effector.id);
			if (lightIndex !== -1) {
				tmpLights[lightIndex].luminosity = state?.luminosity ?? tmpLights[lightIndex].luminosity;
				tmpLights[lightIndex].r = state?.r ?? tmpLights[lightIndex].r;
				tmpLights[lightIndex].g = state?.g ?? tmpLights[lightIndex].g;
				tmpLights[lightIndex].b = state?.b ?? tmpLights[lightIndex].b;
				setLights(tmpLights);
			}
		} else if (effector && itemsRefs.current[effector.id].current) {
			itemsRefs.current[effector.id].current.simulationMessageStart(state);
		}
	};

	const handleAlarmTrigger = (name, trigger) => {
		const tmpItems = { ...itemsRef.current };
		let alarm;
		const triggerItems = [];

		for (const cat of Object.keys(tmpItems)) {
			for (const itemCat of Object.keys(tmpItems[cat])) {
				for (const item of Object.keys(tmpItems[cat][itemCat])) {
					if (name.includes(tmpItems[cat][itemCat][item].itemName)) {
						alarm = tmpItems[cat][itemCat][item];
					}

					if (trigger.some((trig) => trig.includes(tmpItems[cat][itemCat][item].itemName))) {
						triggerItems.push(tmpItems[cat][itemCat][item]);
					}
				}
			}
		}

		if (alarm && itemsRefs.current[alarm.id].current) {
			itemsRefs.current[alarm.id].current.simulationRangeStart();
		}

		for (const triggerItem of triggerItems) {
			if (triggerItem && itemsRefs.current[triggerItem.id].current) {
				itemsRefs.current[triggerItem.id].current.simulationRangeStart();
			}
		}
	};

	const handleRfidReader = (name, rfidData) => {
		const tmpItems = { ...itemsRef.current };
		let detectionSrc;
		const detectionTarget = [];

		for (const cat of Object.keys(tmpItems)) {
			for (const itemCat of Object.keys(tmpItems[cat])) {
				for (const item of Object.keys(tmpItems[cat][itemCat])) {
					if (name.includes(tmpItems[cat][itemCat][item].itemName)) {
						detectionSrc = tmpItems[cat][itemCat][item];
					}

					const rfidTarget = Object.keys(rfidData?.tags ?? {}).find((tag) => tag.includes(tmpItems[cat][itemCat][item].itemName));
					if (rfidTarget) {
						detectionTarget.push({ item: tmpItems[cat][itemCat][item], data: { Tag: rfidData.tags[rfidTarget] } });
					}
				}
			}
		}

		if (detectionSrc && itemsRefs.current[detectionSrc.id].current) {
			itemsRefs.current[detectionSrc.id].current.simulationRangeStart();
		}

		for (const target of detectionTarget) {
			if (target.item && itemsRefs.current[target.item.id].current) {
				itemsRefs.current[target.item.id].current.simulationMessageStart(target.data);
			}
		}
	};

	const addAutoItems = () => {
		const mapComponent = document.querySelector("#map");
		const mapPos = mapComponent.getBoundingClientRect();
		let newId = nextId;

		const waters = [];
		for (const waterObject of autoWaters) {
			const { water, box } = waterObject;
			const boxComponent = document.querySelector(`#box_${box}`);
			const boxPos = boxComponent.getBoundingClientRect();
			waters.push(transformWater(water, boxPos, boxes[box].rotation, mapPos, newId, resolutionRef.current));
			newId += 1;
		}

		const pois = [];
		for (const poiObject of autoPois) {
			const { poi, box, name } = poiObject;
			const boxComponent = document.querySelector(`#box_${box}`);
			const boxPos = boxComponent.getBoundingClientRect();
			pois.push(transformPoi(poi, boxPos, boxes[box].rotation, mapPos, newId, name));
			newId += 1;
		}

		const tmpItems = { ...items };
		for (const water of waters) {
			const newItem = {
				id: water.id,
				itemName: `gn_water_${water.id}`,
				itemCat: "water",
				cat: "general",
				x: water.x,
				y: water.y,
				variables: {
					range: {
						...availableItems.general.items.water.variables.range,
						value: water.range,
					},
				},
			};

			itemsRefs.current[water.id] = createRef();

			tmpItems.general.water[water.id] = newItem;
		}

		const poiNames = {};
		for (const poi of pois) {
			if (poiNames[poi.name]) {
				poiNames[poi.name] += 1;
			} else {
				poiNames[poi.name] = 1;
			}

			const newItem = {
				id: poi.id,
				itemName: `${poi.name}_${poiNames[poi.name]}`,
				itemCat: "poi",
				cat: "general",
				x: poi.x,
				y: poi.y,
				variables: {},
			};

			itemsRefs.current[poi.id] = createRef();

			tmpItems.general.poi[poi.id] = newItem;
		}

		setItems(tmpItems);
		setNextId(newId);
		updateModel({ items: tmpItems, nextId: nextId + waters.length });
	};

	const closePopup = () => {
		setEnvironmentPopupOpen(false);
		setTmpEnvironmentalValues({
			temperature: { ...temperature },
			humidity: { ...humidity },
			luminosity: { ...luminosity },
			ph: { ...ph },
		});
	};

	const onEnvironmentSubmit = () => {
		setTemperature({ ...tmpEnvironmentalValues.temperature });
		setHumidity({ ...tmpEnvironmentalValues.humidity });
		setLuminosity({ ...tmpEnvironmentalValues.luminosity });
		setPh({ ...tmpEnvironmentalValues.ph });

		updateModel({
			temperature: { ...tmpEnvironmentalValues.temperature },
			humidity: { ...tmpEnvironmentalValues.humidity },
			luminosity: { ...tmpEnvironmentalValues.luminosity },
			ph: { ...tmpEnvironmentalValues.ph },
		});
		setEnvironmentPopupOpen(false);
	};

	const onMessage = (msg) => {
		let robotCrashTimeout;

		switch (msg.type) {
			case "connectedToBroker": {
				setIsLoading(false);
				setConnected(true);
				success("Connected to broker");
				break;
			}

			case "errorConnectingToBroker": {
				setIsLoading(false);
				error("There was an error trying to connect to broker. Please check the credentials.");
				break;
			}

			case "disconnectedFromBroker": {
				setIsLoading(false);
				setConnected(false);
				info("Disconnected from broker");
				disconnect({
					connection: connectionRef.current,
					onDisconnect: () => info("Disconnected from websocket"),
				});
				setConnected(false);
				break;
			}

			case "errorDisconnectingFromBroker": {
				setIsLoading(false);
				error("There was an error trying to disconnect from broker");
				break;
			}

			case "disconnectedFromQueue": {
				setIsLoading(false);
				disconnectFromGeneralBroker({ connection: connectionRef.current });
				break;
			}

			case "errorDisconnectingFromQueue": {
				setIsLoading(false);
				error("There was an error trying to disconnect from queue");
				break;
			}

			case "brokerMessage": {
				const { type, data } = JSON.parse(msg.message);
				console.log(type);
				console.log(data);

				switch (type) {
					case "robot_pose":
					case "sensor_pose":
					case "actor_pose": {
						const { x, y, theta, name } = data;
						updatePose(x, y, theta, name);
						break;
					}

					case "env_properties": {
						const { temperature: simTemp, humidity: simHum, luminosity: simLum, ph: simPh } = data;
						updateEnvProperties(simTemp, simHum, simLum, simPh);
						break;
					}

					case "robot_crash": {
						const x = data?.message?.position?.x ?? 0;
						const y = data?.message?.position?.y ?? 0;
						if (!sparksActive) {
							setSparksPosition({ x: (x / resolutionRef.current), y: (y / resolutionRef.current) });
							setSparksActive(true);
							document.querySelector('#robot_crash_audio').currentTime = 0;
							document.querySelector('#robot_crash_audio').play();
							if (robotCrashTimeout) clearTimeout(robotCrashTimeout);
							robotCrashTimeout = setTimeout(() => {
								setSparksActive(false);
								document.querySelector('#robot_crash_audio').pause();
								document.querySelector('#robot_crash_audio').currentTime = 0;
							}, 5000);
						}

						break;
					}

					case "human_crash": {
						const { name } = data;
						handleHumanCrash(name);
						break;
					}

					case "detection": {
						const { name, type: detectionType, result, frm } = data;
						handleDetection(name, detectionType, result, frm);
						break;
					}

					case "effector_state_change": {
						const { origin: name, state } = data;
						handleEffectorStateChange(name, state);
						break;
					}

					case "area_alarm_triggers":
					case "linear_alarm_triggers": {
						const { name, trigger } = data;
						handleAlarmTrigger(name, trigger);
						break;
					}

					case "rfid_reader": {
						const { name, data: rfidData } = data;
						handleRfidReader(name, rfidData);
						break;
					}

					default: {
						break;
					}
				}

				break;
			}

			default: {
				setIsLoading(false);
				info("Unknown message type");
			}
		}
	};

	useEffect(() => {
		let previousMapWidthMeters;
		if (propsDbItem) {
			for (const imp of propsDbItem?.finalImports ?? []) {
				if (imp.model_type === "envmaker") {
					const modelJSON = JSON.parse(imp.model_text);
					setNRows(modelJSON?.nRows ?? 6);
					setNCols(modelJSON?.nCols ?? 9);
					setBoxes(modelJSON?.boxes ?? {});
					setAutoWalls(modelJSON?.autoWalls ?? []);
					setMapWidth(modelJSON?.mapWidth ?? 100);
					setMapHeight(modelJSON?.mapHeight ?? 80);
					break;
				}
			}
		}

		if (propsModel) {
			const modelJSON = JSON.parse(propsModel);
			const tmpMapWidthMeters = modelJSON?.mapWidthMeters ?? INITIAL_MAP_METERS;
			previousMapWidthMeters = tmpMapWidthMeters;
			const tmpMapWidth = modelJSON?.mapWidth ?? 100;
			const tmpGridMeters = modelJSON?.gridMeters ?? (INITIAL_MAP_METERS / tmpMapWidth) * INITIAL_GRID_DIMENSION;
			const tmpResolution = modelJSON?.resolution ?? tmpGridMeters / INITIAL_GRID_DIMENSION;
			setResolution(tmpResolution);
			setGridMeters(tmpGridMeters);
			setGridDimension(modelJSON?.gridDimension ?? INITIAL_GRID_DIMENSION);
			setMapWidth(modelJSON?.mapWidth ?? 100);
			setMapHeight(modelJSON?.mapHeight ?? 80);
			setMapWidthMeters(tmpMapWidthMeters);
			setTemperature(modelJSON?.temperature ?? 22);
			setHumidity(modelJSON?.humidity ?? 60);
			setLuminosity(modelJSON?.luminosity ?? 80);
			setPh(modelJSON?.ph ?? 7);
			setTmpEnvironmentalValues({
				temperature: { ...modelJSON?.temperature },
				humidity: { ...modelJSON?.humidity },
				luminosity: { ...modelJSON?.luminosity },
				ph: { ...modelJSON?.ph },
			});
			setUseAutoItems(modelJSON?.useAutoItems ?? true);
			setShowAutoItems(modelJSON?.showAutoItems ?? true);
			setShowPOIs(modelJSON?.showPOIs ?? true);
			setNextId(modelJSON?.nextId ?? 1);
			setElementDimension((35 / 750) * (modelJSON?.mapWidth ?? 100));
			const tmpItems = { ...(modelJSON?.items) };
			for (const cat of Object.keys(availableItems)) {
				if (!tmpItems[cat]) {
					tmpItems[cat] = {};
				}

				for (const itemCat of Object.keys(availableItems[cat].items)) {
					if (!tmpItems[cat][itemCat]) {
						tmpItems[cat][itemCat] = {};
					}
				}
			}

			itemsRefs.current = {};
			for (const cat of Object.keys(tmpItems)) {
				for (const itemCat of Object.keys(tmpItems[cat])) {
					for (const item of Object.keys(tmpItems[cat][itemCat])) {
						itemsRefs.current[item] = createRef();
					}
				}
			}

			setItems(tmpItems);
		}

		changeMapDimensions(previousMapWidthMeters);
	}, [changeMapDimensions, propsDbItem, propsModel, inSimulation]);

	useEffect(() => {
		const tmpBoxes = { ...boxes };
		for (let i = 0; i < nRows; i++) {
			for (let j = 0; j < nCols; j++) {
				tmpBoxes[`${i + 1}_${j + 1}`] = {
					r: i + 1,
					c: j + 1,
					rotation: 0,
					content: null,
					contentId: null,
					contentCat: null,
				};
			}
		}

		setBoxes(tmpBoxes);

		const tmpItems = { ...items };
		for (const cat of Object.keys(availableItems)) {
			if (!tmpItems[cat]) {
				tmpItems[cat] = {};
			}

			for (const itemCat of Object.keys(availableItems[cat].items)) {
				if (!tmpItems[cat][itemCat]) {
					tmpItems[cat][itemCat] = {};
				}
			}
		}

		itemsRefs.current = {};
		for (const cat of Object.keys(tmpItems)) {
			for (const itemCat of Object.keys(tmpItems[cat])) {
				for (const item of Object.keys(tmpItems[cat][itemCat])) {
					itemsRefs.current[item] = createRef();
				}
			}
		}

		setItems(tmpItems);

		changeMapDimensions();
		window.addEventListener("resize", changeMapDimensions);

		return () => {
			window.removeEventListener("resize", changeMapDimensions);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const tileDimension = getElementDimension(mapWidth, mapHeight, nRows, nCols);
		const tmpGrid = [];
		for (const box of Object.keys(boxes)) {
			tmpGrid.push(
				<Draggable
					key={box}
					disabled
					allowAnyClick={false}
					position={{ x: 0, y: 0 }}
				>
					<div
						key={box}
						id={`box_${box}`}
						style={{
							display: "flex",
							width: tileDimension,
							height: tileDimension,
							border: "",
							position: "absolute",
							left: ((mapWidth - nCols * tileDimension) / 2
								+ (boxes[box].c - 1) * tileDimension),
							top: ((mapHeight - nRows * tileDimension) / 2
								+ (boxes[box].r - 1) * tileDimension),
							justifyContent: "center",
							opacity: 0.5,
						}}
					>
						{boxes[box].content
							&& (
								<img src={boxes[box].content} alt="" style={{ maxWidth: "100%", maxHeight: "100%", transform: `rotate(${boxes[box].rotation}deg)` }} />
							)}
					</div>
				</Draggable>,
			);
		}

		setGrid(tmpGrid);
	}, [boxes, mapWidth, mapHeight, nRows, nCols, inSimulation]);

	useEffect(() => {
		const tmpObjects = [];
		const tmpLights = [...lights];
		for (const cat of Object.keys(items)) {
			for (const itemCat of Object.keys(items[cat])) {
				for (const item of Object.keys(items[cat][itemCat])) {
					if (itemCat === "poi" && !showPOIs) continue;

					const itm = items[cat][itemCat][item];
					const props = {
						key: itm.id,
						id: itm.id,
						mode: (inSimulation || !isEditable || inPopup) ? "view" : "edit",
						name: itm.itemName,
						initialName: itm.itemName,
						position: { x: itm.x, y: itm.y },
						position1: { x: itm.x1, y: itm.y1 },
						position2: { x: itm.x2, y: itm.y2 },
						icon: availableItems[cat].items[itemCat].icon,
						variables: itm.variables,
						icons: availableItems[cat].items[itemCat].icons,
						elementDimensions: elementDimension,
						mapWidth,
						mapHeight,
						resolution,
						cat,
						itemCat,
						items,
						updateItem,
						deleteItem,
						createNewItem: (inSimulation || !isEditable) ? () => {} : createNewItem,
						checkNameExists,
					};

					tmpObjects.push(
						(availableItems[cat].items[itemCat].itemType === "double"
							? <DoubleItem ref={itemsRefs.current[itm.id]} {...props} />
							: <Item ref={itemsRefs.current[itm.id]} {...props} />
						),
					);

					if (cat === "effectors" && itemCat === "light") {
						const lightIndex = tmpLights.findIndex((light) => light.id === itm.id);
						if (lightIndex === -1) {
							const light = {
								id: itm.id,
								x: itm.x,
								y: itm.y,
								range: itm.variables.range.value / resolution,
								luminosity: itm.variables.luminosity.value,
								r: itm.variables.r.value,
								g: itm.variables.g.value,
								b: itm.variables.b.value,
							};
							tmpLights.push(light);
						}
					}
				}
			}
		}

		if (useAutoItems && showAutoItems) {
			for (const wall of autoWalls) {
				const props = {
					key: wall.id,
					id: wall.id,
					mode: "view",
					name: `auto_wall_${wall.id}`,
					initialName: `auto_wall_${wall.id}`,
					position1: { x: wall.x1, y: wall.y1 },
					position2: { x: wall.x2, y: wall.y2 },
					icons: availableItems.general.items.wall.icons,
					elementDimensions: elementDimension,
					mapWidth,
					mapHeight,
					resolution,
					cat: "general",
					itemCat: "wall",
				};

				tmpObjects.push(<DoubleItem {...props} />);
			}
		}

		const mapGuides = [];
		if (showMapGuides) {
			for (let i = 1; i < 10; i++) {
				mapGuides.push(
					<div
						key={`line-h-${i}`}
						style={{
							position: "absolute",
							left: 0,
							top: (mapHeight / 10) * i,
							width: mapWidth,
							height: 1,
							backgroundColor: "rgba(0, 0, 0, 0.3)",
						}}
					/>,
					<div
						key={`line-v-${i}`}
						style={{
							position: "absolute",
							left: (mapWidth / 10) * i,
							top: 0,
							width: 1,
							height: mapHeight,
							backgroundColor: "rgba(0, 0, 0, 0.3)",
						}}
					/>,
				);
			}

			for (let i = 1; i < 10; i++) {
				mapGuides.push(
					<div
						key={`text-h-${i}`}
						style={{
							position: "absolute",
							left: 5,
							top: (mapHeight / 10) * (10 - i) - 10,
							color: "black",
							background: "white",
							fontSize: "12px",
						}}
					>
						{`${(i * ((mapHeight * resolution) / 10)).toFixed(1)}m`}
					</div>,
					<div
						key={`text-v-${i}`}
						style={{
							position: "absolute",
							left: (mapWidth / 10) * i - 10,
							bottom: 5,
							color: "black",
							background: "white",
							fontSize: "12px",
						}}
					>
						{`${(i * (mapWidthMeters / 10)).toFixed(1)}m`}
					</div>,
				);
			}
		}

		setObjects([...mapGuides, ...tmpObjects]);
		setLights(tmpLights);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		elementDimension,
		items,
		mapHeight,
		mapWidth,
		resolution,
		showMapGuides,
		inSimulation,
		isEditable,
		inPopup,
		useAutoItems,
		showAutoItems,
		showPOIs,
		autoWalls,
	]);

	useEffect(() => {
		(async () => {
			let conn;
			if (inSimulation && !connection) {
				conn = await connect({
					onConnect: () => { success("Connected to simulation server"); },
					onError: () => { error("There was an error trying to connect to websocket"); },
					onMessage: (msg) => { onMessage(msg); },
					type: "envpop",
				});
				setConnection(conn);

				connectToGeneralBroker({ connection: conn });
			}

			return async () => {
				if (conn) {
					await disconnect({ connection: conn });
				}
			};
		})();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [success, error, inSimulation, connection]);

	useEffect(() => {
		if (connected && inSimulation) {
			subscribeToQueue(`streamsim/${jwt.decode().id}/notify`, null, connection);
		}
	}, [connected, inSimulation, connection]);

	useEffect(() => {
		setInSimulation(propsInSimulation);
	}, [propsInSimulation]);

	useEffect(() => {
		setIsEditable(propsIsEditable);
	}, [propsIsEditable]);

	useEffect(() => {
		setInPopup(propsInPopup);
	}, [propsInPopup]);

	useEffect(() => {
		if (autoItemsFlag) {
			addAutoItems();
			autoItemsFlag = false;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [grid]);

	useImperativeHandle(ref, () => ({
		onResize() {
			changeMapDimensions();
		},
		storeAutoItems(envmakerModel) {
			try {
				const envmakerBoxes = envmakerModel?.boxes ?? {};
				const waters = [];
				const pois = [];
				for (const box of Object.keys(envmakerBoxes)) {
					const contentCat = envmakerBoxes[box].contentCat;
					const contentId = envmakerBoxes[box].contentId;
					if (contentCat && contentId) {
						const content = categories[contentCat].components[contentId];
						const boxWaters = content?.waters ?? [];
						const boxPois = content?.pois?.items ?? [];
						const boxPoisName = content?.pois?.name ?? "";
						for (const water of boxWaters) {
							waters.push({ water, box });
						}

						for (const poi of boxPois) {
							pois.push({ poi, box, name: boxPoisName });
						}
					}
				}

				setAutoWaters(waters);
				setAutoPois(pois);
				autoItemsFlag = true;
			} catch (error_) {
				console.error(error_);
			}
		},
	}));

	const formContent = useMemo(() => {
		const element = environmentTabStep === 0
			? "temperature"
			: environmentTabStep === 1
				? "humidity"
				: environmentTabStep === 2
					? "luminosity"
					: "ph";
		const content = [];

		for (const [variable, options] of Object.entries(tmpEnvironmentalValues[element])) {
			const {
				type,
				name: varName,
				min,
				max,
				step,
				precision,
				options: selectItems,
				format,
				needs,
			} = options;
			if (needs) {
				let needsMet = true;
				for (const neededKey of Object.keys(needs)) {
					const neededValue = needs[neededKey];
					if (tmpEnvironmentalValues[element]?.[neededKey]?.value !== undefined) {
						let found = false;
						for (const val of neededValue) {
							if (tmpEnvironmentalValues[element][neededKey].value === val) {
								found = true;
								break;
							}
						}

						needsMet = found;
						if (!needsMet) {
							break;
						}
					}
				}

				if (!needsMet) {
					// eslint-disable-next-line no-continue
					continue;
				}
			}

			switch (type) {
				case "number": {
					content.push({
						customType: "number",
						id: variable,
						type: "number",
						label: varName,
						value: tmpEnvironmentalValues[element][variable].value,
						min: (typeof min === "string" ? tmpEnvironmentalValues[element]?.[min].value : min),
						max: (typeof max === "string" ? tmpEnvironmentalValues[element]?.[max].value : max),
						step,
						precision,
						...(format ? {
							format: (num) => `${num}${format}`,
						} : {}),
						...(format ? {
							parse: (num) => num.replace(format, ""),
						} : {}),
						onChange: (val) => {
							setTmpEnvironmentalValues((prev) => ({
								...prev,
								[element]: {
									...prev[element],
									[variable]: {
										...prev[element][variable],
										value: val,
									},
								},
							}));
						},
					});

					break;
				}

				case "select": {
					content.push({
						customType: "dropdown",
						id: variable,
						label: varName,
						defaultValue: tmpEnvironmentalValues[element][variable].value,
						items: typeof selectItems === "string"
							? tmpEnvironmentalValues[element][selectItems].value.map((item) => ({ value: item, text: item }))
							: selectItems.map((item) => ({ value: item, text: item })),
						onChange: (event) => {
							setTmpEnvironmentalValues((prev) => ({
								...prev,
								[element]: {
									...prev[element],
									[variable]: {
										...prev[element][variable],
										value: event.target.value,
									},
								},
							}));
						},
					});

					break;
				}

				default:
			// Do nothing
			}
		}

		content.push({
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save",
		});

		return content;
	}, [environmentTabStep, tmpEnvironmentalValues]);

	const fov = 60;
	const lightsRange = fov < 180
		? `conic-gradient(transparent 0deg ${90 - fov / 2}deg, rgba(255, 255, 255, 0.2) ${90 - fov / 2}deg ${90 + fov / 2}deg, transparent ${90 + fov / 2}deg 360deg)`
		: `conic-gradient(rgba(255, 255, 255, 0.2) 0deg ${90 + fov / 2}deg, transparent ${90 + fov / 2}deg ${360 + (90 - fov / 2)}deg, rgba(255, 255, 255, 0.2) ${360 + (90 - fov / 2)}deg 360deg)`;

	const MapComponent = (
		<Grid item id="map" className={classes.map} sx={{ width: `${mapWidth}px`, height: `${mapHeight}px` }}>
			{grid}
			{objects}
			{descriptionOpen && !dragging && (
				<Grid
					item
					width="100%"
					height="100%"
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						backgroundColor: "rgba(0, 0, 0, 0.6)",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography textAlign="center" fontSize="20px" px={2} color="white!important">
						{descriptionTitle}
					</Typography>
					<Typography textAlign="center" fontSize="16px" px={2} mt={2} color="white!important">
						{descriptionBody}
					</Typography>
				</Grid>
			)}
			{sparksActive && (
				<Sparks dimension={elementDimension} x={sparksPosition.x} y={sparksPosition.y} />
			)}
			<audio key="robot_crash_audio" preload="auto" id="robot_crash_audio">
				<source src={RobotCrashAudio} type="audio/mpeg" />
			</audio>
			<audio key="human_crash_none" preload="auto" id="human_crash_none">
				<source src={HumanCrashNoneAudio} type="audio/mpeg" />
			</audio>
			<audio key="human_crash_male" preload="auto" id="human_crash_male">
				<source src={HumanCrashMaleAudio} type="audio/mpeg" />
			</audio>
			<audio key="human_crash_female" preload="auto" id="human_crash_female">
				<source src={HumanCrashFemaleAudio} type="audio/mpeg" />
			</audio>
			{inSimulation && (
				<div className={classes.lightShadow} style={{ backgroundColor: `rgba(3, 19, 32, ${(100 - simulationLuminosity) / 100})` }}>
					{lights.filter((light) => light.luminosity >= simulationLuminosity).map((light) => (
						<div
							key={light.id}
							style={{
								width: 2 * light.range,
								height: 2 * light.range,
								borderRadius: 2 * light.range,
								background: `radial-gradient(circle, rgba(${light.r}, ${light.g}, ${light.b},${light.luminosity / 100}) -100%, rgba(3, 19, 32,${(100 - simulationLuminosity) / 100}) 100%)`,
								position: "absolute",
								filter: "blur(10px)",
								bottom: `calc(${light.y}% - ${light.range}px)`,
								left: `calc(${light.x}% - ${light.range}px)`,
							}}
						/>
					))}
					{Object.keys(items.general.robot)
						.filter((robotId) => {
							const robot = items.general.robot[robotId];
							return robot?.variables?.leds?.value ?? false;
						}).map((robotId) => {
							const robot = items.general.robot[robotId];
							const robotTheta = toRadians(robot.variables.theta.value);
							const robotX = (robot.x / 100) * mapWidth;
							const robotY = (robot.y / 100) * mapHeight;
							const lightSize = 2 * (10 / resolutionRef.current); // 10m
							const skin = items.general.robot[robotId].variables.skin.value;
							const aspectRatio = availableItems.general.items.robot.icons[skin].aspectRatio;
							const robotSize = elementDimension * (4 / 5);
							return (
								<div
									key={`${robotId}_lights`}
									style={{
										width: `${robotSize * aspectRatio}px`,
										height: `${robotSize}px`,
										position: "absolute",
										bottom: `calc(${robotY}px - ${robotSize / 2}px)`,
										left: `calc(${robotX}px - ${(robotSize * aspectRatio) / 2}px)`,
									}}
								>
									<div
										style={{
											position: "relative",
											width: "100%",
											height: "100%",
											transform: `rotate(${-robotTheta}rad)`,
										}}
									>
										<div
											key={`${robotId}_light_1`}
											style={{
												width: lightSize,
												height: lightSize,
												borderRadius: lightSize,
												backgroundImage: lightsRange,
												filter: "blur(5px)",
												position: "absolute",
												right: `${-(lightSize / 2) + 5}px`,
												top: `${-(lightSize / 2) + (robotSize / 4)}px`,
											}}
										/>
										<div
											key={`${robotId}_light_2`}
											style={{
												width: lightSize,
												height: lightSize,
												borderRadius: lightSize,
												backgroundImage: lightsRange,
												filter: "blur(5px)",
												position: "absolute",
												right: `${-(lightSize / 2) + 5}px`,
												top: `${-(lightSize / 2) + ((3 * robotSize) / 4)}px`,
											}}
										/>
									</div>
								</div>
							);
						})}
				</div>
			)}
			{inSimulation && (
				<div className={classes.environmentalIndications}>
					<div style={{ color: "white", display: "flex", flexDirection: "row", alignItems: "center", cursor: "help" }}>
						<Tooltip title={`Temperature: ${simulationTemperature.toFixed(1)}°C`}>
							<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								<Thermostat color="white" fontSize="14px" />
								<Typography fontSize="14px" color="white" ml={1}>
									{simulationTemperature.toFixed(1)}
									{"°C"}
								</Typography>
							</div>
						</Tooltip>
					</div>
					<div style={{ color: "white", display: "flex", flexDirection: "row", alignItems: "center", cursor: "help" }}>
						<Tooltip title={`Humidity: ${simulationHumidity.toFixed(0)}%`}>
							<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								<Opacity color="white" fontSize="14px" />
								<Typography fontSize="14px" color="white" ml={1}>
									{simulationHumidity.toFixed(0)}
									{"%"}
								</Typography>
							</div>
						</Tooltip>
					</div>
					<div style={{ color: "white", display: "flex", flexDirection: "row", alignItems: "center", cursor: "help" }}>
						<Tooltip title={`Luminosity: ${simulationLuminosity.toFixed(0)}%`}>
							<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								<LightMode color="white" fontSize="14px" />
								<Typography fontSize="14px" color="white" ml={1}>
									{simulationLuminosity.toFixed(0)}
									{"%"}
								</Typography>
							</div>
						</Tooltip>
					</div>
					<div style={{ color: "white", display: "flex", flexDirection: "row", alignItems: "center", cursor: "help" }}>
						<Tooltip title={`PH: ${simulationPh.toFixed(1)}`}>
							<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								<Science color="white" fontSize="14px" />
								<Typography fontSize="14px" color="white" ml={1}>
									{simulationPh.toFixed(1)}
								</Typography>
							</div>
						</Tooltip>
					</div>
				</div>
			)}
		</Grid>
	);

	return ([
		<Grid key="main" ref={resizeRef} container className={classes.root}>
			<Grid item id="mainmap" xs={12} lg={inPopup ? 12 : 10} className={classes.mapRoot}>
				{inSimulation && (
					<TransformWrapper>
						<TransformComponent>
							{MapComponent}
						</TransformComponent>
					</TransformWrapper>
				)}
				{!inSimulation && MapComponent}
			</Grid>
			{dragging && (
				<div className={classes.draggingTile} style={{ left: `${draggingPosition.x}px`, top: `${draggingPosition.y}px` }} />
			)}
			{!inPopup && (
				<Grid item xs={12} lg={2} className={classes.controlsRoot}>
					<Grid item className={classes.sliderBox}>
						<Grid item className={classes.optionsRow}>
							<Typography textAlign="left" fontSize="14px">{"Map Width (meters)"}</Typography>
							<Typography textAlign="right">{mapWidthMeters.toFixed(0)}</Typography>
						</Grid>
						<Slider
							color="secondary"
							value={mapWidthMeters}
							min={1}
							max={1000}
							step={1}
							disabled={inSimulation || !isEditable}
							onChange={(event) => {
								changeMapWidthMeters(event.target.value);
							}}
						/>
					</Grid>
					<Grid
						item
						width="100%"
						className={classes.sliderBox}
						sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
					>
						<Typography textAlign="left" fontSize="14px">{"Use auto-walls"}</Typography>
						<Switch
							checked={useAutoItems}
							disabled={inSimulation || !isEditable}
							onChange={(ev) => changeUseAutoItems(ev)}
						/>
					</Grid>
					<Grid
						item
						width="100%"
						className={classes.sliderBox}
						sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
					>
						<Typography textAlign="left" fontSize="14px">{"Show auto-walls"}</Typography>
						<Switch
							checked={showAutoItems}
							disabled={!useAutoItems}
							onChange={changeShowAutoItems}
						/>
					</Grid>
					<Grid
						item
						width="100%"
						className={classes.sliderBox}
						sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
					>
						<Typography textAlign="left" fontSize="14px">{"Show POIs"}</Typography>
						<Switch
							checked={showPOIs}
							onChange={changeShowPOIs}
						/>
					</Grid>
					<Grid
						item
						width="100%"
						className={classes.sliderBox}
						sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
					>
						<Typography textAlign="left" fontSize="14px">{"Show guides"}</Typography>
						<Switch
							checked={showMapGuides}
							onChange={changeShowMapGuides}
						/>
					</Grid>
					<Grid
						item
						width="100%"
						className={classes.sliderBox}
						mb={0.5}
						sx={{ flexDirection: "row!important", justifyContent: "space-between!important", alignItems: "center" }}
					>
						<SecondaryBackgroundButton title="Environment" titleColor="primary" onClick={() => setEnvironmentPopupOpen(true)} />
					</Grid>
					<Divider className={classes.divider} />
					{Object.keys(availableItems).map((cat) => (
						<Grid key={cat} width="100%" style={{ padding: "0px 20px", marginBottom: "10px" }}>
							<Accordion
								title={availableItems[cat].title}
								titleBackground="third"
								content={(
									<Grid
										container
										className={classes.tilesBox}
										onMouseOver={() => setDescriptionOpen(true)}
										onMouseOut={() => setDescriptionOpen(false)}
									>
										{Object.keys(availableItems[cat].items)
											.filter((comp) => !availableItems[cat].items[comp].hide)
											.map((comp) => (
												<Item
													key={comp}
													id={comp}
													mode="menu"
													icon={availableItems[cat].items[comp].icon}
													variables={availableItems[cat].items[comp].variables}
													icons={availableItems[cat].items[comp].icons}
													elementDimensions={elementDimension}
													mapWidth={mapWidth}
													mapHeight={mapHeight}
													cat={cat}
													itemCat={comp}
													createNewItem={(inSimulation || !isEditable) ? () => {} : createNewItem}
													tooltip={availableItems[cat].items[comp].tooltip}
													setDescriptionTitle={setDescriptionTitle}
													setDescriptionBody={setDescriptionBody}
													onDrag={(inSimulation || !isEditable) ? () => {} : onDrag}
													onDragStop={(inSimulation || !isEditable) ? () => {} : onDragStop}
												/>
											))}
									</Grid>
								)}
								alwaysExpanded={false}
							/>
						</Grid>
					))}
				</Grid>
			)}
		</Grid>,
		<Spinner key="spinner" open={isLoading} />,
		<Popup
			key="popup_environment"
			width="500px"
			open={environmentPopupOpen}
			title="Environmental Settings"
			onClose={closePopup}
		>
			<Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<Tabs value={environmentTabStep} textColor="inherit" onChange={(_, val) => setEnvironmentTabStep(val)}>
					<Tab label="Temperature" />
					<Tab label="Humidity" />
					<Tab label="Luminosity" />
					<Tab label="Ph" />
				</Tabs>
				<Form key={JSON.stringify(formContent)} content={formContent} onSubmit={onEnvironmentSubmit} />
			</Grid>
		</Popup>,
	]);
});

export default CreateMission;
