/* eslint-disable arrow-body-style */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-indent */
/* eslint indent: "off", "react/jsx-indent-props": "off" */

import { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    Paper,
    Box,
} from "@mui/material";

const ParametersListInput = ({ param, parameters, setParameters, nodeData }) => {
    const [elements, setElements] = useState([]);
    const [focusIndex, setFocusIndex] = useState(null);
    const inputRefs = useRef([]);

    useEffect(() => {
        const paramValue = parameters.find((p) => p.id === param.id)?.value || [];

        const values = typeof paramValue === 'string'
            ? (paramValue.startsWith('[') ? JSON.parse(paramValue) : [])
            : paramValue;

        setElements(values.length > 0 ? values : ['']);
    }, [param.id, parameters]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, elements.length);
    }, [elements]);

    const updateParameters = (newElements) => {
        const processedValues = newElements.map((val) => {
            // Check if the value inserted is a number or a string
            return /^[+-]?\d+(\.\d+)?$/.test(val) ? Number(val) : val;
        });

        // eslint-disable-next-line no-confusing-arrow
        const newParams = parameters.map((p) => p.id === param.id ? { ...p, value: processedValues } : p);

        setParameters(newParams);
    };

    const handleElementChange = (index, value) => {
        const newElements = [...elements];
        newElements[index] = value;

        setElements(newElements);
        updateParameters(newElements);
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            const newElements = [...elements];
            newElements.splice(index + 1, 0, '');

            setElements(newElements);
            updateParameters(newElements);

            setTimeout(() => {
                setFocusIndex(index + 1);
            }, 0);
        } else if (e.key === 'Delete' && elements.length > 1) {
            e.preventDefault();

            const newElements = [...elements];
            newElements.splice(index, 1);

            setElements(newElements);
            updateParameters(newElements);

            if (index > 0) {
                setTimeout(() => {
                    setFocusIndex(index - 1);
                }, 0);
            }
        } else if (e.key === 'Tab') {
            if (!e.shiftKey) {
                e.preventDefault();
                if (index === elements.length - 1) {
                    const newElements = [...elements, ''];

                    setElements(newElements);
                    updateParameters(newElements);

                    setTimeout(() => {
                        setFocusIndex(index + 1);
                    }, 0);
                } else {
                    setFocusIndex(index + 1);
                }
            } else if (e.shiftKey && index > 0) {
                e.preventDefault();
                setFocusIndex(index - 1);
            }
        }
    };

    useEffect(() => {
        if (focusIndex !== null && inputRefs.current[focusIndex]) {
            inputRefs.current[focusIndex].focus();
            setFocusIndex(null);
        }
    }, [focusIndex, elements]);

    const handleEmptyClick = () => {
        const newElements = [''];
        setElements(newElements);
        updateParameters(newElements);
        setTimeout(() => {
            setFocusIndex(0);
        }, 0);
    };

    return (
        <Grid
            item
            container
            display="flex"
            direction="column"
            sx={{
                marginBottom: "5px",
                width: "100%",
            }}
        >
            <Typography sx={{ marginBottom: "10px" }}>
                {param.label}
            </Typography>

            <Paper
                elevation={0}
                variant="outlined"
                sx={{
                    padding: 2,
                    backgroundColor: "#f5f5f5",
                    width: "100%",
                    minHeight: "120px",
                    position: "relative",
                }}
            >
                {elements.length > 0 && elements.map((element, index) => (
                    <Box
                        key={`elem-${index}-${elements.length}`}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 1,
                            "& input": {
                                border: "none",
                                backgroundColor: "transparent",
                                fontFamily: "monospace",
                                fontSize: "14px",
                                outline: "none",
                                padding: "6px",
                                width: "100%",
                            },
                            "&:hover": {
                                backgroundColor: "#e3e3e3",
                                borderRadius: "4px",
                            },
                        }}
                    >
                        <Typography sx={{ width: "30px", color: "#666" }}>
                            {index + 1}
                            {"."}
                        </Typography>
                        <input
                            ref={(el) => inputRefs.current[index] = el}
                            value={element}
                            placeholder="value"
                            style={{ flexGrow: 1 }}
                            onChange={(e) => handleElementChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                    </Box>
                ))}

                {elements.length === 0 && (
                    <Typography sx={{ color: "#999", fontStyle: "italic", padding: 1 }}>
                        {"Click to add elements"}
                    </Typography>
                )}

                {elements.length === 0 && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            cursor: "text",
                        }}
                        onClick={handleEmptyClick}
                    />
                )}
            </Paper>

            <Typography variant="caption" sx={{ mt: 1, color: "#666" }}>
                {"Press Enter to add a new element, Delete to remove an element"}
            </Typography>
        </Grid>
    );
};

export default ParametersListInput;
