/* eslint indent: ["off"] */
import { createSlice } from "@reduxjs/toolkit";

export const tabSlice = createSlice({
    name: 'tabs',
    initialState: [],
    reducers: {
        addTab: (state, tab) => {
            state.push({
                tabId: tab.payload.id,
                tabName: tab.payload.label,
            });
        },
        updateTab: (state, action) => {
            const index = state.findIndex((tab) => tab.tabId === action.payload.id);

            if (index !== -1) {
                state[index] = {
                    tabId: action.payload.id,
                    tabName: action.payload.name,
                };
            }

            return state;
        },
        deleteTab: (state, id) => {
            state = state.filter((tab) => tab.tabId !== id.payload);
            return state;
        },
        setTabParameters: (state, data) => {
            const tabArr = [];

            for (const tab of data.payload) {
                tabArr.push({
                    tabId: tab.id,
                    tabName: tab.label,
                });
            }

            state = tabArr;

            return state;
        },
    },
});

export const { addTab, updateTab, deleteTab, setTabParameters } = tabSlice.actions;

export default tabSlice.reducer;
