/* eslint indent: ["off"] */
import { createSlice } from "@reduxjs/toolkit";

export const listSlice = createSlice({
    name: 'lists',
    initialState: [],
    reducers: {
        addList: (state, list) => {
            state.push({
                listId: list.payload.id,
                listName: list.payload.parameters[0]?.value,
                listValue: list.payload.parameters[1]?.value,
            });
        },
        updateList: (state, action) => {
            const index = state.findIndex((list) => list.listId === action.payload.id);

            if (index !== -1) {
                state[index] = {
                    listId: action.payload.id,
                    listName: action.payload.data.parameters[0].value,
                    listValue: action.payload.data.parameters[1].value,
                };
            }
        },
        deleteList: (state, id) => {
            state = state.filter((list) => list.listId !== id.payload.id);
            return state;
        },
        setListParameters: (state, data) => {
            const listArr = [];
            for (const list of data.payload) {
                listArr.push({
                    listId: list.nodeId,
                    listName: list.list,
                });
            }

            state = listArr;

            return state;
        },
    },
});

export const { addList, updateList, deleteList, setListParameters } = listSlice.actions;

export default listSlice.reducer;
