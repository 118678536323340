import transformAsset from "./transform-appcreator-assets.js";

const documentation = `# Thread join node

The parallelism stops in a Thread join node. This node has N inputs and one output. Each of the inputs handles the ending of a thread, and the node concludes its execution when all the incoming threads are done.

A simple example follows:

![alt text](${transformAsset("thread-join.png")})

Here the application will start and two delays will be executed in parallel (3 and 10 seconds).
The 3 seconds delay will end after 3 seconds (duh) and the execution flow will reach the input 0 of the join node. Since the second thread (the one with the 10 seconds delay) has not finished yet, the application execution stays in the join node for another 7 seconds, when the 10 second delay terminates as well. Then the application terminates.
`;

export default documentation;
