import { Add, Delete } from "@mui/icons-material";
import { Chip, Divider, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

import Popup from "./Popup.js";
// eslint-disable-next-line import/no-cycle
import Form from "./Form.js";

const useStyles = makeStyles((theme) => ({
	main: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	chip: {
		margin: "1px 2px",
		backgroundColor: theme.palette.secondary.main,
	},
	step: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	arrowButtonIcon: {
		color: "inherit",
	},
	borderButton: {
		border: `1px solid ${theme.palette.greyDark.main}`,
		borderRadius: "10px",
		padding: "2px",
		margin: "2px",
	},
	deleteButton: {
		color: `${theme.palette.error.main} !important`,
		marginLeft: "auto",
	},
	divider: {
		width: "90%",
		margin: "10px 0px",
		backgroundColor: theme.palette.greyDark.main,
	},
}));

const StateInput = ({
	id = "state-input",
	disabled = false,
	placeholder = "Placeholder",
	value = [],
	variables = [],
	onChange = () => {},
}) => {
	const classes = useStyles();
	const [addStepPopupOpen, setAddStepPopupOpen] = useState(false);

	const handleAdd = () => {
		setAddStepPopupOpen(true);
	};

	const handleDelete = (index) => {
		const newValue = [...value];
		newValue.splice(index, 1);
		onChange(newValue);
	};

	const handleClose = () => {
		setAddStepPopupOpen(false);
	};

	const handleSubmit = (values) => {
		const { duration, ...state } = values;
		const newValue = [...value];
		newValue.push({ state, duration });
		onChange(newValue);
		setAddStepPopupOpen(false);
	};

	const formContent = [];

	for (const [variable, options] of Object.entries(variables)) {
		const {
			type,
			name: varName,
			placeholder: varPlaceholder,
			min,
			max,
			step,
			precision,
			format,
			options: selectItems,
			tmpFormValues,
		} = options;

		switch (type) {
			case "text": {
				formContent.push({
					customType: "input",
					id: variable,
					type: "text",
					label: varName,
					placeholder: varPlaceholder,
					value: variables[variable].value,
				});

				break;
			}

			case "number": {
				formContent.push({
					customType: "number",
					id: variable,
					type: "number",
					label: varName,
					value: variables[variable].value,
					min: (typeof min === "string" ? variables[min].value : min),
					max: (typeof max === "string" ? variables[max].value : max),
					step,
					precision,
					...(format ? {
						format: (num) => `${num}${format}`,
					} : {}),
					...(format ? {
						parse: (num) => num.replace(format, ""),
					} : {}),
				});

				break;
			}

			case "boolean": {
				formContent.push({
					customType: "checkbox",
					id: variable,
					label: varName,
					defaultValue: variables[variable].value,
					color: "secondary",
				});

				break;
			}

			case "select": {
				formContent.push({
					customType: "dropdown",
					id: variable,
					label: varName,
					defaultValue: variables[variable].value,
					items: typeof selectItems === "string"
						? tmpFormValues[selectItems].map((item) => ({ value: item, text: item }))
						: selectItems.map((item) => ({ value: item, text: item })),
				});

				break;
			}

			case "tagInput": {
				formContent.push({
					customType: "tagInput",
					id: variable,
					label: varName,
					defaultValue: variables[variable].value,
				});

				break;
			}

			case "switch": {
				formContent.push({
					customType: "switch",
					id: variable,
					label: varName,
					defaultValue: variables[variable].value,
				});

				break;
			}

			default:
		// Do nothing
		}
	}

	formContent.push({
		customType: "number",
		id: "duration",
		label: "Duration",
		min: 0,
		max: 100,
		step: 1,
		precision: 1,
		format: (num) => `${num}s`,
		parse: (num) => num.replace("s", ""),
		value: 1,
	}, {
		customType: "button",
		id: "submit",
		type: "submit",
		text: "Save",
	});

	return ([
		<Grid key={`main_${id}`} id={id} className={classes.main}>
			{value.length === 0 && (
				<Typography variant="body2" color="greyDark.main">
					{placeholder}
				</Typography>
			)}
			{value.map((item, index) => (
				<Grid key={`main_${index}`} container>
					{index !== 0 && (
						<Divider key={`divider_${index}`} className={classes.divider} />
					)}
					<Grid key={index} item className={classes.step}>
						{`${index + 1}. `}
						<Typography ml={0.3}>
							{Object.keys(item.state).map((state, i) => (
								<Chip
									key={i}
									label={`${state}: ${item.state[state]}`}
									className={classes.chip}
								/>
							))}
							{`-`}
							<Chip label={`Duration: ${item.duration}s`} className={classes.chip} />
						</Typography>
						<IconButton
							edge="end"
							disabled={disabled}
							className={classes.deleteButton}
							onClick={() => handleDelete(index)}
						>
							<Delete className={classes.arrowButtonIcon} />
						</IconButton>
					</Grid>
				</Grid>
			))}
			<IconButton
				edge="end"
				disabled={disabled}
				className={classes.borderButton}
				onClick={() => handleAdd()}
			>
				<Add className={classes.arrowButtonIcon} />
			</IconButton>
		</Grid>,
		<Popup
			key={`popup_${id}`}
			width="500px"
			open={addStepPopupOpen}
			title="Add Step"
			onClose={handleClose}
		>
			<Form key={JSON.stringify(formContent)} content={formContent} onSubmit={handleSubmit} />
		</Popup>,
	]);
};

export default StateInput;
